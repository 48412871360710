import { observer } from 'mobx-react-lite'
import { ExistingRoomState } from './ExistingRoomState'
import { NewRoomState } from './NewRoomState'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'

export const RoomsDialog = observer(function RoomsModal({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  if (cubit.roomStateId) {
    return (
      <Dialog testId="rooms-dialog" size="sm">
        <DialogCloseButton />
        <ExistingRoomState cubit={cubit} />
      </Dialog>
    )
  }

  return (
    <Dialog testId="rooms-dialog" size="lg" className="min-h-[50vh]">
      <DialogCloseButton />
      <NewRoomState cubit={cubit} />
    </Dialog>
  )
})
