import { type ElementType, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { SlideProjector } from './SlideProjector'
import { BreakoutButton } from './design-system/BreakoutButton'

export const BreakoutExperienceWidget = ({
  disableContinueOnPress = false,
  includeBackButton = false,
  doneButtonText,
  onDone,
  onSkip,
  components,
  noBottomPadding,
}: {
  disableContinueOnPress?: boolean
  includeBackButton?: boolean
  doneButtonText?: string
  onDone: () => void | Promise<void>
  onSkip: () => void | Promise<void>
  components: ElementType[]
  noBottomPadding?: boolean
}) => {
  const { t } = useTranslation()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [continueDisabled, setContinueDisabled] = useState(false)

  const nextPage = () => {
    if (currentIndex < components.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const lastPage = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const continueOnPress = async () => {
    const isLastSlide = currentIndex === components.length - 1
    const setDisabledState = disableContinueOnPress && !isLastSlide

    if (setDisabledState) {
      setContinueDisabled(true)
    }

    try {
      if (isLastSlide) {
        if (onDone) {
          await onDone()
        } else {
          // Handle closing or redirecting
        }
      } else {
        nextPage()
      }
    } catch (err) {
      console.error(err)
    } finally {
      if (setDisabledState) {
        setContinueDisabled(false)
      }
    }
  }

  const renderBackButton = currentIndex !== 0 && includeBackButton
  const ComponentToRender = components[currentIndex]

  return (
    <div className="mb:pb-0 relative h-full max-h-full gap-3">
      {/* Progress bar */}
      <div className="absolute left-0 top-0 mt-12 flex h-1 min-h-1 w-full flex-row justify-evenly px-12">
        {components.map((_, index) => (
          <div
            key={index}
            className={classNames('h-full w-full rounded-[3rem]', {
              'bg-grey-text': currentIndex === index,
              'bg-very-light-grey': currentIndex !== index,
              'mr-1': index !== components.length - 1,
            })}
          ></div>
        ))}
      </div>

      <div className="h-full">
        <SlideProjector index={currentIndex}>
          <div
            className={classNames('h-full px-12 pt-16', {
              'pb-16': !noBottomPadding,
              'pb-0': noBottomPadding,
            })}
          >
            <ComponentToRender />
          </div>
        </SlideProjector>
      </div>

      {/* Buttons */}
      <div
        className={`absolute bottom-0 left-0 flex w-full justify-between gap-8 px-12 pb-8`}
      >
        {/* Back or Skip Button */}
        <button
          onClick={renderBackButton ? lastPage : onSkip}
          className="text-sm text-gray-600"
        >
          {!renderBackButton
            ? t('walkthrough.skip_walkthrough')
            : t('walkthrough.back')}
        </button>

        <BreakoutButton
          data-testid="breakout-experience-next-button"
          size="large"
          onClick={continueOnPress}
          disabled={continueDisabled}
          className="text-label-large max-w-[50%] bg-on-primary text-secondary"
        >
          {currentIndex === components.length - 1
            ? doneButtonText || t('walkthrough.start_session')
            : t('walkthrough.next')}
        </BreakoutButton>
      </div>
    </div>
  )
}
