import { SlideDeckMaterialType } from '@breakoutlearning/firebase-repository/types'
import { CourseMaterialListItem } from 'components/slide-deck-material-components/CourseMaterialListItem'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { EyeOpen } from 'components/icons/EyeOpen'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { ShowExperienceDialog } from 'pages/student/assignment/dialogs/ShowExperienceDialog'
import { useTranslation } from 'react-i18next'

export const MaterialsView = observer(function MaterialsView() {
  const cubit = useInstructorAssignmentCubit()
  const slideDeckId = cubit.assignment.data.slideDeckId
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  const hasTeaser =
    cubit.slideDeck.data.slideDeckTeaser !== undefined &&
    cubit.slideDeck.data.slideDeckTeaser !== ''
  const hasDescription =
    cubit.slideDeck.data.slideDeckDescription !== undefined &&
    cubit.slideDeck.data.slideDeckDescription !== ''
  return (
    <div className="flex h-full flex-col gap-5 bg-surface p-5 md:flex-row">
      <div className="flex h-full flex-1 flex-col rounded-2xl bg-core-tertiary md:w-[300px]">
        <div className="flex-1 overflow-auto p-5">
          <img
            src={cubit.slideDeck.data.slideDeckImageURL}
            alt=""
            className="mb-6 max-w-[50px]"
          />
          <h2 className="text-headline-medium mb-1">
            {cubit.slideDeck.data.slideDeckName}
          </h2>
          <div className="mb-6 flex flex-wrap items-center justify-between">
            {hasTeaser && (
              <span className="text-body-large">
                {cubit.slideDeck.data.slideDeckTeaser}
              </span>
            )}
            <span className="text-body-medium text-grey-text">
              {t('instructor_assignment.version_label', {
                version: cubit.slideDeck.data.slideDeckVersion,
              })}
            </span>
          </div>
          {hasDescription && (
            <div className="text-body-medium flex flex-col md:overflow-hidden">
              <span className="mb-2 text-grey-text">
                {t('instructor_assignment.description')}
              </span>
              <span>{cubit.slideDeck.data.slideDeckDescription}</span>
            </div>
          )}
        </div>
      </div>
      <div className="flex h-full flex-1 flex-col justify-between rounded-2xl bg-core-tertiary p-5">
        <MaterialsInnerView />
        <div className="text-right">
          <BreakoutButton
            kind="primary"
            size="large"
            icon={<EyeOpen size={14} />}
            onClick={() => {
              showDialog(() => (
                <ShowExperienceDialog slideDeckId={slideDeckId} />
              ))
            }}
          >
            View Experience
          </BreakoutButton>
        </div>
      </div>
    </div>
  )
})

const MaterialsInnerView = observer(function MaterialsInnerView() {
  const cubit = useInstructorAssignmentCubit()
  const materialsWithoutFeatured = cubit.materials.models.filter(
    (material) =>
      material.data.materialType !== SlideDeckMaterialType.featuredLarge
  )
  const slideDeckId = cubit.assignment.data.slideDeckId

  return (
    <div className="h-full md:overflow-auto">
      {materialsWithoutFeatured.map((material) => (
        <CourseMaterialListItem
          className="mb-2 mr-5 rounded-2xl border-b border-surface-dim bg-core-tertiary p-2 py-6"
          key={material.id}
          material={material}
          slideDeckId={slideDeckId}
          slideDeckName={cubit.slideDeck.data.slideDeckName}
        />
      ))}
    </div>
  )
})
