import type { AssignmentGroupData } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import type { RoomStateRubricResult } from '@breakoutlearning/firebase-repository/models/RoomStateRubricResult'
import type { SlideRubric } from '@breakoutlearning/firebase-repository/models/SlideRubric'
import {
  BreakoutUserAvatar,
  BreakoutUserAvatarStack,
} from 'components/breakout/BreakoutUserAvatar'
import { RubricScoreWidget } from 'components/breakout/RubricScoreWidget'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTable } from 'components/design-system/BreakoutTable'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { ArrowRightIcon } from 'components/icons/ArrowRight'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { Shield } from 'components/icons/Shield'
import { useRepository } from 'hooks/auth'
import { t } from 'i18next'
import { useState } from 'react'

export function AssignmentRubricGroupDialog({
  groupData,
  userId,
}: {
  groupData: AssignmentGroupData
  userId?: string
}) {
  const [rubric, setRubric] = useState<SlideRubric | null>(null)

  return (
    <Dialog size="lg" className="h-[88vh] !bg-core-tertiary">
      <DialogCloseButton className="absolute right-3 top-3" />

      {rubric && (
        <RubricView
          groupData={groupData}
          rubric={rubric}
          setRubric={setRubric}
          userId={userId}
        />
      )}
      {!rubric && (
        <GroupView
          groupData={groupData}
          userId={userId}
          setRubric={setRubric}
        />
      )}
    </Dialog>
  )
}

function RubricView({
  groupData,
  rubric,
  setRubric,
  userId,
}: {
  groupData: AssignmentGroupData
  rubric: SlideRubric
  setRubric: (rubric: SlideRubric | null) => void
  userId?: string
}) {
  const [chosenUserId, setChosenUserId] = useState<string | null>(
    userId || null
  )
  const repository = useRepository()
  const users = groupData.groupMembers.map((userId) => {
    return repository.userStore.getUser(userId)
  })

  const rubricResultsPerUser = new Map<string, RoomStateRubricResult>()

  for (const [userId, rubricResults] of groupData.rubricResults.entries()) {
    const rubricResult = rubricResults.get(rubric)

    if (rubricResult && rubricResult.length > 0) {
      rubricResultsPerUser.set(userId, rubricResult[0])
    }
  }

  const chosenUserResult = chosenUserId
    ? rubricResultsPerUser.get(chosenUserId)
    : undefined

  return (
    <div className="flex h-full flex-col">
      <div className="px-5">
        <BreakoutButton
          kind="tertiary"
          className="rotate-180"
          icon={<ArrowRightIcon />}
          onClick={() => setRubric(null)}
        />
      </div>
      <div className="flex w-full flex-row justify-between px-5">
        <div>
          <div className="text-headline-large mb-3">{rubric.data.rubric}</div>
          <div className="text-body-large text-grey-text">
            {rubric.data.rubricDescription}
          </div>
        </div>
        <div className="mr-10 flex flex-row items-center gap-4 p-4">
          <div className="text-label-medium">
            {groupData.roomState?.roomStateName}
          </div>
          <BreakoutUserAvatarStack users={users} radius={15} />
        </div>
      </div>
      <div className="my-5 flex h-full flex-row gap-3 px-5">
        <div className="flex w-[225px] flex-col gap-2">
          {users.map((user) => (
            <div
              onClick={() => setChosenUserId(user.id)}
              key={user.id}
              className="flex cursor-pointer flex-row items-center gap-2 rounded-xl bg-surface p-3"
            >
              <div>
                <BreakoutUserAvatar user={user} radius={15} />
              </div>
              <div className="text-label-medium flex-1 truncate">
                {user.fullName}
              </div>
              {groupData.roomState?.data.groupLeaderUserIds.includes(
                user.id
              ) && <Shield size={14} className="text-breakout-yellow" />}
              <div>
                {rubricResultsPerUser.has(user.id) && (
                  <RubricScoreWidget
                    rubric={rubric}
                    rubricResult={rubricResultsPerUser.get(user.id)!}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="h-full flex-1 rounded-2xl bg-surface">
          {chosenUserResult && (
            <div className="flex h-full flex-col p-5">
              <div className="text-label-medium mb-2 text-grey-text">
                {t('instructor.justification')}
              </div>
              <div className="text-body-medium">
                {chosenUserResult.data.justification}
              </div>
              <div className="text-label-medium  my-4 text-grey-text">
                {t('instructor.arguments')}
              </div>
              <ul className="ml-4 list-disc">
                {chosenUserResult.data.arguments.map((argument, index) => {
                  return (
                    <li key={index} className="mb-4">
                      <span className="text-body-medium">{argument}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function GroupView({
  groupData,
  userId,
  setRubric,
}: {
  groupData: AssignmentGroupData
  userId?: string
  setRubric: (rubric: SlideRubric) => void
}) {
  const repository = useRepository()
  const users = groupData.groupMembers.map((userId) => {
    return repository.userStore.getUser(userId)
  })
  const rubricResults: {
    user: PublicUser
    rubric: SlideRubric
    result: RoomStateRubricResult
  }[] = []

  if (userId) {
    const userRubrics = groupData.rubricResults.get(userId)

    if (userRubrics) {
      const list = Array.from(userRubrics.entries())

      for (const [rubric, userRubricResults] of list) {
        for (const rubricResult of userRubricResults) {
          rubricResults.push({
            user: repository.userStore.getUser(rubricResult.data.userId),
            rubric,
            result: rubricResult,
          })
        }
      }
    }
  } else {
    const list = Array.from(groupData.rubricResults.entries())

    for (const [userId, userRubrics] of list) {
      const user = repository.userStore.getUser(userId)
      for (const [rubric, userRubricResults] of userRubrics.entries()) {
        for (const rubricResult of userRubricResults) {
          rubricResults.push({
            user,
            rubric,
            result: rubricResult,
          })
        }
      }
    }
  }

  return (
    <>
      <div className="mx-5 mt-5 flex w-full flex-row justify-between">
        <div className="text-headline-large">
          {t('instructor.rubric_assessment')}
        </div>
        <div className="mr-10 flex flex-row items-center gap-4 p-4">
          <div className="text-label-medium">
            {groupData.roomState?.roomStateName}
          </div>
          <BreakoutUserAvatarStack users={users} radius={15} />
        </div>
      </div>
      <div className="mx-5">
        <BreakoutTable className="mb-10 table-auto">
          <thead>
            <tr>
              <th className="!text-xxs">{t('instructor.rubric')}</th>
              <th className="!text-xxs">{t('instructor.score')}</th>
              <th className="!text-xxs">
                {t('instructor.highlighted_justification')}
              </th>
            </tr>
          </thead>
          <tbody>
            {rubricResults.map(({ rubric, result, user }) => {
              return (
                <UserRow
                  onClick={() => setRubric(rubric)}
                  key={rubric.id}
                  rubric={rubric}
                  result={result}
                  user={user}
                  groupData={groupData}
                />
              )
            })}
          </tbody>
        </BreakoutTable>
      </div>
    </>
  )
}

function UserRow({
  rubric,
  result,
  user,
  groupData,
  onClick,
}: {
  rubric: SlideRubric
  result: RoomStateRubricResult
  user: PublicUser
  groupData: AssignmentGroupData
  onClick: () => void
}) {
  const [showArguments, setShowArguments] = useState(false)
  return (
    <tr onClick={onClick}>
      <td className="w-[35%]">
        <div className="text-title-small mb-3">{rubric.data.rubric}</div>
        <div className="text-body-small">{rubric.data.rubricDescription}</div>
      </td>
      <td>
        <RubricScoreWidget
          key={rubric.id}
          rubric={rubric}
          rubricResult={result}
          showTitle
        />
      </td>
      <td className="!text-xs">
        <div className="flex flex-row gap-2">
          <div>
            <BreakoutTooltip
              content={
                showArguments
                  ? t('instructor.hide_arguments')
                  : t('instructor.show_arguments')
              }
            >
              <div
                className="cursor-pointer p-2 pl-0"
                onClick={() => setShowArguments(!showArguments)}
              >
                <ChevronLeft
                  size={14}
                  className={showArguments ? 'rotate-90' : 'rotate-270'}
                />
              </div>
            </BreakoutTooltip>
          </div>
          <div>
            <div className="mb-2 flex flex-row items-center gap-2">
              <BreakoutUserAvatar user={user} radius={14} />
              <span className="text-label-medium">{user.fullName}</span>
              {groupData.roomState?.data.groupLeaderUserIds.includes(
                user.id
              ) && <Shield size={14} className="text-breakout-yellow" />}
            </div>
            <div className="text-body-medium">{result.data.justification}</div>
            {showArguments && (
              <div className="mt-2">
                <ul className="ml-4 list-disc">
                  {result.data.arguments.map((argument, index) => {
                    return (
                      <li key={index} className="mb-4">
                        <span className="text-body-medium">{argument}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </td>
    </tr>
  )
}
