import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { LinearProgressIndicator } from '../quiz_components/LinearProgressIndicator'

export const PollDetailRow = ({
  answer,
  selectedPercentage,
  title,
  testId,
}: {
  answer: string
  selectedPercentage: number
  title: string
  testId?: string
}) => {
  return (
    <div className="flex flex-row items-center" data-testid={testId}>
      <BreakoutTooltip content={answer}>
        <span className="text-body-medium w-[30px] text-grey-text">
          {title}
        </span>
      </BreakoutTooltip>
      <LinearProgressIndicator
        className="w-full pl-2"
        percentage={selectedPercentage}
      />
      <div className="w-10 min-w-10 pr-2 text-end">
        <span className="text-title-small">
          {selectedPercentage.toFixed(0)}%
        </span>
      </div>
    </div>
  )
}
