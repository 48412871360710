import { observer } from 'mobx-react-lite'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useTranslation } from 'react-i18next'
import { GraphBarIcon } from 'components/icons/GraphBar'
import { AirplayVideoIcon } from 'components/icons/AirplayVideo'
import { useCallback, useEffect } from 'react'
import classNames from 'classnames'
import { useDialogs } from 'hooks/dialogs'
import { QuizModal } from '../QuizModal'
import { Button } from 'components/Button'
import { useWalkthroughDialog } from './WalkthroughDialog.hooks'
import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'

export const JoinSessionDialog = observer(function JoinSessionDialog({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const { t } = useTranslation()
  const { popDialog, showDialog } = useDialogs()
  const roomId = cubit.roomStateId
  const { showWalkthroughDialogIfNecessary } = useWalkthroughDialog({ roomId })
  const showQuizElements = !cubit.allPreWorkQuestionsAnswered
  const paragraphTextKey = showQuizElements
    ? 'student_assignment.quiz_required'
    : 'student_assignment.please_join_now'
  const buttonTextKey = showQuizElements
    ? 'student_assignment.start_quiz'
    : 'student_assignment.join_session'
  const ButtonIcon = showQuizElements ? GraphBarIcon : AirplayVideoIcon

  const openQuizDialog = useCallback(() => {
    showDialog(() => (
      <QuizModal
        cubit={cubit}
        closeModal={() => {
          popDialog()
        }}
      />
    ))
  }, [cubit, popDialog, showDialog])

  // set visible true on mount / false on unmount
  useEffect(() => {
    cubit.onDialogMountUnmount('mount', 'join')
    return () => cubit.onDialogMountUnmount('unmount', 'join')
  }, [cubit])

  return (
    <Dialog
      testId="join-session-dialog"
      size="sm"
      innerClassName="flex h-full flex-col items-center justify-center text-center"
    >
      <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
      <h1 className="text-headline-large mb-3">
        {t('student_assignment.time_to_join')}
      </h1>
      <div className="mb-3 flex flex-grow flex-col">
        <h2 className="text-title-large mb-3">
          {t('student_assignment.session_live')}
        </h2>
        <p className="text-body-medium">{t(paragraphTextKey)}</p>
      </div>
      <Button
        className={classNames(
          'text-primary',
          showQuizElements ? 'bg-on-secondary' : 'bg-breakout-dark-green'
        )}
        onClick={() => {
          if (showQuizElements) {
            openQuizDialog()
            // open quiz
          } else {
            if (cubit.roomStateId === null) {
              throw new Error('no ID on RoomState')
            }

            showWalkthroughDialogIfNecessary(() => cubit.setJoiningSession())
          }
        }}
      >
        <ButtonIcon className="mr-1 inline" size={14} />
        {t(buttonTextKey)}
      </Button>
    </Dialog>
  )
})
