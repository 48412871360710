import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { useCallback } from 'react'
import { WalkthroughDialog } from './WalkthroughDialog'

const localStorageKey = 'breakout.hasSeenWalkthrough'

// @ts-expect-error disabled temporarily
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function hasSeenWalkthrough() {
  return localStorage.getItem(localStorageKey) === 'true'
}

function setHasSeenWalkthrough() {
  localStorage.setItem(localStorageKey, 'true')
}

export function useWalkthroughDialog({
  roomId,
}: {
  roomId: string | undefined | null
}) {
  const { showDialog } = useDialogs()
  const store = useRootStore()

  const showWalkthroughDialogIfNecessary = useCallback(
    (onJoin: VoidFunction) => {
      if (!roomId) return
      // if (hasSeenWalkthrough()) {
      //   store.navigateTo('meeting', { roomId })
      //   return
      // }

      showDialog(
        ({ remove }) => (
          <WalkthroughDialog
            joinSession={() => {
              onJoin()
              remove()
              setHasSeenWalkthrough()
              store.navigateTo('meeting', { roomId })
            }}
          />
        ),
        {
          namespace: 'join-session',
        }
      )
    },
    [roomId, showDialog, store]
  )

  return {
    showWalkthroughDialogIfNecessary,
  }
}
