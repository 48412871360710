import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { Button } from 'components/Button'
import { Shield } from 'components/icons/Shield'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import classNames from 'classnames'
import { RoomStateForm } from './RoomStateFormDialog'
import { useBreakoutUser } from 'hooks/profile'
import {
  AssignmentType,
  LibraryObjectState,
} from '@breakoutlearning/firebase-repository/types'
import { useTranslation } from 'react-i18next'
import { capitalize } from 'util/strings'

export const ExistingRoomState = observer(function ExistingRoomState({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const user = useBreakoutUser()

  const { t } = useTranslation()
  const { showDialog, popDialog } = useDialogs()

  const leaveRoom = useCallback(() => {
    cubit.leaveRoom()
  }, [cubit])
  const libraryObject = cubit.libraryObject
  const canLeave =
    libraryObject.libraryObjectState !== LibraryObjectState.expired &&
    libraryObject.assignment.assignmentType === AssignmentType.studentLed
  const scheduledAtDate = cubit.roomState.scheduledAtDate
  const noGroupLeader = cubit.roomState.data.groupLeaderUserIds.length === 0

  return (
    <div className="w-full">
      <div className="text-headline-large mb-4 text-center">
        {cubit.roomState.roomStateName}
      </div>
      <div className="mb-2 flex flex-row justify-between text-xs text-gray-800">
        <div>{t('student_assignment.scheduled_date')}</div>
        {(cubit.currentUserIsGroupLeader || noGroupLeader) && (
          <div
            className="cursor-pointer"
            onClick={() =>
              showDialog(() => (
                <RoomStateForm
                  onSave={() => {
                    popDialog()
                  }}
                  cubit={cubit}
                />
              ))
            }
          >
            {t('student_assignment.edit')}
          </div>
        )}
      </div>
      <div className="flex flex-row justify-between rounded-2xl bg-surface p-5">
        <div className="flex flex-col items-center">
          <div className="mb-2 text-xs">Date</div>
          <div className="font-bold">
            {!scheduledAtDate && t('student_assignment.not')}
            {scheduledAtDate && scheduledAtDate?.toFormat('LLL d, yyyy')}
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="mb-2 text-xs">Time</div>
          <div className="font-bold">
            {!scheduledAtDate && t('student_assignment.scheduled')}
            {scheduledAtDate && scheduledAtDate?.toFormat('t')}
          </div>
        </div>
      </div>
      {user.isAdmin && (
        <div className="mt-3 flex flex-row justify-between">
          <div className="mb-2 text-xs text-gray-800">Video Method</div>
          <div
            onClick={
              cubit.currentUserIsGroupLeader
                ? () => {
                    cubit.updateRoomVideoMethod(
                      cubit.roomState.data.videoMethod === 'broadcast'
                        ? 'streaming'
                        : 'broadcast'
                    )
                  }
                : undefined
            }
            className={classNames(
              'text-label-medium mb-2 select-none text-gray-800',
              cubit.currentUserIsGroupLeader && 'cursor-pointer'
            )}
          >
            {capitalize(cubit.roomState.data.videoMethod)}
          </div>
        </div>
      )}
      <div className="mt-3">
        <div className="mb-2 text-xs text-gray-800">Students</div>
        <div>
          {cubit.roomState.users.map((student) => {
            const isGroupLeader =
              cubit.roomState.data.groupLeaderUserIds.includes(student.id)
            return (
              <div key={student.id} className="mb-2 flex flex-row items-center">
                <div className="h-[25px] w-[25px] overflow-hidden rounded-full">
                  {student.data.imageUrl && <img src={student.data.imageUrl} />}
                </div>
                <div className="text-label-medium ml-2 flex flex-row">
                  {student.data.firstName} {student.data.lastName}
                  {isGroupLeader && (
                    <Shield size={14} className="ml-1 stroke-breakout-gold" />
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {canLeave && (
        <div>
          <Button
            testId="room-dialog-leave-room"
            className="mt-5 w-full bg-red-500 text-white"
            onClick={leaveRoom}
          >
            {t('student_assignment.leave_group')}
          </Button>
        </div>
      )}
    </div>
  )
})
