import { Navigation } from 'pages/layout/Navigation'
import { getRouteName, getSubRouteComponent } from 'config/routes'
import { useRootStore } from 'hooks/rootStore'
import { autorun } from 'mobx'
import React, { useEffect, useState } from 'react'
import { AnimatePresence, m } from 'framer-motion'
import sha1 from 'sha1'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { ErrorBoundary } from 'react-error-boundary'

const SubRouter = () => {
  return <SubRouterWithAnimation />
}

const SubRouterWithAnimation = () => {
  const rootStore = useRootStore()
  const router = rootStore.router
  const name = getRouteName(router.currentRoute)
  const initialComponent = getSubRouteComponent(name)
  const [component, setComponent] = useState<JSX.Element>(initialComponent)

  useEffect(() => {
    return autorun(() => {
      const name = getRouteName(router.currentRoute)
      const component = getSubRouteComponent(name)

      setComponent((current) => {
        if (current === component) return current
        return component
      })
    })
  }, [router])

  const typehash = sha1(component?.type || 'none')
  return (
    <div className="h-full w-full rounded-3xl bg-primary md:ml-5">
      <AnimatePresence mode="wait" initial={false}>
        <m.main
          className="h-full w-full"
          key={`subroute-${typehash}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <React.Fragment key={typehash}>{component}</React.Fragment>
        </m.main>
      </AnimatePresence>
    </div>
  )
}

export function TwoColumnSubRouter() {
  return (
    <div className="box-border h-full w-full">
      <div className="items-left flex h-full flex-col md:flex-row md:justify-between md:overflow-hidden">
        <Navigation />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <SubRouter />
        </ErrorBoundary>
      </div>
    </div>
  )
}

function ErrorFallback({
  resetErrorBoundary,
}: {
  resetErrorBoundary: () => void
}) {
  return (
    <div className="flex h-full w-full items-center justify-center rounded-3xl bg-primary md:ml-5">
      <div className="text-center">
        <div className="text-headline-large mb-5">Something went wrong</div>
        <BreakoutButton
          size="large"
          className="bg-tertiary font-semibold text-on-tertiary"
          onClick={resetErrorBoundary}
        >
          Try again
        </BreakoutButton>
      </div>
    </div>
  )
}
