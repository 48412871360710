import { Spinner } from 'components/Spinner'
import { Suspense, lazy, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CameraAlt } from 'components/icons/CameraAlt'

const AvTest = lazy(() => import('./AvTest'))
type PermissionState = 'waiting' | 'granted' | 'denied'

export function AvTestEntrypoint() {
  const [permissionState, setPermissionState] =
    useState<PermissionState>('waiting')
  const [showPermissionsBanner, setShowPermissionsBanner] = useState(false)

  useEffect(() => {
    // query for both audio and video devices at the same time
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((mediaStream) => {
        console.log('got permissions')
        const tracks = mediaStream.getTracks()
        tracks.forEach((track) => {
          track.stop()
        })
        setPermissionState('granted')
      })
      .catch((e) => {
        console.log('error getting permissions', e)
        setPermissionState('denied')
        setShowPermissionsBanner(true)
      })
  }, [])

  if (permissionState === 'waiting')
    return (
      <div className="flex h-full w-full flex-col md:items-center md:justify-center">
        <div className="flex max-h-[800px] min-h-[80%] w-full max-w-[1200px] flex-col items-center justify-center rounded-2xl bg-core-tertiary md:overflow-hidden xl:max-w-[1200px]">
          <Spinner />
        </div>
      </div>
    )

  return (
    <div className="flex h-full w-full flex-col md:items-center md:justify-center">
      {showPermissionsBanner && (
        <PermissionsErrorBanner
          onDismiss={() => setShowPermissionsBanner(false)}
        />
      )}
      <div className="max-h-[800px] w-full max-w-[1200px] rounded-2xl bg-core-tertiary md:overflow-x-hidden xl:max-w-[1200px]">
        <Suspense fallback={<Spinner />}>
          <AvTest permissionState={permissionState} />
        </Suspense>
      </div>
    </div>
  )
}

function PermissionsErrorBanner({ onDismiss }: { onDismiss: () => void }) {
  const { t } = useTranslation()
  return (
    <div className="z-100 fixed left-0 top-0 flex w-full items-center border-b-1 bg-light-grey p-2 text-on-secondary">
      <div className="flex w-full flex-row justify-between px-1">
        <div className="flex flex-row">
          <CameraAlt
            size={24}
            className="mr-2 fill-on-primary stroke-secondary"
          />
          <p className="text-body-large mr-2">
            {t('dart.video_not_readable_error_popup')}
          </p>
        </div>
        <div className="flex flex-row">
          <button
            className="text-label-large mr-4 text-grey-text"
            onClick={() =>
              window.open(
                'https://support.breakoutlearning.com/help-center/audio-video',
                '_blank'
              )
            }
          >
            Help
          </button>
          <button
            className="text-label-large mr-1 text-grey-text"
            onClick={onDismiss}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  )
}
