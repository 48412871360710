import { AnimatePresence, m } from 'framer-motion'
import { useSettings } from 'hooks/settings'
import { useEffect, useState } from 'react'

export function SlideProjector({
  index,
  children,
  className,
}: {
  index: number
  children: React.ReactNode
  className?: string
}) {
  const { animationsEnabled } = useSettings()
  if (!animationsEnabled)
    return (
      <div
        className={`relative h-full w-full md:overflow-x-hidden ${className}`}
      >
        <div className="absolute top-0 h-full w-full bg-primary">
          {children}
        </div>
      </div>
    )

  return (
    <SlideAnimation className={className} index={index}>
      {children}
    </SlideAnimation>
  )
}

const variants = {
  enter: (direction: string) => {
    return {
      x: direction === 'left' ? '125%' : '-125%',
    }
  },
  center: {
    x: 0,
  },
  exit: (direction: string) => {
    return {
      x: direction === 'left' ? '-100%' : '100%',
    }
  },
}

function SlideAnimation({
  index,
  children,
  className,
}: {
  index: number
  children: React.ReactNode
  className?: string
}) {
  const [state, setState] = useState<{
    index: number
    direction: 'left' | 'right'
    children: React.ReactNode
  }>({
    index: index,
    direction: 'left',
    children: children,
  })

  useEffect(() => {
    if (index === state.index) return

    setState({
      index: index,
      direction: index > state.index ? 'left' : 'right',
      children: children,
    })
  }, [index, children, state])

  return (
    <div className={`relative h-full w-full md:overflow-x-hidden ${className}`}>
      <AnimatePresence mode="sync" initial={false} custom={state.direction}>
        <m.div
          key={state.index.toString()}
          variants={variants}
          custom={state.direction}
          initial="enter"
          animate="center"
          exit="exit"
          className="absolute top-0 h-full w-full "
          transition={{ duration: 0.3 }}
        >
          {state.children}
        </m.div>
      </AnimatePresence>
    </div>
  )
}
