import { GoogleStorageVideo } from './GoogleStorageVideo'
import { Dialog, InlineDialog } from './dialogs/Dialog'
import { DialogCloseButton } from './dialogs/DialogCloseButton'

export function VideoPreviewDialog({
  src,
  inline = false,
  onDismiss,
  requiresAuthHeaders = false,
}: {
  src: string
  inline?: boolean
  onDismiss?: () => void
  requiresAuthHeaders?: boolean
}) {
  const DialogToUse = inline ? InlineDialog : Dialog
  return (
    <DialogToUse
      onDismiss={onDismiss}
      size="custom"
      className="relative max-h-[90vh] max-w-[90vw] overflow-hidden rounded-2xl bg-surface-bright !p-0"
      innerClassName="!p-0"
    >
      <DialogCloseButton
        className="z-[50]"
        onClick={() => {
          if (onDismiss) onDismiss()
          // returning true pops dialog
          return !inline
        }}
      />
      {!requiresAuthHeaders ? (
        <video
          autoPlay
          className="!aspect-video h-full w-full"
          controls
          src={src}
        />
      ) : (
        <GoogleStorageVideo
          namespace="preview-dialog"
          autoPlay
          className="!aspect-video h-full w-full"
          controls
          src={src}
        />
      )}
    </DialogToUse>
  )
}
