import { Button } from 'components/Button'
import { ShowCompletedButton } from 'components/ShowCompletedButton'
import { PlusIcon } from 'components/icons/Plus'
import {
  Actions,
  Contents,
  Header,
  Headline,
  MainPane,
} from 'pages/layout/TwoColumn'
import { useRepository } from 'hooks/auth'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { SectionList } from './SectionList'
import { useDialogs } from 'hooks/dialogs'
import { AddSectionDialog } from './AddSectionDialog'
import { InstructorLibraryCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorLibraryCubit'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { useCubitBuilder } from 'hooks/cubits'
import { useEffect } from 'react'
import { ephemeralFlags } from 'config/ephemeral'
import { ImpersonationInfo } from 'components/ImpersonationInfo'

export function InstructorClassesPage() {
  const repository = useRepository()
  const { t } = useTranslation()
  const rootStore = useRootStore()

  const user = useBreakoutUser()

  const instructorUserId = rootStore.impersonatedUserId

  const cubit = useCubitBuilder(
    () =>
      new InstructorLibraryCubit(repository, user.role || '', instructorUserId),
    [repository, user.role, instructorUserId]
  )

  useEffect(() => {
    if (
      ephemeralFlags.instructorClassPageShowCompleted &&
      !cubit.showCompleted
    ) {
      cubit.toggleShowCompleted()
    }
  }, [cubit])

  return (
    <MainPane className="z-20">
      <Header>
        <Headline>{t('instructor_classes.headline')}</Headline>
        <ImpersonationInfo />
        <ClassesActions cubit={cubit} />
      </Header>
      <Contents className="h-full md:overflow-auto">
        <SectionList cubit={cubit} />
      </Contents>
    </MainPane>
  )
}

const ClassesActions = observer(function ClassesActions({
  cubit,
}: {
  cubit: InstructorLibraryCubit
}) {
  const user = useBreakoutUser()
  const rootStore = useRootStore()
  const { showDialog, popDialog } = useDialogs()
  return (
    <Actions>
      <ShowCompletedButton
        onClick={() => {
          ephemeralFlags.instructorClassPageShowCompleted = !cubit.showCompleted
          cubit.toggleShowCompleted()
        }}
        showCompleted={cubit.showCompleted}
      />
      {(user.isAdmin || user.isInstructor) && (
        <Button
          onClick={() => {
            showDialog(() => (
              <AddSectionDialog
                createSection={(...props) => cubit.createSection(...props)}
                onCreate={popDialog}
                instructorUserId={rootStore.impersonatedUserId}
              />
            ))
          }}
          overrideClassName="bg-tertiary text-on-tertiary h-[54px] w-[182px] rounded-2xl text-label-large"
        >
          <PlusIcon className="mr-1 inline" size={14} />
          Add Class
        </Button>
      )}
    </Actions>
  )
})
