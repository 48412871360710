import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { RoomStateForm } from './RoomStateFormDialog'
import { Spinner } from 'components/Spinner'
import { useDialogs } from 'hooks/dialogs'
import { useTranslation } from 'react-i18next'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { CalendarIcon } from 'components/icons/Calendar'
import { Dialog } from 'components/dialogs/Dialog'
import { ShadowBox } from 'components/ShadowBox'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import { useRepository } from 'hooks/auth'
import { BreakoutUserAvatarStack } from 'components/breakout/BreakoutUserAvatar'
import { LockOn } from 'components/icons/LockOn'
import { LibraryObjectState } from '@breakoutlearning/firebase-repository/types'
import { DateTime } from 'luxon'

const JoinRoomDialog = observer(function JoinRoomDialog({
  cubit,
  room,
  onClick,
}: {
  cubit: StudentAssignmentCubit
  room: RoomState
  onClick: () => void
}) {
  const { t } = useTranslation()
  const formattedDate = room.scheduledAtDate
    ? room.scheduledAtDate.toLocaleString(DateTime.DATETIME_FULL)
    : ''

  useEffect(() => {
    cubit.logEvent('show_join_group')
  }, [cubit])

  return (
    <Dialog size="xs">
      <DialogCloseButton className="p-5 pb-0" />
      <div>
        <h1 className="text-headline-large ">
          {t('student_assignment.join_group_confirm')}
        </h1>
        <ShadowBox className="mb-10 mt-5">
          <div className="flex h-full flex-1 flex-col items-center justify-center px-5 ">
            <div className="text-headline-medium">{room.roomStateName}</div>
            {room.userIds.length === 0 && (
              <div className="text-body-medium mt-1 text-center">
                {t('student_assignment.no_students')}
              </div>
            )}
            {room.userIds.length > 0 && (
              <BreakoutUserAvatarStack users={room.users} radius={12} />
            )}
            <div className="text-label-large mt-4">{formattedDate}</div>
          </div>
        </ShadowBox>
        <BreakoutButton
          data-testid="join-room-dialog-confirm"
          onClick={onClick}
          size="large"
          fullWidth
        >
          {t('student_assignment.confirm')}
        </BreakoutButton>
      </div>
    </Dialog>
  )
})

const RoomRow = observer(function RoomRow({
  room,
  joinRoom,
  assignmentExpired,
}: {
  room: RoomState
  assignmentExpired: boolean
  joinRoom: (roomId: string) => void
}) {
  const repository = useRepository()
  const users = room.data.userIds.map((id) => repository.userStore.getUser(id))
  const { t } = useTranslation()
  const formattedDate = room.scheduledAtDate
    ? room.scheduledAtDate.toLocaleString(DateTime.DATETIME_FULL)
    : t('student_assignment.not_scheduled')

  const isLocked = room.isCompleted || assignmentExpired
  return (
    <tr>
      <td>{room.data.roomStateName}</td>
      <td>
        <BreakoutUserAvatarStack users={users} radius={12} />
      </td>
      <td>
        <CalendarIcon
          size={12}
          className="-mt-[2px] mr-2 inline stroke-grey-text"
        />
        {formattedDate}
      </td>
      <td>
        {isLocked && (
          <BreakoutButton
            size="small"
            kind="secondary"
            className="text-body-small md:px-12"
            icon={<LockOn size={12} />}
            tooltip={t('dart.group_locked_description')}
            fullWidth
          >
            {t('dart.group_locked')}
          </BreakoutButton>
        )}
        {!isLocked && (
          <BreakoutButton
            size="small"
            kind="secondary"
            className="text-body-small md:px-12"
            onClick={() => joinRoom(room.id)}
            fullWidth
          >
            {t('student_assignment.join_group')}
          </BreakoutButton>
        )}
      </td>
    </tr>
  )
})

export const NewRoomState = observer(function NewRoomState({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const { t } = useTranslation()
  const { popDialog, showDialog } = useDialogs()
  const {
    roomStates: { isLoading, models },
  } = cubit
  const rooms = models

  const joinRoom = useCallback(
    async (room: RoomState) => {
      const roomIsEmpty = room.userCount === 0
      await cubit.joinRoom(room.id, { isGroupLeader: roomIsEmpty })
      popDialog?.()
      popDialog?.()
    },
    [cubit, popDialog]
  )

  const showJoinRoomDialog = useCallback(
    (id: string) => {
      const room = rooms.find((r) => r.id === id)
      if (!room) return

      showDialog(() => (
        <JoinRoomDialog
          cubit={cubit}
          room={room}
          onClick={() => joinRoom(room)}
        />
      ))
    },
    [cubit, joinRoom, rooms, showDialog]
  )

  return (
    <div>
      <div className="h-full w-full text-on-primary">
        <div className="mb-5 flex flex-row items-center justify-between">
          <h1 className="text-headline-small text-center md:text-2xl">
            {t('student_assignment.join_or_create_group')}
          </h1>
          <BreakoutButton
            size="large"
            data-testid="room-dialog-new-room"
            onClick={() => {
              showDialog(() => (
                <RoomStateForm
                  isCreate
                  onSave={() => {
                    // pop, pop! - there are two dialogs to pop
                    popDialog()
                    popDialog()
                  }}
                  cubit={cubit}
                />
              ))
            }}
            className="py-[14.25px] text-xs font-semibold text-white"
          >
            {t('student_assignment.create_new_group')}
          </BreakoutButton>
        </div>
        <table className="breakout-table">
          <thead>
            <tr>
              <th>{t('student_assignment.group_name')} </th>
              <th>{t('student_assignment.students_in_group')} </th>
              <th>{t('student_assignment.scheduled_date')} </th>
              <th>{t('student_assignment.action')} </th>
            </tr>
          </thead>
          <tbody>
            {rooms.map((room) => {
              return (
                <RoomRow
                  assignmentExpired={
                    cubit.libraryObject.libraryObjectState ===
                    LibraryObjectState.expired
                  }
                  key={room.id}
                  room={room}
                  joinRoom={showJoinRoomDialog}
                />
              )
            })}
          </tbody>
        </table>
        {isLoading && (
          <div className="mb-5 mt-10 text-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  )
})
