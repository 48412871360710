import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { CourseMaterialListItem } from 'components/slide-deck-material-components/CourseMaterialListItem'
import { Spinner } from 'components/Spinner'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

export const PreWorkModal = observer(function PreWorkModal({
  cubit,
}: {
  cubit: StudentAssignmentCubit
}) {
  const materials = cubit.materials
  const libraryObject = cubit.libraryObject
  const preWorkMaterials = useMemo(
    () =>
      materials.models
        .filter((material) => material.isPreWork)
        .sort((a, b) => {
          // sort mp3 above pdf
          if (b.data.materialType !== a.data.materialType)
            return b.data.materialType - a.data.materialType

          // if both have a material order, compare them
          if (
            a.data.materialOrder !== undefined &&
            b.data.materialOrder !== undefined
          )
            return a.data.materialOrder - b.data.materialOrder

          // if only one has a material order, put it first
          if (a.data.materialOrder !== undefined) return -1

          if (b.data.materialOrder !== undefined) return -1

          return 0
        }),
    [materials.models]
  )

  const { slideDeck } = libraryObject

  return (
    <Dialog size="sm">
      <DialogCloseButton />
      <div className="max-h-[80vh]">
        <h1 className="text-headline-large mb-4 text-center text-on-surface">
          {t('student_assignment.prework')}
        </h1>
        <p className="mb-4 text-center text-xs">
          {t('student_assignment.prework_description')}
        </p>
        {materials.isLoading || libraryObject.slideDeck.isLoading ? (
          <div className="flex flex-grow items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div>
            {preWorkMaterials.map((material) => {
              const { isPodcast, isPDF } = material
              const renderLabel = isPodcast || isPDF
              const label =
                renderLabel &&
                (isPodcast
                  ? t('student_assignment.prework_listen_to_podcast')
                  : t('student_assignment.prework_read_case_summary'))
              return (
                <>
                  {label && (
                    <div className="text-body-medium my-5 text-center text-grey-text">
                      {label}
                    </div>
                  )}
                  <CourseMaterialListItem
                    className="mb-2 flex h-[100px] flex-row items-center justify-between gap-4 rounded-2xl bg-light-grey p-5"
                    key={material.id}
                    slideDeckName={slideDeck.data.slideDeckName}
                    slideDeckId={slideDeck.id}
                    material={material}
                  />
                </>
              )
            })}
          </div>
        )}
      </div>
    </Dialog>
  )
})
