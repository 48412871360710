import { createContext } from 'react'
import type { IObservableArray } from 'mobx'
import { action, makeObservable, observable } from 'mobx'

export type DialogBuilder = ({
  remove,
}: {
  remove: () => void
}) => React.ReactNode

export type DialogConfig = {
  namespace?: string
  ignoreOnNamespace?: boolean
}

export type DialogStoreEntry = {
  id: number
  builder: DialogBuilder
  config: DialogConfig
  remove: () => void
  namespace?: string
}

type DialogContextType = {
  dialogStore: DialogStore
  showDialog: (
    dialog: DialogBuilder,
    config?: DialogConfig
  ) => DialogStoreEntry | undefined
  popDialog: () => void
  removeDialog: (entry: DialogStoreEntry) => void
  clearAllDialogs: () => void
}

export const DialogContext = createContext<DialogContextType | null>(null)

export class DialogStore {
  dialogs: IObservableArray<DialogStoreEntry> =
    observable.array<DialogStoreEntry>([])

  objectId = 0

  constructor() {
    makeObservable(this, {
      dialogs: observable,
      // actions
      showDialog: action,
      pop: action,
      remove: action,
      clearAllDialogs: action,
    })
  }

  showDialog(dialog: DialogBuilder, config?: DialogConfig) {
    const safeConfig = config || {}
    const entry: DialogStoreEntry = {
      id: this.objectId++,
      builder: dialog,
      namespace: safeConfig.namespace,
      config: safeConfig,
      remove: () => this.remove(entry),
    }
    if (safeConfig.namespace && safeConfig.ignoreOnNamespace) {
      const existingDialog = this.dialogs.find(
        (d) => d.namespace === safeConfig.namespace
      )
      if (existingDialog) {
        console.trace(
          'Found existing dialog with namespace',
          safeConfig.namespace
        )
        return
      }
    }
    this.dialogs.push(entry)
    return entry
  }

  remove(entry: DialogStoreEntry) {
    const index = this.dialogs.findIndex((d) => d.id === entry.id)
    if (index !== -1) {
      this.dialogs.splice(index, 1)
    }
  }

  pop() {
    this.dialogs.pop()
  }

  clearAllDialogs() {
    this.dialogs.clear()
  }
}
