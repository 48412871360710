export function EyeClosed({
  color = 'currentColor',
  size = 24,
  ...otherProps
}: {
  size?: number | string
} & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <path d="M2 10C2 10 5.5 14 12 14C18.5 14 22 10 22 10" stroke="black" />
      <path d="M4 11.6445L2 14" />
      <path d="M22 14L20.0039 11.6484" />
      <path d="M8.91406 13.6797L8 16.5" />
      <path d="M15.0625 13.6875L16 16.5" />
    </svg>
  )
}
