import { type SlideQuestion } from '@breakoutlearning/firebase-repository/models/SlideQuestion'
import { SlideQuestionType } from '@breakoutlearning/firebase-repository/models/SlideQuestionType'
import { AlignLeftIcon } from 'components/icons/AlignLeft'
import { GraphBarIcon } from 'components/icons/GraphBar'
import { QuestionIcon } from 'components/icons/Question'
import { SortIcon } from 'components/icons/Sort'
import { TetragonIcon } from 'components/icons/Tetragon'

export const iconFromSlideQuestion = (question: SlideQuestion) => {
  switch (question.data.questionType) {
    case SlideQuestionType.poll:
      return GraphBarIcon
    case SlideQuestionType.customPoll:
      return AlignLeftIcon
    case SlideQuestionType.multipleChoice:
      return QuestionIcon
    case SlideQuestionType.title:
      return TetragonIcon
    case SlideQuestionType.sorting:
      return SortIcon
  }
}
