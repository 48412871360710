import type { RouteType } from 'config/routes'
import type { QueryParams } from 'mobx-router'
import { TwoColumnAnimatedPane } from 'pages/layout/TwoColumn'
import { InstructorLibraryPage } from './library/InstructorLibraryPage'
import { InstructorSlideDeckPage } from './slide_deck/InstructorSlideDeckPage'

// This function controls animated transitions between subpages
function calculateAnimationController(
  params: QueryParams,
  route: RouteType | undefined
) {
  const routeTitle = route?.title
  let key: string | null = null
  let component: JSX.Element | null = null
  let mode = 'stack'
  const slideDeckId = params?.slideDeckId
  if (routeTitle === 'instructorSlideDeck' && typeof slideDeckId === 'string') {
    key = `instructorClassAssignment`
    component = <InstructorSlideDeckPage slideDeckId={slideDeckId} />
    mode = 'slideIn'
  } else if (routeTitle === 'instructorLibrary' || routeTitle === 'home') {
    key = 'instructorLibrary'
    component = <InstructorLibraryPage />
    mode = 'slideOut'
  } else {
    mode = 'leave'
    component = null
  }

  return {
    key,
    component,
    mode,
  }
}

export const InstructorLibraryEntryPage = () => {
  return (
    <TwoColumnAnimatedPane calculateController={calculateAnimationController} />
  )
}
