import type { InstructorAssistantsCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorAssistantsCubit'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useDialogs } from 'hooks/dialogs'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export function RemoveAssistantDialog({
  cubit,
  assistantId,
}: {
  cubit: InstructorAssistantsCubit
  assistantId: string
}) {
  const { t } = useTranslation()
  const tScoped = useCallback(
    (key: string) => t(`instructor_assistants.${key}`),
    [t]
  )
  const { popDialog } = useDialogs()

  return (
    <Dialog size="sm" innerClassName="flex flex-col justify-between">
      <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
      <div>
        <h1 className="text-headline-large mb-3">{tScoped('remove_ta')}</h1>
        <p className="text-body-medium mb-5">
          {tScoped('remove_ta_confirmation')}
        </p>
      </div>
      <div className="flex flex-row justify-between">
        <BreakoutButton kind="tertiary" size="medium" onClick={popDialog}>
          {tScoped('no')}
        </BreakoutButton>
        <BreakoutButton
          kind="primary"
          size="medium"
          onClick={() => {
            cubit.removeAssistant(assistantId)
            popDialog()
          }}
        >
          {tScoped('yes')}
        </BreakoutButton>
      </div>
    </Dialog>
  )
}
