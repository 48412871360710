import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { Header, Headline } from 'pages/layout/TwoColumn'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { Trans, useTranslation } from 'react-i18next'
import { DesktopDevice } from 'components/icons/DesktopDevice'
import { Dialog } from 'components/dialogs/Dialog'
import { Mail } from 'components/icons/Mail'
import { useDialogs } from 'hooks/dialogs'
import { GoogleSlidesDialog } from '../dialogs/GoogleSlidesDialog'
import { DateTime } from 'luxon'
import { CalendarIcon } from 'components/icons/Calendar'
import { ScheduleAssignmentDialog } from '../dialogs/ScheduleAssignmentDialog'
import classNames from 'classnames'
import { useBreakoutUser } from 'hooks/profile'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { FloatingIconActionButton } from 'components/breakout/FloatingIconActionButton'
import { AdminPanelSettingsIcon } from 'components/icons/AdminPanelSettings'

export const PageHeader = observer(function PageHeader() {
  const cubit = useInstructorAssignmentCubit()
  const rootStore = useRootStore()
  const user = useBreakoutUser()
  const percentageCompleted = cubit.assignmentProgress.percentageCompleted
  const showSlidesButton =
    percentageCompleted === 100 &&
    cubit.sectionAssignmentSlidesURL !== undefined

  return (
    <Header data-testid="instructor-assignment-header">
      <div className="flex h-full w-full flex-row justify-between">
        <Headline
          className="text-display-medium font-display min-w-[35%] cursor-pointer truncate"
          onClick={() =>
            rootStore.navigateTo('instructorClass', { id: cubit.sectionId })
          }
        >
          <div className="flex flex-row items-center">
            <div className="pr-1">
              <ChevronLeft size={24} />
            </div>
            {cubit.assignment.slideDeck.data.slideDeckName}
          </div>
        </Headline>
        <div className="flex flex-row items-center gap-5">
          {!cubit.isReadOnly && <ScheduleAssignmentButton />}
          {showSlidesButton ? (
            <GoogleSlidesDialogButton url={cubit.sectionAssignmentSlidesURL!} />
          ) : (
            <AssignmentCompletionProgressIndicator
              percentageCompleted={percentageCompleted}
            />
          )}
          {user.isAdmin && percentageCompleted > 0 && !cubit.isReadOnly && (
            <SendTestEmailButton />
          )}
        </div>
      </div>
    </Header>
  )
})

const GoogleSlidesDialogButton = observer(function GoogleSlidesDialogButton({
  url,
}: {
  url: string
}) {
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  return (
    <BreakoutButton
      kind="primary"
      size="large"
      onClick={() => showDialog(() => <GoogleSlidesDialog url={url} />)}
      icon={<DesktopDevice size={14} />}
    >
      <span>{t('instructor_assignment.view_teaching_slides')}</span>
    </BreakoutButton>
  )
})

const AssignmentCompletionProgressIndicator = observer(
  function AssignmentCompletionProgressIndicator({
    percentageCompleted,
  }: {
    percentageCompleted: number
  }) {
    const { t } = useTranslation()
    return (
      <div className="flex h-9 w-40 flex-col justify-between">
        <div className="flex flex-row justify-between">
          <span className="text-body-medium text-grey-text">
            {t('instructor_assignment.completion')}
          </span>
          <span className="text-title-small">
            {percentageCompleted.toFixed(0)}%
          </span>
        </div>
        <div className="h-1.5 w-full rounded-full bg-grey-text">
          <div
            className={classNames(`h-full rounded-full`, {
              'bg-breakout-red': percentageCompleted < 50,
              'bg-breakout-yellow':
                percentageCompleted >= 50 && percentageCompleted < 80,
              'bg-breakout-dark-green': percentageCompleted >= 80,
            })}
            style={{ width: `${percentageCompleted}%` }}
          />
        </div>
      </div>
    )
  }
)

const ScheduleAssignmentButton = observer(function ScheduleAssignmentButton() {
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  const cubit = useInstructorAssignmentCubit()

  return (
    <BreakoutButton
      kind="secondary"
      size="large"
      icon={<CalendarIcon size={14} />}
      onClick={() =>
        showDialog(() => (
          <ScheduleAssignmentDialog
            onSave={(d) => cubit.updateAssignmentExpiration(d)}
            expiresAt={
              cubit.assignment.expiresAt
                ? DateTime.fromJSDate(cubit.assignment.expiresAt)
                : undefined
            }
          />
        ))
      }
    >
      <span>
        {cubit.assignment.expiresAt ? (
          <Trans
            i18nKey="instructor_assignment.deadline"
            values={{
              date: DateTime.fromJSDate(cubit.assignment.expiresAt).toFormat(
                'MMM dd, yyyy'
              ),
            }}
            components={{ bold: <strong /> }}
          />
        ) : (
          t('instructor_assignment.choose_deadline')
        )}
      </span>
    </BreakoutButton>
  )
})

const SendTestEmailButton = observer(function SendTestEmailButton() {
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  const cubit = useInstructorAssignmentCubit()

  return (
    <FloatingIconActionButton
      kind="secondary"
      className="z-10 flex justify-end"
      menuBottom={true}
      Icon={AdminPanelSettingsIcon}
      actions={[
        {
          text: t('instructor_assignment.send_test_email'),
          Icon: ({ size }: { size: number }) => (
            <Mail size={size} className="fill-black stroke-black" />
          ),
          onClick: () => {
            showDialog(({ remove }) => (
              <Dialog size="xs" className="h-[400px]">
                <div className="flex h-full flex-col gap-3">
                  <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
                  <h2 className="text-headline-large mb-3">
                    {t('instructor_assignment.send_test_email')}
                  </h2>
                  <p className="text-body-large">
                    {t('instructor_assignment.send_test_email_confirmation')}
                  </p>
                  <div className="mt-4 flex h-full flex-col justify-end gap-3">
                    <BreakoutButton
                      kind="success"
                      onClick={() => {
                        cubit.sendTestEmail()
                        remove()
                      }}
                      fullWidth={true}
                      size="large"
                    >
                      {t('instructor_assignment.yes')}
                    </BreakoutButton>
                    <BreakoutButton
                      kind="secondary"
                      onClick={() => {
                        remove()
                      }}
                      size="large"
                      fullWidth={true}
                    >
                      {t('instructor_assignment.no')}
                    </BreakoutButton>
                  </div>
                </div>
              </Dialog>
            ))
          },
        },
      ]}
    />
  )

  return (
    <BreakoutButton
      kind="success"
      size="large"
      onClick={() => {
        showDialog(({ remove }) => (
          <Dialog size="xs" className="h-[400px] p-[3.25rem]">
            <div className="flex h-full flex-col">
              <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
              <h2 className="text-headline-large mb-3">
                {t('instructor_assignment.send_test_email')}
              </h2>
              <p className="text-body-large">
                {t('instructor_assignment.send_test_email_confirmation')}
              </p>
              <div className="flex h-full flex-col justify-end gap-3">
                <BreakoutButton
                  kind="success"
                  onClick={() => {
                    cubit.sendTestEmail()
                    remove()
                  }}
                  fullWidth={true}
                  size="large"
                >
                  {t('instructor_assignment.yes')}
                </BreakoutButton>
                <BreakoutButton
                  kind="secondary"
                  onClick={() => {
                    remove()
                  }}
                  size="large"
                  fullWidth={true}
                >
                  {t('instructor_assignment.no')}
                </BreakoutButton>
              </div>
            </div>
          </Dialog>
        ))
      }}
      icon={<Mail size={24} className="fill-white stroke-white" />}
    />
  )
})
