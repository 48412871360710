import type { Cubit } from '@breakoutlearning/firebase-repository/cubits/core'
import type { DependencyList } from 'react'
import { useEffect, useMemo } from 'react'

export function useCubitBuilder<T extends Cubit<object>>(
  builder: () => T,
  dependencies?: DependencyList | undefined
) {
  const cubit = useMemo(() => {
    return builder()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)

  useEffect(() => {
    cubit.initialize()
    return () => {
      cubit.dispose()
    }
  }, [cubit])

  return cubit
}
