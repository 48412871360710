import { FirebaseRepository } from '@breakoutlearning/firebase-repository/models/FirebaseRepository'
import { autorun } from 'mobx'
import { useEffect, useMemo, useState } from 'react'
import { getFirebaseConfig } from '../../config/firebase'
import { AuthenticationContext } from '../../hooks/auth'
import { inE2E } from 'config/environment'

export const AuthenticationContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const repository = useMemo(() => {
    return FirebaseRepository.build({
      firebase: getFirebaseConfig(),
      useLocalPersistence: inE2E(),
      disableAnalytics: inE2E(),
    }).initialize()
  }, [])

  const [authenticated, setAuthenticated] = useState(repository.isAuthenticated)
  const [initialized, setInitialized] = useState(repository.initialized)

  const usingLocalEmulator =
    import.meta.env.VITE_USE_EMULATOR === 'true' ||
    process.env.VITE_USE_EMULATOR === 'true'
  useEffect(() => {
    if (usingLocalEmulator) {
      const host = import.meta.env.VITE_USE_EMULATOR_HOST || 'localhost'
      try {
        repository.connectToEmulator(host)
      } catch (e) {
        console.error(e)
      }
    }
  }, [repository, usingLocalEmulator])

  useEffect(() => {
    const disposer = autorun(() => {
      setAuthenticated(repository.isAuthenticated)
      if (!initialized) setInitialized(repository.initialized)
    })
    return disposer
  }, [repository, initialized])

  return (
    <AuthenticationContext.Provider
      value={{
        initialized,
        repository,
        authenticated,
        usingLocalEmulator,
        setAuthenticated,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}
