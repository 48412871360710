import { observer } from 'mobx-react-lite'
import { StudentAssignmentStep1 } from './StudentAssignmentStep1'
import { StudentAssignmentStep2 } from './StudentAssignmentStep2'
import { StudentAssignmentStep3 } from './StudentAssignmentStep3'

export const StudentAssignmentStepsLoading = () => {
  return (
    <div className="flex h-full w-full flex-grow flex-col items-center justify-start overflow-auto rounded-3xl bg-primary lg:w-auto">
      <div className="h-full max-h-full">
        <div className="flex h-full flex-col items-center justify-center overflow-auto">
          Loading...
        </div>
      </div>
    </div>
  )
}

export const StudentAssignmentSteps = observer(
  function StudentAssignmentSteps() {
    return (
      <div className="flex w-full flex-grow flex-col items-center justify-between space-y-3 rounded-3xl bg-primary p-3 md:h-full  md:overflow-auto">
        <StudentAssignmentStep1 />
        <div className="w-[90%] max-w-[90%] border-t border-gray-200 md:w-[500px]"></div>
        <StudentAssignmentStep2 />
        <div className="w-[90%] max-w-[90%] border-t border-gray-200 md:w-[500px]"></div>
        <StudentAssignmentStep3 />
      </div>
    )
  }
)
