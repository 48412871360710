import { BreakoutDateTimePicker } from 'components/design-system/BreakoutDateTimePicker'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useDialogs } from 'hooks/dialogs'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

export const ScheduleAssignmentDialog = observer(
  function ScheduleAssignmentDialog({
    expiresAt,
    onSave,
  }: {
    onSave: (scheduledDate: DateTime) => Promise<void>
    expiresAt?: DateTime
  }) {
    // date time now plus 5 minutes
    const { popDialog } = useDialogs()
    const value = expiresAt || DateTime.now().plus({ minutes: 5 })

    const { t } = useTranslation()

    const handleSubmit = useCallback(
      // function takes on click event
      async (newDate: DateTime) => {
        const nowMinus1Minute = DateTime.now().minus({ minutes: 1 })
        if (newDate < nowMinus1Minute) {
          toast.error(t('instructor_assignment.scheduled_date_invalid'))
          return
        }
        try {
          await onSave(newDate)
          //toast.success('Assignment scheduled')
          popDialog()
        } catch (e) {
          toast.error('Error scheduling assignment')
          throw e
        }
      },
      [t, onSave, popDialog]
    )

    return (
      <Dialog
        size="custom"
        className="relative rounded-2xl !bg-core-tertiary p-13"
        ignoreBackdropClick
        ignoreEscapeKey
      >
        <DialogCloseButton />
        <BreakoutDateTimePicker
          value={value}
          id="meeting-time"
          kind="secondary"
          name="scheduledAt"
          min={DateTime.now()}
          onChange={(newValue) => {
            handleSubmit(newValue)
          }}
        />
      </Dialog>
    )
  }
)
