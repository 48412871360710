import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import type { RoomStateAnswer } from '@breakoutlearning/firebase-repository/models/RoomStateAnswer'
import type { SlideQuestion } from '@breakoutlearning/firebase-repository/models/SlideQuestion'
import { Dialog } from 'components/dialogs/Dialog'
import classNames from 'classnames'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { EmptyState } from 'components/breakout/EmptyState'
import { QuestionIcon } from 'components/icons/Question'
import { type ReactElement, useCallback, useMemo, useState } from 'react'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import {
  getQuizAnswerScore,
  getSortingQuizHistogram,
} from '@breakoutlearning/firebase-repository/util'
import { BreakoutBarGraphVertical } from 'components/breakout/BreakoutBarGraphVertical'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { roomStateUsers } from 'util/roomStateUsers'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import {
  BreakoutUserAvatar,
  BreakoutUserAvatarStack,
} from 'components/breakout/BreakoutUserAvatar'
import { roomStateName } from 'util/roomStateName'

export const AssignmentQuizSortingDialog = observer(
  function AssignmentQuizSortingDialog({
    answers,
    question,
    roomStates,
    section,
    usersMap,
  }: {
    answers: RoomStateAnswer[]
    question: SlideQuestion
    roomStates: RoomState[]
    section: Section
    usersMap: Map<string, PublicUser>
  }) {
    const { t } = useTranslation()
    const tScoped = useMemo(
      () => (key: string) => t(`instructor_assignment.${key}`),
      [t]
    )
    const maxHeight = useMemo(
      () =>
        Math.min(Math.max(question.data.answers.length * 135 + 128, 400), 700),
      [question.data.answers.length]
    )
    const [viewType, setViewType] = useState<'student' | 'group'>(
      question.hasGroupSlideId ? 'group' : 'student'
    )

    return (
      <Dialog
        size={answers.length === 0 ? 'sm' : 'lg'}
        className={classNames('min-w-[500px] !bg-primary', {
          '!h-[700px] !max-h-[700px] !max-w-[1200px] !bg-light-grey':
            answers.length > 0,
        })}
        innerClassName="h-full"
      >
        <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
        <div
          className="h-full min-h-[400px]"
          style={{
            maxHeight: `${maxHeight}px`,
          }}
        >
          {!answers.length ? (
            <EmptyState Icon={QuestionIcon} text={tScoped('quizzes_missing')} />
          ) : (
            <div className="flex h-full w-full flex-col">
              <div className="flex max-h-[200px] flex-row items-start p-4">
                <div className="flex flex-grow flex-col gap-2.5">
                  <h1 className="text-headline-large">
                    {tScoped('slide_question_type_sorting')}
                  </h1>
                </div>
                <BreakoutButton
                  className="!min-w-[200px] text-nowrap bg-light-grey"
                  kind="tertiary"
                  size="large"
                  onClick={() =>
                    setViewType((viewType) =>
                      viewType === 'student' ? 'group' : 'student'
                    )
                  }
                >
                  {viewType === 'student'
                    ? tScoped('switch_to_group_view')
                    : tScoped('switch_to_student_view')}
                </BreakoutButton>
              </div>
              <div className="p-4 pt-0">
                <h2 className="text-title-large line-clamp-2">
                  {question.questionWithoutNumbersAtBeginning}
                </h2>
              </div>

              {viewType === 'group' ? (
                <GroupHistogram
                  answers={answers}
                  question={question}
                  roomStates={roomStates}
                  usersMap={usersMap}
                  section={section}
                />
              ) : (
                <IndividualHistogram
                  question={question}
                  usersMap={usersMap}
                  answers={answers}
                  roomStates={roomStates}
                />
              )}
            </div>
          )}
        </div>
      </Dialog>
    )
  }
)

const IndividualHistogram = observer(function IndividualHistogram({
  answers,
  question,
  usersMap,
  roomStates,
}: {
  answers: RoomStateAnswer[]
  question: SlideQuestion
  usersMap: Map<string, PublicUser>
  roomStates: RoomState[]
}) {
  //default selected bucket to last key or first key with non-zero value
  const { t } = useTranslation()
  const tScoped = useCallback(
    (key: string) => t(`instructor_assignment.${key}`),
    [t]
  )

  const { histogram, resultsInBuckets, scale } = useMemo(
    () =>
      getHistogramDataFrom(
        answers.filter((a) =>
          roomStates.every((room) => room.id !== a.data.userId)
        ),
        question
      ),
    [answers, question, roomStates]
  )

  const userScores = useMemo(
    () =>
      getScoreByUserOrGroup({
        resultsInBuckets,
        question,
      }),
    [resultsInBuckets, question]
  )

  const [selectedBucket, setSelectedBucket] = useState<string>(
    getDefaultSelectedBucket(histogram)
  )

  return (
    <div className="flex flex-grow flex-row">
      <div className="flex-grow pt-2">
        <BreakoutBarGraphVertical
          histogram={Object.fromEntries(histogram.entries())}
          scale={scale}
          xLabel={tScoped('score')}
          yLabel={tScoped('number_of_students')}
          selectedBucket={selectedBucket}
          onPressed={(bucket) => setSelectedBucket(bucket)}
          colorForeground="bg-light-grey-text"
          colorBackground="bg-light-grey"
          className="w-full"
        />
      </div>
      <div className="flex w-[250px] flex-col px-5">
        <h3 className="text-body-medium text-grey-text">
          {tScoped('students')}
        </h3>
        <div className="pr-[14px]">
          {resultsInBuckets.get(selectedBucket)?.map((answer) => {
            const user = usersMap.get(answer.data.userId)
            if (!user) return null
            const score = userScores.get(answer.data.userId)
            return (
              <div
                key={answer.id}
                className="flex h-11 flex-row items-center border-b border-light-grey"
              >
                <BreakoutUserAvatar user={user} radius={16} />
                <span className="text-label-small ml-2 flex-grow">
                  {user.fullName}
                </span>
                <span className="text-label-small">
                  {score ? Math.round(score) : ''}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
})

const GroupHistogram = observer(function GroupHistogram({
  answers,
  question,
  roomStates,
  usersMap,
  section,
}: {
  answers: RoomStateAnswer[]
  question: SlideQuestion
  roomStates: RoomState[]
  usersMap: Map<string, PublicUser>
  section: Section
}) {
  const { t } = useTranslation()
  const tScoped = useCallback(
    (key: string) => t(`instructor_assignment.${key}`),
    [t]
  )
  const [showSynergy, groupHeight] = useMemo(() => {
    const showSynergy = question.hasGroupSlideId && question.hasSlideId
    const groupHeight = showSynergy ? 106 : 76
    return [showSynergy, groupHeight]
  }, [question])

  const { histogram, resultsInBuckets, scale } = useMemo(
    () =>
      getHistogramDataFrom(
        answers.filter((a) =>
          roomStates.some((room) => room.id === a.data.userId)
        ),
        question
      ),
    [answers, question, roomStates]
  )

  const groupScores = useMemo(
    () =>
      getScoreByUserOrGroup({
        resultsInBuckets,
        question,
      }),
    [resultsInBuckets, question]
  )

  const [selectedBucket, setSelectedBucket] = useState<string>(
    getDefaultSelectedBucket(histogram)
  )

  const bucketResults = resultsInBuckets.get(selectedBucket) ?? []

  return (
    <div className="flex flex-grow flex-row">
      <div className="flex-grow pt-2">
        <BreakoutBarGraphVertical
          histogram={Object.fromEntries(histogram.entries())}
          scale={scale}
          xLabel={tScoped('score')}
          yLabel={tScoped('number_of_groups')}
          selectedBucket={selectedBucket}
          onPressed={(bucket) => setSelectedBucket(bucket)}
          colorForeground="bg-light-grey-text"
          colorBackground="bg-light-grey"
          className="w-full"
        />
      </div>
      {/* groups view */}
      <div className="flex w-[280px] min-w-[280px] flex-col px-5">
        <h3 className="text-body-medium text-grey-text">{tScoped('groups')}</h3>
        <div className="pr-[14px]">
          {bucketResults.map((answer) => {
            if (
              !roomStates.some(
                (roomState) => roomState.id === answer.data.userId
              )
            )
              return null

            const roomState = roomStates.find(
              (roomState) => roomState.id === answer.data.userId
            )
            if (!roomState) return null
            const roomId = roomState.id
            const roomStateAnswers = answers.filter(
              (a) =>
                roomState.userIds.includes(a.data.userId) &&
                a.data.roomId === roomId
            )
            const userScores = (() => {
              const userScores = new Map<string, number>()
              for (const answer of roomStateAnswers) {
                const score =
                  question.getWorstSortingScore -
                  getQuizAnswerScore({ answer, question }) *
                    question.getWorstSortingScore
                userScores.set(answer.data.userId, score)
              }
              return userScores
            })()
            roomStateAnswers.sort(
              (a, b) =>
                (userScores.get(a.data.userId) ?? 0) -
                (userScores.get(b.data.userId) ?? 0)
            )

            type ScoreTextColor = 'grey' | 'green' | 'red'

            let averageScore = 0
            let averageScoreColor: ScoreTextColor = 'grey'
            let bestScore = 0
            let bestScoreColor: ScoreTextColor = 'grey'
            const groupScore = groupScores.get(roomId) ?? 0
            if (showSynergy && userScores.size > 0) {
              averageScore =
                Array.from(userScores.values()).reduce(
                  (acc, score) => acc + score,
                  0
                ) / userScores.size
              averageScoreColor = averageScore > groupScore ? 'red' : 'green'
              bestScore = Math.min(
                question.getWorstSortingScore,
                ...Array.from(userScores.values())
              )
              bestScoreColor = bestScore > groupScore ? 'red' : 'green'
            }
            const groupSortingDetails = (
              <GroupSortingDetails
                answer={answer}
                averageScore={averageScore}
                bestScore={bestScore}
                roomState={roomState}
                section={section}
                usersMap={usersMap}
                averageScoreColor={averageScoreColor}
                bestScoreColor={bestScoreColor}
                groupScores={groupScores}
                showSynergy={showSynergy}
              />
            )
            return (
              <div
                key={answer.id}
                className="border-b border-medium-grey py-3"
                style={{ height: groupHeight }}
              >
                {showSynergy ? (
                  <GroupSortingTooltip
                    roomStateAnswers={roomStateAnswers}
                    usersMap={usersMap}
                    userScores={userScores}
                    groupScore={groupScore}
                  >
                    {groupSortingDetails}
                  </GroupSortingTooltip>
                ) : (
                  groupSortingDetails
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
})

const GroupSortingDetails = observer(function GroupSortingDetails({
  answer,
  averageScore,
  bestScore,
  roomState,
  section,
  usersMap,
  averageScoreColor,
  bestScoreColor,
  groupScores,
  showSynergy,
}: {
  answer: RoomStateAnswer
  averageScore: number
  bestScore: number
  roomState: RoomState
  section: Section
  usersMap: Map<string, PublicUser>
  averageScoreColor: 'grey' | 'green' | 'red'
  bestScoreColor: 'grey' | 'green' | 'red'
  groupScores: Map<string, number>
  showSynergy: boolean
}) {
  const users = roomStateUsers(roomState, usersMap)
  const { t } = useTranslation()
  const tScoped = useCallback(
    (key: string) => t(`instructor_assignment.${key}`),
    [t]
  )
  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-2">
        <h4 className="text-label-medium flex-grow">
          {roomStateName({
            roomState,
            section,
            usersMap,
          })}
        </h4>
        <div
          className="h-8"
          style={{ width: Math.max(users.length * 16 * 1.5, 32) }}
        >
          <BreakoutUserAvatarStack users={users} radius={16} />
        </div>
      </div>
      <div className="flex flex-row">
        <span className="text-body-small flex-grow text-grey-text">
          {tScoped('group_score')}
        </span>
        <span className="text-label-medium">
          {groupScores.get(answer.data.userId) ?? ''}
        </span>
      </div>
      {showSynergy && (
        <>
          <div className="flex flex-row">
            <span className="text-body-small flex-grow text-grey-text">
              {tScoped('average_individual_score')}
            </span>
            <span
              className={classNames('text-body-small', {
                'text-breakout-red': averageScoreColor === 'red',
                'text-breakout-dark-green': averageScoreColor === 'green',
                'text-grey-text': averageScoreColor === 'grey',
              })}
            >
              {Math.round(averageScore)}
            </span>
          </div>
          <div className="flex flex-row">
            <span className="text-body-small flex-grow text-grey-text">
              {tScoped('best_individual_score')}
            </span>
            <span
              className={classNames('text-body-small', {
                'text-breakout-red': bestScoreColor === 'red',
                'text-breakout-dark-green': bestScoreColor === 'green',
                'text-grey-text': bestScoreColor === 'grey',
              })}
            >
              {Math.round(bestScore)}
            </span>
          </div>
        </>
      )}
    </div>
  )
})

const GroupSortingTooltip = observer(function GroupSortingTooltip({
  roomStateAnswers,
  usersMap,
  userScores,
  groupScore,
  children,
}: {
  roomStateAnswers: RoomStateAnswer[]
  usersMap: Map<string, PublicUser>
  userScores: Map<string, number>
  groupScore: number
  children: ReactElement
}) {
  return (
    <BreakoutTooltip
      delay={{ open: 500 }}
      content={
        <div className="mr-10 w-[330px] rounded-xl bg-primary px-10 pb-10 pt-8 shadow-2xl">
          {roomStateAnswers.map((userAnswer) => {
            const user = usersMap.get(userAnswer.data.userId)
            if (!user) return null
            const userScore = userScores.get(userAnswer.data.userId) ?? 0
            const color = userScore > groupScore ? 'red' : 'green'
            return (
              <div
                className="flex h-[50px] flex-row items-center"
                key={userAnswer.id}
              >
                <BreakoutUserAvatar user={user} radius={16} />
                <span className="text-label-small ml-2 flex-grow">
                  {user.fullName}
                </span>
                <span
                  className={classNames(`text-label-small`, {
                    'text-breakout-red': color === 'red',
                    'text-breakout-dark-green': color === 'green',
                  })}
                >
                  {Math.round(userScore)}
                </span>
              </div>
            )
          })}
        </div>
      }
    >
      <div className="cursor-default">{children}</div>
    </BreakoutTooltip>
  )
})

const getHistogramDataFrom = (
  answers: RoomStateAnswer[],
  question: SlideQuestion
) => {
  const { histogram, resultsInBuckets } = getSortingQuizHistogram({
    answers: answers,
    question,
  })

  const max = Math.max(...histogram.values())
  const maxRounded = Math.ceil(max / 10) * 10
  const delta = Math.round(maxRounded / 10)
  const scale = Array.from(
    { length: histogram.size + 1 },
    (_, index) => index * delta
  )

  return {
    histogram,
    resultsInBuckets,
    scale,
  }
}

const getDefaultSelectedBucket = (histogram: Map<string, number>) => {
  // get last element that satisfies the condition
  const keys = Array.from(histogram.keys())
  // default last element
  let value = keys[keys.length - 1]
  for (const key of histogram.keys()) {
    if (histogram.get(key) ?? 0 > 0) {
      value = key
    }
  }
  return value
}

const getScoreByUserOrGroup = ({
  resultsInBuckets,
  question,
}: {
  resultsInBuckets: Map<string, RoomStateAnswer[]>
  question: SlideQuestion
}) => {
  const scoresMap = new Map<string, number>()
  for (const entryValue of resultsInBuckets.values()) {
    for (const answer of entryValue) {
      const score =
        question.getWorstSortingScore -
        getQuizAnswerScore({ answer, question }) * question.getWorstSortingScore
      scoresMap.set(answer.data.userId, score)
    }
  }
  return scoresMap
}
