import { observer } from 'mobx-react-lite'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { useState } from 'react'
import { Spinner } from 'components/Spinner'
import classNames from 'classnames'

export const GoogleSlidesDialog = observer(function GoogleSlidesDialog({
  url,
}: {
  url: string
}) {
  const viewId = 'google_slides_frame'
  const [loading, setLoading] = useState(true)

  return (
    <Dialog size="lg">
      <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
      <div id={viewId} className={classNames('aspect-video w-full')}>
        {loading && (
          <div className="absolute inset-0 flex h-full items-center justify-center">
            <Spinner />
          </div>
        )}
        <iframe
          src={`${url}/preview`}
          onLoad={() => setLoading(false)}
          className="h-full w-full border-none"
        />
      </div>
    </Dialog>
  )
})
