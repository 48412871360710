import type { BreakoutDesignSystemTheme } from 'components/design-system/types'

const theme: BreakoutDesignSystemTheme = {
  switch: {
    root: {
      base: 'inline-flex items-center',
      disabled: 'opacity-50',
    },
    container: {
      base: 'relative inline-block w-8 h-4 rounded-full',
      enabled: 'cursor-pointer',
    },
    input: {
      base: 'peer appearance-none w-8 h-4 absolute bg-surface-dim rounded-full transition-colors duration-300 checked:bg-gray-900 peer-checked:border-gray-900 peer-checked:before:bg-gray-900',
      enabled: 'cursor-pointer',
    },
    circle: {
      base: "bg-white w-5 h-5 border border-blue-gray-100 rounded-full shadow-md absolute top-2/4 -left-1 -translate-y-2/4 peer-checked:translate-x-full transition-all duration-300  before:content[''] before:block before:bg-blue-gray-500 before:w-10 before:h-10 before:rounded-full before:absolute before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 before:transition-opacity before:opacity-0 peer-checked:border-gray-900 peer-checked:before:bg-gray-900",
      enabled: 'hover:before:opacity-10 cursor-pointer',
    },
    ripple: {
      base: 'inline-block top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 p-5 rounded-full',
    },
    label: {
      base: 'text-body-medium px-4 text-on-surface-var select-none',
      enabled: 'cursor-pointer',
    },
  },
}

export { theme }
