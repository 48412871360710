import { NavLink } from './NavLink'
import { Home } from '../../components/icons/Home'
import GraduationCap from '@iconscout/unicons/svg/line/graduation-cap.svg'
import { Search } from '../../components/icons/Search'
import { PeopleMultiple } from '../../components/icons/PeopleMultiple'
import { PeopleGroup } from '../../components/icons/PeopleGroup'
import { Dashboard } from '../../components/icons/Dashboard'
import { BookOpen } from '../../components/icons/BookOpen'
import { GridIcon } from '../../components/icons/Grid'
import { Person } from '../../components/icons/Person'
import { SignOut } from '../../components/icons/SignOut'
import { useRootStore } from 'hooks/rootStore'
import { useTranslation } from 'react-i18next'
import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { ThreeLineHorizontalIcon } from '../../components/icons/ThreeLineHorizontal'
import { useCallback, useState } from 'react'
import { KeyCapIcon } from '../../components/icons/KeyCap'
import { BreakoutTooltip } from '../../components/design-system/BreakoutTooltip'
import { BreakoutDrawer } from '../../components/design-system/BreakoutDrawer'
import { BookClose } from 'components/icons/BookClose'
import { Environment, environment } from 'config/environment'

const activeClassName = 'bg-surface'
const navLinkClassName = `inline-block cursor-pointer rounded-lg p-2 hover:bg-surface`

function AdminNavigation() {
  const { t } = useTranslation()

  return (
    <>
      <div className="text-center md:mb-8 ">
        <NavLink
          tooltip={t('navigation.home')}
          to="home"
          className={navLinkClassName}
          activeClassName={activeClassName}
          activeOn="home, assignment, assignmentWithRoom"
        >
          <Home aria-hidden size={25} className="inline" />
        </NavLink>
      </div>
      <hr className="mx-auto w-12" />
      <div className="hidden md:block">
        <div className="mb-14 mt-6 text-center">
          <NavLink
            tooltip={t('navigation.instructor_classes')}
            to="instructorClasses"
            className={navLinkClassName}
            activeClassName={activeClassName}
            activeOn="instructorClass, instructorClasses, instructorClassAssignment"
          >
            <img
              aria-hidden
              src={GraduationCap}
              width={25}
              className="inline"
            />
          </NavLink>
        </div>
        <div className="mb-14 text-center">
          <NavLink
            tooltip={t('navigation.instructor_library')}
            to="instructorLibrary"
            className={navLinkClassName}
            activeClassName={activeClassName}
            activeOn="instructorLibrary, instructorSlideDeck"
          >
            <Search aria-hidden size={25} className="inline" />
          </NavLink>
        </div>
        <div className="mb-14 text-center">
          <NavLink
            tooltip={t('navigation.instructor_assistants')}
            to="instructorAssistants"
            className={navLinkClassName}
            activeClassName={activeClassName}
          >
            <PeopleMultiple aria-hidden size={25} className="inline" />
          </NavLink>
        </div>
        <BreakoutTooltip content={t('navigation.admin_dashboard')}>
          <div className="mb-14 text-center">
            <NavLink
              tooltip={t('navigation.admin_dashboard')}
              to="adminDashboard"
              className={navLinkClassName}
              activeClassName={activeClassName}
            >
              <Dashboard aria-hidden size={25} className="inline" />
            </NavLink>
          </div>
        </BreakoutTooltip>
        <BreakoutTooltip content={t('navigation.admin_library')}>
          <div className="mb-14 text-center">
            <NavLink
              tooltip={t('navigation.admin_library')}
              to="adminLibrary"
              className={navLinkClassName}
              activeClassName={activeClassName}
            >
              <BookOpen aria-hidden size={25} className="inline" />
            </NavLink>
          </div>
        </BreakoutTooltip>
        <BreakoutTooltip content={t('navigation.admin_catalogs')}>
          <div className="mb-14 text-center">
            <NavLink
              tooltip={t('navigation.admin_catalogs')}
              to="adminCatalogs"
              className={navLinkClassName}
              activeClassName={activeClassName}
            >
              <GridIcon aria-hidden size={25} className="inline" />
            </NavLink>
          </div>
        </BreakoutTooltip>
        <BreakoutTooltip content={t('navigation.admin_users')}>
          <div className="mb-14 text-center">
            <NavLink
              tooltip={t('navigation.admin_users')}
              to="adminUsers"
              className={navLinkClassName}
              activeClassName={activeClassName}
            >
              <PeopleGroup aria-hidden size={25} className="inline" />
            </NavLink>
          </div>
        </BreakoutTooltip>
      </div>
    </>
  )
}

function FacultyNavigation() {
  const { t } = useTranslation()
  const user = useBreakoutUser()

  return (
    <>
      <div className="text-center md:mb-8">
        <NavLink
          tooltip={t('navigation.home')}
          to="home"
          className={navLinkClassName}
          activeClassName={activeClassName}
          activeOn="home, instructorClass, instructorClasses, instructorClassAssignment"
        >
          <Home aria-hidden size={25} className="inline" />
        </NavLink>
      </div>
      <hr className="mx-auto w-12" />
      <div className="hidden md:block">
        <div className="mb-14  mt-6 text-center">
          <NavLink
            tooltip={t('navigation.instructor_library')}
            to="instructorLibrary"
            className={navLinkClassName}
            activeClassName={activeClassName}
            activeOn="instructorLibrary, instructorSlideDeck"
          >
            <Search aria-hidden size={25} className="inline" />
          </NavLink>
        </div>
        <div className="mb-14 text-center">
          <NavLink
            tooltip={t('dart.student_view')}
            to="instructorStudentView"
            className={navLinkClassName}
            activeClassName={activeClassName}
            activeOn="instructorStudentView, assignment, assignmentWithRoom"
          >
            <BookClose aria-hidden size={25} className="inline" />
          </NavLink>
        </div>
        {user.isInstructor && (
          <div className="mb-14 text-center">
            <NavLink
              tooltip={t('navigation.instructor_assistants')}
              to="instructorAssistants"
              className={navLinkClassName}
              activeClassName={activeClassName}
              activeOn="instructorAssistants"
            >
              <PeopleMultiple aria-hidden size={25} className="inline" />
            </NavLink>
          </div>
        )}
      </div>
    </>
  )
}

function StudentNavigation() {
  const { t } = useTranslation()

  return (
    <>
      <div className="text-center md:mb-8">
        <NavLink
          tooltip={t('navigation.home')}
          to="home"
          className="inline-block cursor-pointer rounded-lg p-2 hover:bg-gray-50"
          activeClassName="bg-gray-50"
          activeOn="home, assignment , assignmentWithRoom"
        >
          <Home aria-hidden size={25} className="inline" />
        </NavLink>
      </div>
    </>
  )
}

function MobileNavigationDrawer({ closeDrawer }: { closeDrawer: () => void }) {
  const { t } = useTranslation()
  const rootStore = useRootStore()
  const breakoutUser = useBreakoutUser()

  const linkClass =
    'inline-block w-full cursor-pointer py-6 px-4 hover:bg-gray-200'
  const iconClass = 'mr-3 inline'

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="my-5 text-center">
        <img src="/assets/images/logo.png" width={48} className="inline" />
      </div>
      <div className="h-full w-full text-left text-sm">
        <NavLink
          to="home"
          onClick={closeDrawer}
          className={linkClass}
          activeClassName="bg-gray-50"
          activeOn="home, assignment"
        >
          <Home size={25} className={iconClass} />
          {t('navigation.profile')}
        </NavLink>
        {breakoutUser.isAdmin && (
          <>
            <NavLink
              to="instructorClasses"
              onClick={closeDrawer}
              className={linkClass}
              activeClassName="bg-gray-50"
            >
              <img src={GraduationCap} width={25} className={iconClass} />
              {t('navigation.instructor_classes')}
            </NavLink>
          </>
        )}
      </div>
      <div className="w-full text-left text-sm">
        {environment() !== Environment.production && (
          <NavLink
            to="designSystem"
            onClick={closeDrawer}
            className={linkClass}
            activeClassName="bg-gray-50"
          >
            <KeyCapIcon size={25} className={iconClass} />
            Design System
          </NavLink>
        )}
        <NavLink
          to="profile"
          onClick={closeDrawer}
          className={linkClass}
          activeClassName="bg-gray-50"
        >
          <Person size={25} className={iconClass} />
          {t('navigation.profile')}
        </NavLink>
        <a
          className={linkClass}
          onClick={() => {
            closeDrawer()
            rootStore.navigateTo('logout')
          }}
        >
          <SignOut size={25} className={iconClass} />
          {t('navigation.logout')}
        </a>
      </div>
    </div>
  )
}

const MobileTopNavigation = observer(function MobileTopNavigation() {
  const [open, setOpen] = useState(false)
  const [mounted, setMounted] = useState(false)

  const openDrawer = useCallback(() => {
    setOpen(true)
    setMounted(true)
  }, [])
  const closeDrawer = useCallback(() => {
    setOpen(false)
    setTimeout(() => {
      setMounted(false)
    }, 200)
  }, [])

  return (
    <div
      className={`
      flex
      flex-row
      items-center
      justify-between
      border-b
      border-light-grey-text
      py-3
      md:hidden
  `}
    >
      <div className="w-[25%] flex-none text-left">
        <span className="mx-5"> </span>
        {/* empty for now, necessary for logo to be in the middle */}
      </div>
      <div className="mx-5 flex-1 text-center">
        <img src="/assets/images/logo.png" width={48} className="inline" />
      </div>
      <div className="w-[25%] flex-none text-right">
        <button onClick={openDrawer}>
          <ThreeLineHorizontalIcon className="mx-5 inline" />
        </button>
        <BreakoutDrawer
          open={open}
          onClose={closeDrawer}
          className=""
          placement="right"
        >
          {mounted && <MobileNavigationDrawer closeDrawer={closeDrawer} />}
        </BreakoutDrawer>
      </div>
    </div>
  )
})

const BrowserNavigation = observer(function Navigation() {
  const { t } = useTranslation()
  const rootStore = useRootStore()
  const breakoutUser = useBreakoutUser()

  return (
    <div
      className={`
      mb-5
      box-border
      hidden
      items-center
      justify-start
      rounded-3xl bg-primary
      text-center

      md:mb-0 md:flex md:h-full md:w-[4%] md:min-w-[100px] md:flex-shrink-0 md:flex-grow-0 md:flex-col
      `}
    >
      <div className="my-9 ml-5 text-center md:ml-0">
        <img src="/assets/images/logo.png" width={48} className="inline" />
      </div>
      <div className="isolate w-full pt-2 md:ml-0 md:mt-14 md:h-full md:overflow-y-auto">
        {breakoutUser.isAdmin && <AdminNavigation />}
        {breakoutUser.isFaculty && <FacultyNavigation />}
        {breakoutUser.isStudent && <StudentNavigation />}
      </div>

      <div className="flex w-full flex-grow flex-row justify-end pt-8 md:block md:w-auto md:flex-none">
        {environment() !== Environment.production && (
          <div className="mr-5 text-center md:mx-auto md:mb-5">
            <NavLink
              tooltip={'Design System'}
              aria-label="Design System"
              to="designSystem"
              className={navLinkClassName}
              activeClassName={activeClassName}
            >
              <KeyCapIcon size={25} className="inline" />
            </NavLink>
          </div>
        )}
        <BreakoutTooltip content={t('navigation.profile')}>
          <div className="mr-5 text-center md:mx-auto md:mb-5">
            <NavLink
              to="profile"
              className={navLinkClassName}
              activeClassName={activeClassName}
            >
              <Person size={25} className="inline" />
            </NavLink>
          </div>
        </BreakoutTooltip>
        <div className="mr-5 text-center md:mx-auto md:mb-9">
          <BreakoutTooltip content={t('navigation.logout')}>
            <a aria-label={t('navigation.logout')} className={navLinkClassName}>
              <SignOut
                onClick={() => rootStore.navigateTo('logout')}
                size={25}
                className="inline"
              />
            </a>
          </BreakoutTooltip>
        </div>
      </div>
    </div>
  )
})

export function Navigation() {
  return (
    <>
      <MobileTopNavigation />
      <BrowserNavigation />
    </>
  )
}
