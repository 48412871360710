import { InstructorCatalogsCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorCatalogsCubit'
import type { Catalog } from '@breakoutlearning/firebase-repository/models/Catalog'
import type { SlideDeck } from '@breakoutlearning/firebase-repository/models/SlideDeck'
import { groupSlideDecksByTypeAndSortByUpdateDate } from '@breakoutlearning/firebase-repository/util'
import { Breadcrumb } from 'components/Breadcrumb'
import { ImpersonationInfo } from 'components/ImpersonationInfo'
import { Spinner } from 'components/Spinner'
import { FloatingIconActionButton } from 'components/breakout/FloatingIconActionButton'
import { BreakoutCard } from 'components/design-system/BreakoutCard'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { InstructorLibraryCard } from 'components/design-system/cards/InstructorLibraryCard'
import { AdminPanelSettingsIcon } from 'components/icons/AdminPanelSettings'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { GraduationCapIcon } from 'components/icons/GraduationCap'
import { useRepository } from 'hooks/auth'
import { useCubitBuilder } from 'hooks/cubits'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { SlideDeckGroupDialog } from 'pages/admin/library/SlideDeckGroupDialog'
import { Contents, Header, MainPane } from 'pages/layout/TwoColumn'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function InstructorLibraryPage() {
  const repository = useRepository()
  const cubit = useCubitBuilder(
    () => new InstructorCatalogsCubit(repository),
    [repository]
  )
  const { t } = useTranslation()
  const rootStore = useRootStore()

  const sectionId = rootStore.router.queryParams?.sectionId?.toString()

  const [adminView, setAdminView] = useState(false)
  const isAdmin = cubit.repository.breakoutUser?.isAdmin || false

  return (
    <MainPane className="mt-[9px]">
      <Header>
        <Breadcrumb
          onClick={() => {
            if (sectionId) {
              return rootStore.navigateTo('instructorClass', { id: sectionId })
            }
            rootStore.navigateTo('home')
          }}
        >
          {t('instructor_library.headline')}
        </Breadcrumb>
        <ImpersonationInfo />
        {isAdmin && (
          <FloatingIconActionButton
            kind="secondary"
            className="z-10 flex justify-end"
            menuBottom={true}
            Icon={AdminPanelSettingsIcon}
            actions={[
              {
                text: t(
                  `instructor_library.${adminView ? 'instructor' : 'admin'}_view`
                ),
                Icon: adminView ? GraduationCapIcon : AdminPanelSettingsIcon,
                onClick: () => setAdminView(!adminView),
              },
            ]}
          />
        )}
      </Header>
      <Contents className="h-full w-full overflow-auto pb-4 pr-1">
        <PageContent cubit={cubit} adminView={adminView} />
      </Contents>
    </MainPane>
  )
}

const PageContent = observer(function PageContent({
  cubit,
  adminView,
}: {
  cubit: InstructorCatalogsCubit
  adminView: boolean
}) {
  if (cubit.isLoading) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (adminView) {
    return <AdminCasesView cubit={cubit} />
  }

  return (
    <div className="flex flex-col gap-5">
      {cubit.catalogs.models.map((catalog) => (
        <CatalogView key={catalog.id} catalog={catalog} cubit={cubit} />
      ))}
    </div>
  )
})

const AdminCasesView = observer(function AdminCasesView({
  cubit,
}: {
  cubit: InstructorCatalogsCubit
}) {
  const { t } = useTranslation()
  const { showDialog } = useDialogs()
  const slideDeckGroupsByType = useMemo(
    () => groupSlideDecksByTypeAndSortByUpdateDate(cubit.slideDecks),
    [cubit.slideDecks]
  )
  const store = useRootStore()

  return (
    <BreakoutCard.Grid>
      {Object.entries(slideDeckGroupsByType).map(([typeId, group]) => {
        const { imageUrl, name, teaser } = group
        return (
          <BreakoutCard
            key={typeId}
            onClick={() => {
              showDialog(() => (
                <SlideDeckGroupDialog
                  slideDeckGroup={group}
                  catalogs={cubit.catalogs.models}
                  onSlideDeckClick={(slideDeckId) => {
                    store.navigateTo(
                      'instructorSlideDeck',
                      { slideDeckId },
                      store.router.queryParams
                    )
                  }}
                />
              ))
            }}
          >
            <BreakoutCard.Body className="gap-2">
              <div className="flex rounded-lg">
                <div className="min-h-[35px] min-w-[35px]">
                  <img
                    src={imageUrl || undefined}
                    className="inline max-h-[35px] max-w-[35px] object-cover"
                  />
                </div>
              </div>
              <BreakoutTooltip content={name}>
                <h2 className="text-body-medium line-clamp-1 text-on-surface-var">
                  {name}
                </h2>
              </BreakoutTooltip>

              <BreakoutTooltip content={teaser || ''}>
                <h2 className="text-title-medium line-clamp-2 h-[42px]">
                  {teaser || ''}
                </h2>
              </BreakoutTooltip>
            </BreakoutCard.Body>
            <BreakoutCard.Footer>
              <div>
                <span className="text-body-medium text-on-surface-var">
                  {t('admin_library.versions', {
                    count: group.slideDecks.length,
                  })}
                </span>
              </div>
            </BreakoutCard.Footer>
          </BreakoutCard>
        )
      })}
    </BreakoutCard.Grid>
  )
})

const CatalogView = observer(function CatalogView({
  catalog,
  cubit,
}: {
  catalog: Catalog
  cubit: InstructorCatalogsCubit
}) {
  const [isOpen, setIsOpen] = useState(true)
  const slideDecks =
    catalog.id in cubit.slideDecksByCatalogId
      ? cubit.slideDecksByCatalogId[catalog.id]
      : []
  if (!slideDecks.length) {
    return null
  }
  return (
    <div>
      <div className="flex flex-row justify-between">
        <h2 className="text-headline-medium">{catalog.data.catalogName}</h2>
        <div onClick={() => setIsOpen(!isOpen)} className="cursor-pointer">
          <ChevronLeft className={isOpen ? 'rotate-270' : 'rotate-90'} />
        </div>
      </div>
      {isOpen && (
        <BreakoutCard.Grid className="mt-5">
          {slideDecks.map((slideDeck) => (
            <SlideDeckView
              key={slideDeck.id}
              slideDeck={slideDeck}
              catalogId={catalog.id}
            />
          ))}
        </BreakoutCard.Grid>
      )}
    </div>
  )
})

const SlideDeckView = observer(function SlideDeckView({
  slideDeck,
  catalogId,
}: {
  slideDeck: SlideDeck
  catalogId: string
}) {
  const rootStore = useRootStore()
  const queryParams = rootStore.router.queryParams

  return (
    <InstructorLibraryCard
      imageUrl={slideDeck.data.slideDeckImageURL}
      title={slideDeck.data.slideDeckName}
      subtitle={slideDeck.data.slideDeckTeaser}
      isFree={slideDeck.data.slideDeckFree}
      price={slideDeck.data.slideDeckPrice}
      onClick={() => {
        rootStore.navigateTo(
          'instructorSlideDeck',
          {
            slideDeckId: slideDeck.id,
          },
          { ...queryParams, catalogId }
        )
      }}
    />
  )
})
