import { useEffect } from 'react'
import { useAuth, useRepository } from '../../hooks/auth'
import { useRootStore } from 'hooks/rootStore'

export function LogoutPage() {
  const auth = useAuth()
  const rootStore = useRootStore()
  const repository = useRepository()

  useEffect(() => {
    repository.logout()
    // this thing is so fast I have to slow it down for you too see the logout screen
    // new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
    auth.signOut().then(async () => {
      rootStore.navigateTo('home')
    })
    // })
  }, [auth, rootStore, repository])

  return (
    <div className="relative box-border flex h-full flex-grow flex-col items-center justify-center overflow-auto rounded-3xl bg-primary p-8 px-[2.5rem]">
      <div>Logging you out...</div>
    </div>
  )
}
