import { BreakoutExperienceWidget } from 'components/BreakoutExperience'
import { useBreakoutUser } from 'hooks/profile'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { PodcastsComponent } from 'pages/student/podcasts/PodcastsPage'
import { useRootStore } from 'hooks/rootStore'

const OnboardingPortraitViewContext = createContext(false)

const useOnboardingPortraitView = () =>
  useContext(OnboardingPortraitViewContext)

export const OnboardingPage = () => {
  const user = useBreakoutUser()
  const [portraitView, setPortraitView] = useState(false)
  const { t } = useTranslation()
  const store = useRootStore()

  const setOnboardCompleteAndRedirect = useCallback(() => {
    user.setOnboardingComplete()
    store.navigateTo('home')
  }, [user, store])

  useEffect(() => {
    const setPortraitModeOnResize = () => {
      if (window.innerHeight > window.innerWidth && window.innerWidth < 850) {
        console.log('portrait mode')
        return setPortraitView(true)
      } else {
        setPortraitView(false)
      }
    }
    // run once on load
    setPortraitModeOnResize()
    window.addEventListener('resize', setPortraitModeOnResize)
    return () => {
      window.removeEventListener('resize', setPortraitModeOnResize)
    }
  }, [])

  return (
    <div className="flex h-full w-full items-center justify-center p-[0.75rem]">
      <div
        className={classNames(
          'h-full w-full max-w-[75rem] rounded-3xl bg-primary',
          {
            'max-h-[43.75rem]': !portraitView,
          }
        )}
      >
        <OnboardingPortraitViewContext.Provider value={portraitView}>
          <BreakoutExperienceWidget
            includeBackButton={true}
            components={[Page1, Page2, Page3, Page4, Page5, Page6, Page7]}
            doneButtonText={t('onboarding.continue')}
            onDone={setOnboardCompleteAndRedirect}
            onSkip={setOnboardCompleteAndRedirect}
          />
        </OnboardingPortraitViewContext.Provider>
      </div>
    </div>
  )
}

const OnboardingScreenWithImageTemplate = ({
  imageSrc,
  title,
  subtitle,
  includeImageShadow = true,
  includeDropShadow = false,
}: {
  imageSrc: string
  title: string
  subtitle: string
  includeImageShadow?: boolean
  includeDropShadow?: boolean
}) => {
  const portraitView = useOnboardingPortraitView()
  return (
    <div
      className={classNames('flex h-full w-full items-center justify-center', {
        //todo: sort out padding top/bot with no more absolute positioning
        //'px-3': portraitView,
        //'px-12': !portraitView,
      })}
    >
      <div
        className={classNames(
          'flex h-full w-full items-center justify-center',
          {
            'flex-col-reverse justify-evenly': portraitView,
            'flex-row justify-center gap-[3.75rem]': !portraitView,
          }
        )}
      >
        {/* Title Subtitle */}
        <div
          className={classNames('flex flex-col', {
            'flex-[7]': !portraitView,
            'mt-5': portraitView,
          })}
        >
          <h2 className={`text-body-large mb-3`}>{title}</h2>
          <p className={`text-headline-medium sm:text-headline-large`}>
            {subtitle}
          </p>
        </div>
        {/* Image */}
        <div
          className={classNames('max-h-full min-h-0', {
            'flex h-full min-h-0 w-full min-w-0 flex-[5] items-center':
              !portraitView,
          })}
        >
          <img
            src={imageSrc}
            alt="Onboarding Image"
            className={classNames(
              'mx-auto my-auto max-h-full max-w-full justify-center object-contain',
              {
                'shadow-3xl': includeImageShadow,
                'rounded-3xl': includeImageShadow,
                'drop-shadow-3xl': includeDropShadow,
                'h-full w-full': portraitView,
              }
            )}
          />
        </div>
      </div>
    </div>
  )
}

const Page1 = () => {
  const { t } = useTranslation()
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <h2 className="text-body-large mb-3">
        {t('onboarding.welcome').toUpperCase()}
      </h2>
      <p className="sm:text-headline-large text-headline-medium max-w-[46.875rem] text-center">
        {t('onboarding.welcome_sub')}
      </p>
    </div>
  )
}

const Page2 = () => {
  const { t } = useTranslation()
  return (
    <OnboardingScreenWithImageTemplate
      title={t('onboarding.class_assignments')}
      subtitle={t('onboarding.class_assignments_sub')}
      imageSrc="/assets/images/onboarding/onboarding_02.png"
      includeImageShadow={false}
      includeDropShadow={true}
    />
  )
}

const Page3 = () => {
  const { t } = useTranslation()
  return (
    <OnboardingScreenWithImageTemplate
      title={t('onboarding.enrolling')}
      subtitle={t('onboarding.enrolling_sub')}
      imageSrc="/assets/images/onboarding/onboarding_03.png"
    />
  )
}

const Page4 = () => {
  const { t } = useTranslation()
  return (
    <OnboardingScreenWithImageTemplate
      title={t('onboarding.groups')}
      subtitle={t('onboarding.groups_sub')}
      imageSrc="/assets/images/onboarding/onboarding_04.png"
    />
  )
}

const Page5 = () => {
  const { t } = useTranslation()
  return (
    <OnboardingScreenWithImageTemplate
      title={t('onboarding.prework')}
      subtitle={t('onboarding.prework_sub')}
      imageSrc="/assets/images/onboarding/onboarding_05.png"
    />
  )
}

const Page6 = () => {
  const { t } = useTranslation()
  return (
    <OnboardingScreenWithImageTemplate
      title={t('onboarding.experience')}
      subtitle={t('onboarding.experience_sub')}
      imageSrc="/assets/images/onboarding/onboarding_06.png"
    />
  )
}

const Page7 = () => {
  return (
    <div className="h-full">
      <PodcastsComponent onboarding={true} />
    </div>
  )
}
