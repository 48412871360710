import type { AssignmentGroupData } from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import { RoomStateStatus } from '@breakoutlearning/firebase-repository/models/RoomState'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { RubricScoreWidget } from 'components/breakout/RubricScoreWidget'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTable } from 'components/design-system/BreakoutTable'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { DownloadIcon } from 'components/icons/Download'
import { EyeOpen } from 'components/icons/EyeOpen'
import { PeopleGroup } from 'components/icons/PeopleGroup'
import { Person } from 'components/icons/Person'
import { Search } from 'components/icons/Search'
import { Shield } from 'components/icons/Shield'
import { SparklesIcon } from 'components/icons/Sparkles'
import { TrashCanIcon } from 'components/icons/TrashCan'
import { useRepository } from 'hooks/auth'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { useDialogs } from 'hooks/dialogs'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { useSettings } from 'hooks/settings'
import { observer } from 'mobx-react-lite'
import { AssignmentRubricGroupDialog } from 'pages/instructor/dialogs/AssignmentRubricGroupDialog'
import React, { createRef, useCallback, useLayoutEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GroupRemoveUserDialog } from './dialogs/GroupRemoveUserDialog'
import { GroupAddUserDialog } from './dialogs/GroupAddUserDialog'
import { downloadAssignmentGroupDataAsCSV } from 'util/csv'
import { SummaryDialog } from 'pages/student/assignment/dialogs/SummaryDialog'
import { EmptyState } from 'components/breakout/EmptyState'
import { PeopleMultiple } from 'components/icons/PeopleMultiple'
import { BreakoutPill } from 'components/design-system/BreakoutPill'

export const InstructorAssignmentStudents = observer(function StudentsView() {
  const currentUser = useBreakoutUser()
  const rootStore = useRootStore()
  const cubit = useInstructorAssignmentCubit()
  const data = cubit.assignmentGroupDataSortedWithNotInGroup
  const input = useMemo(() => createRef<HTMLInputElement>(), [])
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  const tScoped = useCallback(
    (key: string, options?: Record<string, unknown>) =>
      t(`instructor_assignment.${key}`, options),
    [t]
  )

  useLayoutEffect(() => {
    // we need to focus only after the animation is done
    setTimeout(() => {
      input.current?.focus()
    }, 300)
  }, [input])

  // Groups with all empty groups merged into one.
  const groups = useMemo(() => {
    // Separate the groups into empty and non-empty groups
    const emptyGroups = data.filter((group) => group.roomState?.isEmpty)
    const nonEmptyGroups = data.filter((group) => group.roomState?.isNotEmpty)

    if (emptyGroups.length > 0) {
      // Start with the first empty group and append members from the rest
      const mergedEmptyGroup = {
        ...emptyGroups[0],
        groupMembers: emptyGroups.flatMap((group) => group.groupMembers),
      }
      return [...nonEmptyGroups, mergedEmptyGroup]
    }

    // If there are no empty groups, return only non-empty groups
    return nonEmptyGroups
  }, [data])

  if (!groups.length) {
    return (
      <EmptyState Icon={PeopleMultiple} text={tScoped('students_missing')} />
    )
  }

  return (
    <div className="overflow-auto rounded-2xl bg-surface p-5 md:h-full md:overflow-hidden">
      <div className="h-full overflow-auto rounded-2xl bg-core-tertiary">
        <BreakoutTable className="white h-auto">
          <thead>
            <tr>
              <th>{tScoped('group')}</th>
              <th>{tScoped('full_name')}</th>
              <th>{tScoped('quiz_results')}</th>
              <th>{tScoped('rubric_assessment')}</th>
              <th>
                <BreakoutTextInput
                  ref={input}
                  name="filter"
                  type="text"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      const name = e.currentTarget.value
                      cubit.addUserFilter(name)
                      e.currentTarget.value = ''
                    }
                  }}
                  LeadingIcon={Search}
                  inputClassName="text-body-medium font-medium placeholder-grey-text bg-surface"
                  kind="tertiary"
                  placeholder={t('instructor_assignment.filter_names')}
                />
              </th>
              <th>
                <BreakoutButton
                  kind="tertiary"
                  size="small"
                  onClick={() => {
                    downloadAssignmentGroupDataAsCSV(cubit.repository, data)
                  }}
                  icon={<DownloadIcon size={20} />}
                />
              </th>
            </tr>
          </thead>
          <tbody className="h-full">
            {cubit.userFilters.length > 0 && (
              <tr>
                <td colSpan={6}>
                  {cubit.userFilters.length > 0 && (
                    <BreakoutPill.Chips
                      labels={cubit.userFilters}
                      onClick={cubit.removeUserFilter}
                    />
                  )}
                </td>
              </tr>
            )}
            {groups?.map((groupData, index) => (
              <React.Fragment key={groupData.roomState?.id || `index-${index}`}>
                <tr>
                  <td
                    rowSpan={groupData.groupMembers.length + 1}
                    className={
                      'h-fit items-start border-r border-r-surface align-top'
                    }
                  >
                    {groupData.roomState?.isEmpty && (
                      <div>
                        <div className="text-title-small">
                          {t('instructor_assignment.not_assigned_to_group')}
                        </div>
                        <div className="text-body-small text-grey-text">
                          {t('instructor_assignment.student_count', {
                            count: groupData.groupMembers.length,
                          })}
                        </div>
                      </div>
                    )}
                    {groupData.roomState?.isNotEmpty && (
                      <div className="flex h-full flex-col items-start justify-center gap-2">
                        <div className="text-title-small">
                          {groupData.roomState?.data.roomStateName}
                        </div>
                        <div>
                          <RoomStateStatusBadge
                            status={groupData.roomStateStatus}
                          />
                        </div>
                        {groupData.roomStateStatus === RoomStateStatus.live &&
                          !groupData.summary?.id && (
                            <BreakoutButton
                              onClick={async () => {
                                if (!groupData.roomState) return
                                const result = await cubit.observeRoom(
                                  groupData.roomState
                                )
                                // if we can observe the room, navigate to the room
                                if (result) {
                                  rootStore.navigateTo('meeting', {
                                    roomId: groupData.roomState.id,
                                  })
                                }
                              }}
                              kind="secondary"
                              size="small"
                              icon={<EyeOpen size={14} />}
                            >
                              {t('instructor_assignment.monitor_session')} &gt;
                            </BreakoutButton>
                          )}
                        {groupData.summary?.id && (
                          <BreakoutButton
                            kind="secondary"
                            size="small"
                            icon={<SparklesIcon size={14} />}
                            onClick={() => {
                              if (!groupData.summary || !groupData.roomState) {
                                return
                              }
                              const summary = groupData.summary
                              const roomState = groupData.roomState
                              showDialog(() => (
                                <SummaryDialog
                                  summary={summary.data}
                                  roomStateName={roomState.roomStateName}
                                />
                              ))
                            }}
                          >
                            {t('instructor_assignment.show_summary')} &gt;
                          </BreakoutButton>
                        )}

                        {currentUser.isAdmin && groupData.roomState && (
                          <BreakoutButton
                            kind="secondary"
                            size="small"
                            onClick={() => {
                              if (!groupData.roomState) return
                              rootStore.navigateTo('assignment', {
                                assignmentId:
                                  groupData.roomState?.data.assignmentId,
                                sectionId: groupData.roomState?.data.sectionId,
                              })
                            }}
                            icon={<PeopleGroup size={14} />}
                          >
                            Student View &gt;
                          </BreakoutButton>
                        )}
                        {!groupData.summary?.id &&
                          groupData.roomStateStatus !==
                            RoomStateStatus.live && (
                            <BreakoutButton
                              kind="secondary"
                              size="small"
                              onClick={() => {
                                showDialog(({ remove }) => {
                                  return (
                                    <GroupAddUserDialog
                                      cubit={cubit}
                                      groupData={groupData}
                                      removeSelf={remove}
                                    />
                                  )
                                })
                              }}
                              icon={<Person size={14} />}
                            >
                              {t('instructor_assignment.add_member')} &gt;
                            </BreakoutButton>
                          )}
                      </div>
                    )}
                  </td>
                </tr>
                {groupData.groupMembers.map((memberId) => (
                  <UserRow
                    key={memberId}
                    groupData={groupData}
                    userId={memberId}
                  />
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </BreakoutTable>
      </div>
    </div>
  )
})

const UserRow = observer(function UserRow({
  groupData,
  userId,
}: {
  groupData: AssignmentGroupData
  userId: string
}) {
  const cubit = useInstructorAssignmentCubit()
  const repository = useRepository()
  const member = repository.userStore.getUser(userId)
  const rubrics = groupData.rubricResults.get(userId)
  const rubricEntries = Array.from(rubrics?.entries() || [])

  const { showDialog } = useDialogs()
  const showRubricDialog = useCallback(() => {
    showDialog(() => (
      <AssignmentRubricGroupDialog groupData={groupData} userId={userId} />
    ))
  }, [groupData, showDialog, userId])

  return (
    <tr key={userId}>
      <td>
        <div className="flex flex-row items-center gap-2">
          <div>
            <BreakoutUserAvatar user={member} radius={16} />
          </div>
          <div className="text-label-medium">{member.fullName}</div>
          {groupData.roomState?.data.groupLeaderUserIds.includes(userId) && (
            <Shield size={16} className="stroke-breakout-orange" />
          )}
        </div>
      </td>
      <td>
        {(((groupData.quizScore.get(userId) || 0) * 1000) / 10).toFixed(1)}%
      </td>
      <td colSpan={2}>
        <div
          className="flex cursor-pointer flex-row gap-1"
          onClick={showRubricDialog}
        >
          {rubricEntries.map(([rubric, results], index) => (
            <RubricScoreWidget
              key={rubric.id || `rubric-${index}`}
              rubric={rubric}
              rubricResult={results[0]}
            />
          ))}
        </div>
      </td>
      <td>
        {groupData.summary?.isEmpty && groupData.roomState?.isNotEmpty && (
          <div className="lg:max-w-3">
            <BreakoutButton
              size="small"
              kind="tertiary"
              onClick={() => {
                showDialog(({ remove }) => {
                  const roomState = groupData.roomState
                  if (!roomState) return

                  return (
                    <GroupRemoveUserDialog
                      cubit={cubit}
                      userId={userId}
                      roomState={roomState}
                      onClick={remove}
                    />
                  )
                })
              }}
              icon={<TrashCanIcon size={12} />}
            ></BreakoutButton>
          </div>
        )}
      </td>
    </tr>
  )
})

function RoomStateStatusBadge({ status }: { status: RoomStateStatus }) {
  const { t } = useTranslation()
  const { animationsEnabled } = useSettings()
  const animation = animationsEnabled ? 'pulse-animation' : ''
  let text = ''
  let icon = <></>
  switch (status) {
    case RoomStateStatus.live: {
      text = t('instructor_assignment.room_state_status_live')
      icon = (
        <span
          className={`${animation} mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-green`}
        ></span>
      )
      break
    }
    case RoomStateStatus.completed: {
      text = t('instructor_assignment.room_state_status_completed')
      icon = (
        <span
          className={`mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-green`}
        ></span>
      )
      break
    }
    case RoomStateStatus.abandoned: {
      text = t('instructor_assignment.room_state_status_abandoned')
      icon = (
        <span className="mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-red"></span>
      )
      break
    }
    case RoomStateStatus.scheduled: {
      text = t('instructor_assignment.room_state_status_scheduled')
      icon = (
        <span
          className={`mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-green`}
        ></span>
      )
      break
    }
    case RoomStateStatus.mustSchedule: {
      text = t('instructor_assignment.room_state_status_must_schedule')
      icon = (
        <span
          className={`mr-1 inline-block h-[8px] w-[8px] rounded-full bg-breakout-green`}
        ></span>
      )
      break
    }
    case RoomStateStatus.expired: {
      text = t('instructor_assignment.room_state_status_expired')
      icon = (
        <span
          className={`mr-1 inline-block h-[8px] w-[8px] rounded-full bg-dark-grey`}
        ></span>
      )
      break
    }
  }
  return (
    <span className="text-body-medium inline-block whitespace-nowrap rounded-xl py-3">
      {icon}
      {text}
    </span>
  )
}
