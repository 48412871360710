import { useDialogs } from 'hooks/dialogs'
import { XSmallIcon } from '../icons/XSmall'
import classnames from 'classnames'

export function DialogCloseButton({
  onClick,
  className,
}: {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => boolean
  className?: string
}) {
  const { popDialog } = useDialogs()

  return (
    <div
      className={classnames(
        'absolute right-4 top-4 flex justify-end',
        className
      )}
    >
      <button
        data-testid="dialog-close-button"
        className="p-1"
        onClick={(e) => {
          if (onClick) {
            const res = onClick(e)
            if (res) popDialog()
          } else {
            popDialog()
          }
        }}
      >
        <XSmallIcon size={22} />
      </button>
    </div>
  )
}
