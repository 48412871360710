import classNames from 'classnames'
import { useMemo } from 'react'

export function Spinner({
  size,
  thickness,
  className,
}: {
  size?: number
  thickness?: number
  className?: string
}) {
  const circleStyle = useMemo(() => {
    const thicknessOrDefault = 8 * (8 / (thickness || 8))
    let circleStyle
    if (size) {
      circleStyle = {
        width: `${size}rem`,
        height: `${size}rem`,
        borderWidth: `${size / thicknessOrDefault}rem`,
      }
    }
    return circleStyle
  }, [size, thickness])

  return (
    <span
      className={classNames('spinner', className)}
      style={circleStyle}
    ></span>
  )
}

export function FullPageSpinner() {
  return (
    <div className="fixed flex h-screen w-screen flex-col items-center justify-center">
      <Spinner />
    </div>
  )
}
