import classNames from 'classnames'
import { Toaster, resolveValue } from 'react-hot-toast'

export function Theme({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Toaster
        data-testid="toaster"
        containerClassName="!inset-0"
        gutter={0}
        position="bottom-center"
      >
        {(t) => {
          return (
            <div
              role="alert"
              className={classNames(
                'w-full',
                t.type === 'error'
                  ? 'bg-core-error text-core-on-error'
                  : 'bg-gray-700 text-white',
                t.visible ? 'animate-toast-enter' : 'animate-toast-leave'
              )}
            >
              <div className="p-4">{resolveValue(t.message, t)}</div>
            </div>
          )
        }}
      </Toaster>
      {children}
    </>
  )
}
