import type { SectionState } from '@breakoutlearning/firebase-repository/types'
import { BreakoutCard } from 'components/design-system/BreakoutCard'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { useTranslation } from 'react-i18next'

export function InstructorSectionCard({
  className,
  sectionName,
  userCount,
  caseCount,
  state,
  onClick,
  testId,
}: {
  className: string
  sectionName: string
  userCount?: number
  caseCount?: number
  state: SectionState
  onClick?: () => void
  testId?: string
}) {
  const { t } = useTranslation()
  return (
    <BreakoutCard data-testid={testId} onClick={onClick}>
      <BreakoutCard.Header>
        <BreakoutPill.SectionState kind="primary" state={state} />
      </BreakoutCard.Header>
      <BreakoutCard.Body>
        <div className="w-full">
          <h2 className="text-title-large line-clamp-2 text-on-surface">
            {className}
          </h2>
          <p className="text-body-medium line-clamp-1 text-on-surface-var">
            {sectionName}
          </p>
        </div>
      </BreakoutCard.Body>
      <BreakoutCard.Footer>
        <div className="grid w-full flex-none grid-cols-2 flex-row justify-between gap-5">
          <CountBox
            label={t('instructor_library.students')}
            count={userCount}
          />
          <CountBox
            label={t('instructor_library.experiences')}
            count={caseCount}
          />
        </div>
      </BreakoutCard.Footer>
    </BreakoutCard>
  )
}

function CountBox({
  count,
  label,
}: {
  count: number | undefined
  label: string
}) {
  return (
    <div className="flex flex-col text-center">
      <div className="text-title-large text-on-surface">
        {count === undefined ? '...' : count}
      </div>
      <div className="text-body-medium text-on-surface-var">{label}</div>
    </div>
  )
}
