import type { InstructorAssistantsCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorAssistantsCubit'
import { Spinner } from 'components/Spinner'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { LinkIcon } from 'components/icons/Link'
import { useDialogs } from 'hooks/dialogs'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { copyAsyncTextToClipboard } from 'util/clipboard'

export function AddAssistantDialog({
  cubit,
}: {
  cubit: InstructorAssistantsCubit
}) {
  const [invitationUrl, setInvitationUrl] = useState<string | null>(null)
  const { popDialog } = useDialogs()

  useEffect(() => {
    const generateInvitation = async () => {
      const { output: invitationUrl } = await copyAsyncTextToClipboard(
        async () => {
          const id = await cubit.createInvitation()

          return `${window.location.origin}/invitation/${id}`
        }
      )

      setTimeout(() => {
        // A cosmetic delay to show the spinner for a bit longer. Avoids flickering.
        setInvitationUrl(invitationUrl)
      }, 300)
    }

    generateInvitation()
  }, [cubit])

  return (
    <Dialog size="xs" key="dialog-show-url">
      <DialogCloseButton />

      <div className="px-8 pb-6 pt-3 ">
        <h3 className="text-center text-xl font-semibold">
          {t('instructor_assistants.link_copied_clipboard')}
        </h3>
        <p className="mt-4 text-center text-xs">
          {t('instructor_assistants.link_warning_assistants')}
        </p>
        {!invitationUrl && (
          <div className="mt-6 flex h-8 items-center justify-center">
            <Spinner size={2} />
          </div>
        )}
        {invitationUrl && (
          <div className="mt-6">
            <BreakoutTextInput
              data-testid="invitation-url"
              inputClassName="cursor-text"
              kind="secondary"
              LeadingIcon={({ ...props }) => (
                <LinkIcon
                  onClick={() => {
                    popDialog()
                  }}
                  {...props}
                />
              )}
              value={invitationUrl}
            />
          </div>
        )}
      </div>
    </Dialog>
  )
}
