import { t } from 'i18next'

export function EmptyListPlaceholder() {
  return (
    <div className="relative flex h-full items-center justify-center rounded-3xl bg-surface">
      <div className=" my-36 bg-surface text-center md:my-0 md:mb-36">
        <h3 className="font-bold">{t('student_library.empty_placeholder')}</h3>
        <p className="my-4 whitespace-pre-wrap px-10 text-xs">
          {t('student_library.empty_placeholder_description')}
        </p>
        <p className="text-label-medium">
          <a href="mailto:support@breakoutlearning.com">
            support@breakoutlearning.com
          </a>
        </p>
      </div>
      <div className="absolute bottom-0  hidden md:block">
        <img
          src="/assets/images/auth1.png"
          alt="placeholder"
          className="mt-5"
        />
      </div>
    </div>
  )
}
