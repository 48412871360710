import { useTranslation } from 'react-i18next'
import { BreakoutCard } from 'components/design-system/BreakoutCard'

export function InstructorLibraryCard({
  imageUrl,
  title,
  subtitle,
  isFree,
  price,
  onClick,
}: {
  imageUrl?: string
  title: string
  subtitle?: string
  isFree: boolean
  price: number
  onClick?: () => void
}) {
  const { t } = useTranslation()
  return (
    <BreakoutCard onClick={onClick}>
      <BreakoutCard.Body>
        {imageUrl && (
          <div className="flex h-[50px] w-[50px] flex-col items-center justify-center ">
            <img src={imageUrl} className="inline max-h-[30px] max-w-[30px]" />
          </div>
        )}
        <h2 className="text-body-medium line-clamp-1 text-on-surface-var">
          {title}
        </h2>
        {subtitle && (
          <h2 className="text-title-large line-clamp-2 text-on-surface">
            {subtitle}
          </h2>
        )}
      </BreakoutCard.Body>
      <BreakoutCard.Footer>
        <div>
          {isFree && (
            <span className="text-label-large text-on-surface">
              {t('instructor_library.price_student_free')}
            </span>
          )}
          {!isFree && (
            <>
              <div className="text-label-medium text-on-surface">
                {t('instructor_library.price_tokens', { count: price })}
              </div>
              <div className="text-body-small text-on-surface-var">
                {t('instructor_library.price_tokens_per_student')}
              </div>
            </>
          )}
        </div>
      </BreakoutCard.Footer>
    </BreakoutCard>
  )
}
