import type { FC } from 'react'
import classNames from 'classnames'

export const FormLabel: FC<{
  required?: boolean
  label?: string
  labelClass?: string
  name?: string
}> = ({ label, labelClass, name, required }) => {
  const classes = 'text-body-medium w-full px-4 text-on-surface-var '
  const asteriskClasses = 'text-red-500 pl-[2px]'

  if (!label) return null

  return (
    <label
      htmlFor={name}
      className={classNames(classes, labelClass)}
      data-testid={`${name}-field-label`}
    >
      {label}
      {required && <span className={asteriskClasses}>*</span>}
    </label>
  )
}
