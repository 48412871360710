import type {
  AssignmentGroupData,
  InstructorAssignmentCubit,
} from '@breakoutlearning/firebase-repository/cubits/InstructorAssignmentCubit'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { PlusIcon } from 'components/icons/Plus'
import { Search } from 'components/icons/Search'
import { useRepository } from 'hooks/auth'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GroupConfirmAddUserDialog } from './GroupConfirmAddUserDialog'
import { BreakoutPill } from 'components/design-system/BreakoutPill'

export const GroupAddUserDialog = observer(function GroupAddUserDialog({
  cubit,
  groupData,
  removeSelf,
}: {
  cubit: InstructorAssignmentCubit
  groupData: AssignmentGroupData
  removeSelf?: () => void
}) {
  // const thisGroupUsers = groupData.groupMembers
  const { showDialog } = useDialogs()
  const repository = useRepository()
  const [chips, setChips] = useState<string[]>([])
  const allGroupData = cubit.assignmentGroupDataSortedWithNotInGroup
  const allUserIds = allGroupData.map((group) => group.groupMembers).flat()

  const userIdToGroupNameMap = allGroupData.reduce(
    (acc, group) => {
      group.groupMembers.forEach((userId) => {
        acc[userId] = group.groupName
      })
      return acc
    },
    {} as Record<string, string | undefined>
  )

  // remove users that are already in the group
  const outsideGroupUserIds = allUserIds.filter(
    (userId) => !groupData.groupMembers.includes(userId)
  )

  const users = outsideGroupUserIds.map((userId) => {
    return repository.userStore.getUser(userId)
  })

  const filteredUsers = users.filter((user) => {
    return chips.every((chip) =>
      user.fullName.toLowerCase().includes(chip.toLowerCase())
    )
  })

  const addChip = useCallback((chip: string) => {
    setChips((prev) => [...prev, chip])
  }, [])

  const removeChip = useCallback((chip: string) => {
    setChips((prev) => prev.filter((c) => c !== chip))
  }, [])

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const formData = new FormData(e.currentTarget)
      const name = formData.get('filter') as string
      addChip(name)
      e.currentTarget.reset()
    },
    [addChip]
  )

  const { t } = useTranslation()
  return (
    <Dialog size="xs" className="!bg-core-tertiary">
      <DialogCloseButton />
      <div className="text-headline-medium mb-5 text-center">
        {t('instructor.group_user_add_student_to_group')}
      </div>
      <form onSubmit={handleSubmit}>
        <BreakoutTextInput
          autoFocus
          name="filter"
          LeadingIcon={Search}
          inputClassName="text-body-medium border font-medium placeholder-grey-text"
          kind="secondary"
          placeholder={t('instructor.group_user_add_search_by_name')}
        />
      </form>
      {chips.length > 0 && (
        <div className="mt-5">
          <BreakoutPill.Chips labels={chips} onClick={removeChip} />
        </div>
      )}
      <div className="mt-5">
        {filteredUsers.map((user) => (
          <div
            key={user.id}
            onClick={() => {
              if (!groupData.roomState) return
              showDialog(({ remove }) => (
                <GroupConfirmAddUserDialog
                  cubit={cubit}
                  roomState={groupData.roomState!}
                  userId={user.id}
                  onClick={remove}
                />
              ))

              removeSelf?.()
            }}
            className="my-2 flex cursor-pointer flex-row items-center justify-between gap-0 rounded-full p-1 px-2 hover:bg-neutral-100"
          >
            <div className="flex w-full gap-2">
              <BreakoutUserAvatar user={user} radius={18} />
              <div>
                <div className="text-label-medium">{user.fullName}</div>
                {userIdToGroupNameMap[user.id] && (
                  <div className="text-body-medium text-grey-text">
                    {userIdToGroupNameMap[user.id]}
                  </div>
                )}
                {!userIdToGroupNameMap[user.id] && (
                  <div className="text-body-medium text-grey-text">
                    {t('instructor.group_user_not_in_group')}
                  </div>
                )}
              </div>
            </div>
            <div className="pr-2">
              <PlusIcon size={18} className="text-grey-text" />
            </div>
          </div>
        ))}
      </div>
    </Dialog>
  )
})
