import { Dialog, InlineDialog } from 'components/dialogs/Dialog'
import { GoogleStorageImage } from 'components/GoogleStorageImage'

export const ImagePreviewDialog = ({
  src,
  inline = false,
  onDismiss,
}: {
  src: string
  inline?: boolean
  onDismiss?: () => void
}) => {
  const DialogToUse = inline ? InlineDialog : Dialog
  return (
    <DialogToUse size="lg" onDismiss={onDismiss} noWrap={true}>
      <GoogleStorageImage
        key={src}
        className="max-h-[85%] max-w-[85%] object-scale-down"
        namespace="preview-dialog"
        src={src}
      />
    </DialogToUse>
  )
}
