import type { SlideQuestion } from '@breakoutlearning/firebase-repository/models/SlideQuestion'
import type { RoomStateAnswer } from '@breakoutlearning/firebase-repository/models/RoomStateAnswer'
import { Button } from 'components/Button'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { CheckIcon } from 'components/icons/Check'
import { XSmallIcon } from 'components/icons/XSmall'
import { Dialog } from 'components/dialogs/Dialog'
import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { LazyMarkdown } from 'components/LazyMarkdown'
import { SlideQuestionType } from '@breakoutlearning/firebase-repository/models/SlideQuestionType'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'

export const QuizResultsDialog = observer(function QuizResultsDialog({
  removeSelf,
  cubit,
}: {
  removeSelf: VoidFunction
  cubit: StudentAssignmentCubit
}) {
  const { t } = useTranslation()

  const preWorkQuestionsMap: Record<string, SlideQuestion> =
    cubit.preWorkQuestions.reduce(
      (map, item) => {
        map[item.id] = item
        return map
      },
      {} as Record<string, SlideQuestion>
    )

  // todo: zod & strict typing
  const preWorkAnswers = cubit.answers.models

  const answersWithQuestions: {
    answer: RoomStateAnswer
    question: SlideQuestion
  }[] = []

  // join answers with questions via questionId
  for (const answer of preWorkAnswers) {
    const qid = answer.data.slideQuestionId
    const question = preWorkQuestionsMap[qid]
    if (!question) continue
    answersWithQuestions.push({ answer: answer, question: question })
  }

  return (
    <Dialog testId="quiz-results-dialog" size="md">
      <DialogCloseButton />
      <div className="relative flex flex-col">
        <div className="flex flex-row items-center justify-between">
          <h1 className="text-headline-large">
            {t('student_assignment.quiz_results')}
          </h1>
          <h1 className="text-display-medium">{cubit.preWorkQuizResult}%</h1>
        </div>
        <div className="flex flex-grow flex-col overflow-y-auto">
          {answersWithQuestions
            .sort((a, b) => {
              return a.question.data.question.localeCompare(
                b.question.data.question,
                undefined,
                {
                  numeric: true,
                }
              )
            })
            .map((ansWithQuestion, index) => (
              <QuizResultListItem
                key={index}
                index={index}
                question={ansWithQuestion.question}
                answer={ansWithQuestion.answer}
              />
            ))}
        </div>
        {/* idk why this button needs to be in a div it shrinks otherwise*/}
        <div className="h-[3.25rem]">
          <Button
            testId="quiz-results-dialog-exit-portal-button"
            className="mt-[1.25rem] w-full rounded-2xl bg-on-primary text-secondary"
            onClick={removeSelf}
          >
            {t('student_assignment.exit_portal')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
})

const QuizResultListItem = ({
  question,
  answer: answerDoc,
}: {
  index: number
  question: SlideQuestion
  answer: RoomStateAnswer
}) => {
  const answer = answerDoc.data

  const answerStr =
    answer.answerList && answer.answerList.length > 0
      ? answer.answerList.map((a) => question.data.answers[a]).join(', ')
      : question.data.answers[answer.answer]
  const correctAnswer =
    question.data.questionType === SlideQuestionType.sorting
      ? question.data.answers.join(', ')
      : question.data.answers[question.data.correct ?? -1]
  const isGroupQuestion = Boolean(question.data.groupSlideId)
  const { t } = useTranslation()

  const isCorrect = (() => {
    if (question.data.questionType === SlideQuestionType.multipleChoice) {
      return answer.answer === question.data.correct
    }
    const answerList = answer.answerList || []
    if (answerList.length !== question.data.answers.length) return false
    return answerList.every((ans, index) => ans === index)
  })()

  return (
    <div className="border-b-1 border-gray-400 bg-white px-4 py-3 pb-3">
      <div className="flex flex-row items-center">
        {/* icon size isnt consistent unless wrapped in div. not sure why*/}
        <div>
          {isCorrect ? (
            <CheckIcon size={16} color="green" />
          ) : (
            <XSmallIcon size={16} color="red" />
          )}
        </div>
        <p className="text-label-medium ml-10 flex-grow">
          {question.data.question}
        </p>
      </div>
      <div className="markdown-quiz-results ml-[3.5rem] mt-3">
        <LazyMarkdown>{answerStr}</LazyMarkdown>
      </div>
      {!isCorrect && !isGroupQuestion && (
        <>
          <div className="ml-[3.5rem] mt-3 flex flex-row">
            <CheckIcon size={16} color="green" />
            <p className="text-label-medium ml-1">
              {t('student_assignment.correct_answer')}
            </p>
          </div>
          <div className="markdown-quiz-results ml-[3.5rem] mt-3">
            <LazyMarkdown>{correctAnswer}</LazyMarkdown>
          </div>
        </>
      )}
    </div>
  )
}
