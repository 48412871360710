import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutSwitch } from 'components/design-system/BreakoutSwitch'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Environment, environment } from 'config/environment'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { useSettings } from 'hooks/settings'
import { setLanguage as updateLanguageInLocalStorage } from 'i18n/core'
import { autorun } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useDarkModeSettings } from 'util/darkMode'

const languageOptions = [
  { value: 'en', label: 'English' },
  { value: 'pl', label: 'Polski' },
]

export const ProfileInfoSection = observer(function ProfileInfoSection() {
  const { t, i18n } = useTranslation()
  const { animationsEnabled, updateAnimationsEnabled } = useSettings()
  const breakoutUser = useBreakoutUser()
  const { darkMode, setDarkMode } = useDarkModeSettings()
  const rootStore = useRootStore()
  const [language, setLanguage] = useState(i18n.language)
  const [formValid, setFormValid] = useState<boolean>(false)
  const [formData, setFormData] = useState<{
    firstName: string
    lastName: string
    email: string
  } | null>(null)

  useEffect(() => {
    if (breakoutUser.isLoading || formData !== null) return
    setFormData({
      firstName: breakoutUser.firstName,
      lastName: breakoutUser.lastName,
      email: breakoutUser.emailAddress,
    })
  }, [breakoutUser.isLoading, breakoutUser, formData])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (formData && name in formData) {
      const key = name as keyof typeof formData
      let newData
      setFormData((prevData) => {
        newData = prevData || {
          firstName: '',
          lastName: '',
          email: '',
        }
        newData[key] = value
        setFormValid(Object.values(newData).every((v) => v !== ''))
        return newData
      })
    }
  }

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const formData = new FormData(e.currentTarget)

      await breakoutUser.updateName(
        formData.get('firstName') as string,
        formData.get('lastName') as string
      )

      toast.success(t('profile.profile_updated'))
    },
    [breakoutUser, t]
  )

  useEffect(() => {
    const dispose = autorun(() => {
      if (!breakoutUser.hasData) return

      const firstName = breakoutUser.firstName
      const lastName = breakoutUser.lastName

      const firstNameInput: HTMLInputElement | null = document.querySelector(
        'input[name="firstName"]'
      )

      if (firstNameInput && firstName) {
        firstNameInput.value = firstName
      }

      const lastNameInput: HTMLInputElement | null = document.querySelector(
        'input[name="lastName"]'
      )

      if (lastNameInput && lastName) {
        lastNameInput.value = lastName
      }
    })

    return () => {
      dispose()
    }
  }, [breakoutUser])

  const changeAnimation = useCallback(() => {
    updateAnimationsEnabled(!animationsEnabled)
  }, [animationsEnabled, updateAnimationsEnabled])

  const changeLanguage = useCallback((newLanguage: string) => {
    setLanguage(newLanguage)
    updateLanguageInLocalStorage(newLanguage)
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <h2 className="text-headline-small mb-2.5 text-on-primary antialiased">
          {t('profile.details_headline')}
        </h2>
        {breakoutUser.isLoading && <div>{t('dart.loading')}</div>}
        {breakoutUser.hasData && (
          <>
            <div className="flex flex-col gap-3">
              <BreakoutTextInput
                kind="tertiary"
                label={t('profile.first_name')}
                name="firstName"
                onChange={handleChange}
                data-testid="firstNameInput"
                defaultValue={breakoutUser.firstName}
              />
              <BreakoutTextInput
                kind="tertiary"
                label={t('profile.last_name')}
                name="lastName"
                onChange={handleChange}
                defaultValue={breakoutUser.lastName}
              />
              <BreakoutTextInput
                kind="secondary"
                label={t('profile.email_address')}
                name="email"
                onChange={handleChange}
                disabled
                defaultValue={breakoutUser.emailAddress}
              />
              <BreakoutButton
                data-testid="save-profile"
                type="submit"
                size="large"
                fullWidth
                kind="tertiary"
                className="mt-3"
                disabled={!formValid}
              >
                {t('profile.save_profile')}
              </BreakoutButton>

              {environment() === Environment.development && (
                <BreakoutSwitch
                  label="Dark Mode"
                  className="my-2 ml-1 mt-3"
                  onChange={(e) => setDarkMode(e.target.checked)}
                  initialValue={darkMode}
                />
              )}

              <BreakoutSwitch
                testId="animations-toggle"
                label="Animations Enabled"
                className="my-2 ml-1"
                onChange={() => changeAnimation()}
                initialValue={animationsEnabled}
              />

              {breakoutUser.isAdmin && (
                <>
                  <h2 className="text-headline-small  mt-1 text-on-primary antialiased">
                    {t('profile.select_language')}
                  </h2>

                  <BreakoutSelect<string>
                    testId="language-select"
                    kind="tertiary"
                    options={languageOptions}
                    onChange={changeLanguage}
                    value={language}
                  />
                </>
              )}

              <h2 className="text-headline-small  text-on-primary antialiased">
                {t('profile.other_resources')}
              </h2>

              <BreakoutButton
                size="large"
                fullWidth
                kind="tertiary"
                onClick={() => rootStore.navigateTo('avTest')}
              >
                {t('profile.test_av')}
              </BreakoutButton>
              <a
                href="https://www.breakoutlearning.com/legal/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                <BreakoutButton size="large" fullWidth kind="tertiary">
                  {t('profile.privacy_policy')}
                </BreakoutButton>
              </a>
            </div>
          </>
        )}
      </div>
    </form>
  )
})
