import { ChevronLeft } from './icons/ChevronLeft'

export function Breadcrumb({
  children,
  className,
  onClick,
}: {
  children: React.ReactNode
  className?: string
  onClick?: () => void
}) {
  return (
    <div
      className={
        className || '' + ' flex cursor-pointer  flex-row items-center'
      }
      onClick={onClick}
    >
      <ChevronLeft className="inline-block" />
      <span className="text-headline-large text-on-primary md:text-4xl">
        {children}
      </span>
    </div>
  )
}
