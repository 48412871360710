import { useRootStore } from 'hooks/rootStore'
import { reaction } from 'mobx'
import { useEffect } from 'react'
import { useBreakoutUser } from 'hooks/profile'

export const AuthedRedirectHandler = () => {
  const store = useRootStore()
  const user = useBreakoutUser()

  const redirectExceptions = ['avTest', 'onboarding', 'logout', 'meeting']

  useEffect(() => {
    return reaction(
      () => ({
        shouldShowOnboarding: user.shouldShowOnboarding,
        userIsLoading: user.isLoading,
        currentRoute: store.router.currentRoute,
      }),
      ({ shouldShowOnboarding, userIsLoading }, prev, reaction) => {
        if (redirectExceptions.includes(store.router.currentRoute?.title || ''))
          return
        const route = 'onboarding'
        // if we are leaving onboarding, do nothing
        if (prev.currentRoute?.title === route) return
        const isOnboarding = store.router.currentRoute?.title === route
        if (shouldShowOnboarding && !isOnboarding) {
          store.navigateTo('onboarding')
        }
        if (!shouldShowOnboarding && !userIsLoading) {
          // dispose self onboarding complete
          reaction.dispose()
        }
      }
    )
  })

  useEffect(() => {
    return reaction(
      () => store.router.currentRoute,
      (route, _, reaction) => {
        if (redirectExceptions.includes(route?.title || '')) return
        if (!localStorage.getItem('lk-user-choices')) {
          return store.navigateTo('avTest')
        }
        // dispose of self if lk user choices is set
        reaction.dispose()
      },
      { fireImmediately: true }
    )
  })

  useEffect(() => {
    return reaction(
      () => user.role,
      (role, prevRole) => {
        if (prevRole && role !== prevRole) store.navigateTo('home')
      },
      { fireImmediately: true }
    )
  })

  return null
}
