import classNames from 'classnames'
import { theme } from 'config/designSystem'
import { useSettings } from 'hooks/settings'
import type { ChangeEvent, ComponentProps } from 'react'
import React, { forwardRef, useEffect, useId, useRef } from 'react'
import { RippleEffect } from './RippleEffect'

// const rootClasses = 'inline-flex items-center'

// const containerClasses =
//   'relative inline-block w-8 h-4 cursor-pointer rounded-full'

// const inputClasses =
//   'peer appearance-none w-8 h-4 absolute bg-blue-gray-100 rounded-full cursor-pointer transition-colors duration-300 checked:bg-gray-900 peer-checked:border-gray-900 peer-checked:before:bg-gray-900'

// const circleClasses =
//   "bg-white w-5 h-5 border border-blue-gray-100 rounded-full shadow-md absolute top-2/4 -left-1 -translate-y-2/4 peer-checked:translate-x-full transition-all duration-300 cursor-pointer before:content[''] before:block before:bg-blue-gray-500 before:w-10 before:h-10 before:rounded-full before:absolute before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 before:transition-opacity before:opacity-0 hover:before:opacity-10 peer-checked:border-gray-900 peer-checked:before:bg-gray-900"

// const circleDisabledClasses =
//   "bg-white w-5 h-5 border border-blue-gray-100 rounded-full shadow-md absolute top-2/4 -left-1 -translate-y-2/4 peer-checked:translate-x-full transition-all duration-300 cursor-pointer before:content[''] before:block before:bg-blue-gray-500 before:w-10 before:h-10 before:rounded-full before:absolute before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 before:transition-opacity before:opacity-0 peer-checked:border-gray-900 peer-checked:before:bg-gray-900"

// const rippleClasses =
//   'inline-block top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 p-5 rounded-full'

// const labelClasses = 'text-body-medium px-4 text-on-surface-var cursor-pointer'

interface SwitchProps extends ComponentProps<'input'> {
  label?: string
  ripple?: boolean
  className?: string
  disabled?: boolean
  initialValue?: boolean
  testId?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  containerProps?: React.HTMLAttributes<HTMLDivElement>
  labelProps?: React.HTMLAttributes<HTMLLabelElement>
  circleProps?: React.HTMLAttributes<HTMLLabelElement>
  inputRef?: React.Ref<HTMLInputElement>
}

export const BreakoutSwitch = forwardRef<HTMLInputElement, SwitchProps>(
  function BreakoutSwitch(
    {
      label,
      ripple,
      disabled,
      initialValue,
      onChange,
      testId,
      className,
      containerProps,
      circleProps,
      labelProps,
      ...rest
    },
    ref
  ) {
    const { animationsEnabled } = useSettings()
    // 1. init
    const switchId = useId()
    const internalInputRef = useRef<HTMLInputElement | null>(null)

    // 2. set default props
    ripple = !animationsEnabled ? false : ripple ?? true
    disabled = disabled ?? false

    // 3. set ripple effect instance
    const rippleEffect = ripple !== false && new RippleEffect()

    // 4. resolve classes
    const themeClasses = theme.switch
    const rootClasses = classNames(
      themeClasses.root.base,
      className,
      disabled ? themeClasses.root.disabled : themeClasses.root.enabled
    )
    const containerClasses = classNames(
      themeClasses.container.base,
      disabled
        ? themeClasses.container.disabled
        : themeClasses.container.enabled
    )
    const inputClasses = classNames(
      themeClasses.input.base,
      disabled ? themeClasses.input.disabled : themeClasses.input.enabled
    )
    const circleClasses = classNames(
      themeClasses.circle.base,
      disabled ? themeClasses.circle.disabled : themeClasses.circle.enabled
    )
    const rippleClasses = classNames(
      themeClasses.ripple.base,
      disabled ? themeClasses.circle.disabled : themeClasses.circle.enabled
    )
    const labelClasses = classNames(
      themeClasses.label.base,
      disabled ? themeClasses.label.disabled : themeClasses.label.enabled
    )

    useEffect(() => {
      if (internalInputRef.current) {
        if (initialValue !== undefined) {
          internalInputRef.current.checked = initialValue
        }
      }
    }, [internalInputRef, initialValue])

    // 4. return
    return (
      <div ref={ref} className={classNames(rootClasses, className)}>
        <div {...containerProps} className={containerClasses}>
          <input
            {...rest}
            ref={(ref) => {
              internalInputRef.current = ref
            }}
            type="checkbox"
            disabled={disabled}
            id={rest.id || switchId}
            className={classNames(inputClasses)}
            onChange={onChange}
          />
          <label
            data-testid={testId ? testId : undefined}
            {...circleProps}
            htmlFor={rest.id || switchId}
            className={circleClasses}
          >
            {!disabled && rippleEffect && (
              <div
                className={rippleClasses}
                onMouseDown={(e) => {
                  // setValue(!value)
                  // if (internalInputRef.current) {
                  //   internalInputRef.current.checked = !value
                  // }
                  // onChange && onChange(e)
                  // const onMouseDown = containerProps?.onMouseDown
                  if (ripple) {
                    rippleEffect.createFromEvent(e, 'dark')
                  }
                  // return typeof onMouseDown === 'function' && onMouseDown(e)
                }}
              />
            )}
          </label>
        </div>
        {label && (
          <label
            {...labelProps}
            htmlFor={rest.id || switchId}
            className={labelClasses}
          >
            {label}
          </label>
        )}
      </div>
    )
  }
)
