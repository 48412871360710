import { SlideQuestionType } from '@breakoutlearning/firebase-repository/models/SlideQuestionType'
import { Spinner } from 'components/Spinner'
import { EmptyState } from 'components/breakout/EmptyState'
import { GraphBarIcon } from 'components/icons/GraphBar'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SimplePoll } from '../poll_components/SimplePoll'
import { CustomPoll } from '../poll_components/CustomPoll'

export const InstructorAssignmentResultsPolls = observer(
  function InstructorAssignmentResultsRubric() {
    const cubit = useInstructorAssignmentCubit()
    const { t } = useTranslation()

    const [hasPolls, hasAnswers, pollEntries] = useMemo(() => {
      return [
        cubit.polls.size > 0,
        Array.from(cubit.polls.values()).some((answers) => answers.length),
        Array.from(cubit.polls.entries()),
      ]
    }, [cubit.polls])

    if (cubit.questionsAnswerDataIsLoading) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )
    }

    if (!hasPolls || !hasAnswers) {
      return (
        <EmptyState
          Icon={GraphBarIcon}
          text={t('instructor_assignment.polls_missing')}
        />
      )
    }

    return (
      <div className="flex h-full w-full flex-col">
        {pollEntries.map(([question, answers], index) => {
          if (question.data.questionType === SlideQuestionType.poll)
            return (
              <SimplePoll
                key={question.id}
                answers={answers}
                index={index}
                question={question}
              />
            )
          if (question.data.questionType === SlideQuestionType.customPoll)
            return (
              <CustomPoll
                key={question.id}
                answers={answers}
                index={index}
                question={question}
              />
            )
          return null
        })}
      </div>
    )
  }
)
