import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { ProfileInfoSection } from './ProfileInfoSection'
import { ProfileTokensSection } from './ProfileTokensSection'
import { Contents, MainPane, Header, Headline } from 'pages/layout/TwoColumn'
import { useBreakoutUser } from 'hooks/profile'
import { getShortAppCommitSha } from 'util/commitSha'

export const ProfilePage = observer(function ProfilePage() {
  const user = useBreakoutUser()
  const { t } = useTranslation()

  return (
    <MainPane>
      <Header>
        <Headline>{t('profile.headline')}</Headline>
        {user.isAdmin && <div>{getShortAppCommitSha()}</div>}
      </Header>
      <Contents className="overflow-auto">
        <div className="flex min-h-full w-full flex-col semi:flex-row ">
          <div
            className={`
              mb-5 mr-5 w-full
              flex-shrink-0 flex-grow-0
              rounded-3xl
              bg-secondary
              p-5
              semi:mb-0
              semi:w-[38%] semi:min-w-[350px] semi:max-w-[500px] semi:p-10 semi:pl-9
              `}
          >
            <ProfileInfoSection />
          </div>
          <div className="w-full rounded-2xl bg-secondary p-5 md:p-10">
            <ProfileTokensSection />
          </div>
        </div>
      </Contents>
    </MainPane>
  )
})
