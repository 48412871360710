// assets/images/onboarding/onboarding_podcast.png

import { Button } from 'components/Button'
import { useRootStore } from 'hooks/rootStore'

// import { useUserData } from '../hooks/userData'
// import { useProfile } from 'hooks/profile'
// import { useCallback, useEffect, useState } from 'react'
// import { autorun } from 'mobx'
// import { useTokens } from 'hooks/tokens'
// import config from 'config/config'

export const NotFoundPage = function ProfilePage() {
  const rootStore = useRootStore()
  return (
    <div className="box-border flex h-full w-full flex-grow flex-col items-center justify-center rounded-3xl bg-primary p-8 px-[2.5rem] align-middle text-on-primary">
      <div className="mb-5">Page Not Found</div>
      <div>
        <Button
          onClick={() => rootStore.navigateTo('home')}
          className="bg-black text-white"
        >
          Back to Home
        </Button>
      </div>
    </div>
  )
}
