import classNames from 'classnames'
import { LockOn } from 'components/icons/LockOn'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { useDialogs } from 'hooks/dialogs'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { MaterialsModal } from './MaterialsModal'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'

export const StudentAssignmentInfoLoading = () => {
  return (
    <div className="mt-5 w-full flex-grow rounded-3xl bg-primary py-10 lg:mr-5 lg:mt-0 lg:h-full lg:max-w-[35%]">
      <div className="h-full max-h-full w-full">
        <div className="flex h-full flex-col items-center justify-center overflow-auto">
          Loading...
        </div>
      </div>
    </div>
  )
}

function InfoBox({ children }: { children: React.ReactNode }) {
  return (
    <div>
      <div className="text-body-medium mb-2 flex h-full flex-col items-center justify-center rounded-lg bg-secondary p-4 text-center md:mb-0">
        {children}
      </div>
    </div>
  )
}

export const StudentAssignmentInfo = observer(function StudentAssignmentInfo() {
  const cubit = useStudentAssignmentCubit()
  const libraryObject = cubit.libraryObject
  const { showDialog } = useDialogs()
  const { t } = useTranslation()
  const section = libraryObject.section
  const assignment = libraryObject.assignment
  const slideDeckData = libraryObject.slideDeck.data
  return (
    <div className="mt-5 flex-grow rounded-3xl bg-primary md:h-full lg:mr-5 lg:mt-0 lg:max-w-[35%]">
      <div className="max-h-full md:h-full">
        <div className="items-left flex flex-col justify-between md:h-full md:overflow-auto">
          <div className="top mt-8 flex-none px-8">
            <div className="ml-5">
              {slideDeckData.slideDeckImageURL && (
                <img
                  src={slideDeckData.slideDeckImageURL}
                  alt={slideDeckData.slideDeckName}
                  width="47"
                  height="47"
                />
              )}
            </div>
            <div className="pl-1">
              <div className="text-title-large mt-9">
                {slideDeckData.slideDeckTeaser}
              </div>
              <div className="mt-2 text-xs text-grey-text">Description</div>
            </div>
          </div>
          <div className="description isolate mb-2 mr-6 mt-2 h-[200px] flex-grow overflow-y-auto px-9 pr-5 text-xs leading-5 text-on-primary md:h-auto md:min-h-32">
            {slideDeckData.slideDeckDescription}
          </div>
          <div className="bottom flex-grow-0 px-8 pb-8">
            <div className="mb-2 grid grid-cols-3 grid-rows-1 gap-2 justify-self-stretch">
              <InfoBox>
                <h3 className="text-grey-text">Instructor</h3>
                <BreakoutUserAvatar radius={24} user={section.instructor} />
                <span className="text-label-medium break-words text-on-primary">
                  {section.instructor.fullName}
                </span>
              </InfoBox>
              <InfoBox>
                <h3 className="text-grey-text">Class</h3>
                <span className="text-label-medium my-3 break-words text-on-primary">
                  {section.data.className}
                </span>
                <span className="text-label-medium break-words text-on-primary">
                  {section.data.sectionName}
                </span>
              </InfoBox>
              <InfoBox>
                <h3 className="text-grey-text">Deadline</h3>
                <span
                  className={`text-label-medium ${assignment.isExpired ? 'text-breakout-red' : 'text-on-primary'} my-3`}
                >
                  {assignment.expiresAtDate &&
                    assignment.expiresAtDate.toFormat('LLL d, yyyy')}
                  {!assignment.expiresAtDate && 'No deadline'}
                </span>
                <span
                  className={`text-label-medium ${assignment.isExpired ? 'text-breakout-red' : 'text-on-primary'}`}
                >
                  {assignment.expiresAtDate &&
                    assignment.expiresAtDate.toFormat('t')}
                </span>
              </InfoBox>
            </div>

            <div className="w-full flex-grow rounded-lg bg-surface md:mt-2">
              <div
                onClick={() => {
                  if (libraryObject.requiresPayment) return
                  showDialog(() => (
                    <div>{<MaterialsModal cubit={cubit} />}</div>
                  ))
                }}
                className={classNames(
                  'flex h-full flex-col items-center justify-center p-4',
                  {
                    'cursor-pointer': !libraryObject.requiresPayment,
                  }
                )}
              >
                {libraryObject.requiresPayment && (
                  <LockOn size={20} className="mt-4 stroke-grey-text" />
                )}
                <h3
                  className={classNames('text-xs text-grey-text', {
                    'mt-4': !libraryObject.requiresPayment,
                    'mb-4': libraryObject.requiresPayment,
                  })}
                >
                  {t('student_assignment.course_materials')}
                </h3>
                {!libraryObject.requiresPayment && (
                  <a className="text-label-medium mb-4 mt-3 cursor-pointer text-on-primary">
                    {t('student_assignment.view_details')} &gt;
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
