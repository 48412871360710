import { InvitationConsumeResult } from '@breakoutlearning/firebase-repository/types'
import { Spinner } from 'components/Spinner'
import { useRepository } from 'hooks/auth'
import { useDialogs } from 'hooks/dialogs'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { InvitationAcceptedDialog } from './InvitationAcceptedDialog'
import { InstructorSectionCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSectionCubit'

export function InvitationTokenPage() {
  const repository = useRepository()
  const rootStore = useRootStore()
  const router = rootStore.router
  const user = useBreakoutUser()
  const { showDialog } = useDialogs()

  useEffect(() => {
    const token = router.params?.token

    async function acceptInvitation() {
      if (!token || typeof token !== 'string') {
        rootStore.navigateTo('home')
        return
      }

      const response = await user.consumeInvitation(token)
      switch (response.result) {
        case InvitationConsumeResult.success:
          toast.success('Invitation accepted', { position: 'bottom-center' })
          break
        case InvitationConsumeResult.errorAlreadyConsumed:
          toast.error('Already accepted invitation')
          break
        case InvitationConsumeResult.errorCreatedByYou:
          toast.error('Invitation created by you')
          break
        case InvitationConsumeResult.errorInvitationExpired:
          toast.error('Invitation expired')
          break
        case InvitationConsumeResult.errorInvitationNotFound:
          toast.error('Invitation not found')
          break
      }

      rootStore.navigateToWithHook('home', {
        hook: async () => {
          if (
            response.result !== InvitationConsumeResult.success ||
            !response.invitation?.sectionId
          ) {
            return
          }
          const cubit = new InstructorSectionCubit(
            repository,
            response.invitation.sectionId
          )
          const section = cubit.section

          // wait for the doc to prevent spinners
          await cubit.section.ready()

          if (section) {
            showDialog(() => <InvitationAcceptedDialog section={section} />)
          }
        },
      })
    }

    void acceptInvitation()
  }, [user, rootStore, router, showDialog, repository])

  return (
    <div className="relative box-border flex h-full w-full items-center justify-center overflow-auto rounded-3xl bg-white">
      <Spinner />
    </div>
  )
}
