import { Spinner } from 'components/Spinner'
import { EmptyState } from 'components/breakout/EmptyState'
import { QuestionIcon } from 'components/icons/Question'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getAggregateQuizAnswerScore } from '@breakoutlearning/firebase-repository/util'
import type { RoomStateAnswer } from '@breakoutlearning/firebase-repository/models/RoomStateAnswer'
import type { SlideQuestion } from '@breakoutlearning/firebase-repository/models/SlideQuestion'
import type { RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import classNames from 'classnames'
import type { Section } from '@breakoutlearning/firebase-repository/models/Section'
import type { PublicUser } from '@breakoutlearning/firebase-repository/models/PublicUser'
import { SlideQuestionType } from '@breakoutlearning/firebase-repository/models/SlideQuestionType'
import { LinearProgressIndicator } from '../quiz_components/LinearProgressIndicator'
import { useDialogs } from 'hooks/dialogs'
import { AssignmentQuizMultipleChoiceDialog } from '../dialogs/AssignmentQuizMultipleChoiceDialog'
import { AssignmentQuizSortingDialog } from '../dialogs/AssignmentQuizSortingDialog'

export const InstructorAssignmentResultsQuiz = observer(
  function InstructorAssignmentResultsRubric() {
    const cubit = useInstructorAssignmentCubit()
    const { t } = useTranslation()

    const [hasQuestions, hasAnswers, quizEntries] = useMemo(() => {
      return [
        cubit.quizQuestions.size > 0,
        Array.from(cubit.quizQuestions.values()).some(
          (answers) => answers.length
        ),
        Array.from(cubit.quizQuestions.entries()),
      ]
    }, [cubit.quizQuestions])

    if (cubit.questionsAnswerDataIsLoading) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )
    }

    if (!hasQuestions || !hasAnswers) {
      return (
        <EmptyState
          Icon={QuestionIcon}
          text={t('instructor_assignment.quizzes_missing')}
        />
      )
    }

    return (
      <div className="flex h-full w-full flex-col">
        {quizEntries.map(([question, answers], index) => {
          return (
            <CombinedQuizQuestions
              key={question.id}
              answers={answers}
              index={index}
              question={question}
              roomStates={cubit.roomStates.models}
              section={cubit.section}
              usersMap={cubit.usersMap}
            />
          )
        })}
      </div>
    )
  }
)

const CombinedQuizQuestions = observer(function CombinedQuizQuestions({
  answers,
  index,
  question,
  roomStates,
  section,
  usersMap,
}: {
  answers: Array<RoomStateAnswer>
  index: number
  question: SlideQuestion
  roomStates: Array<RoomState>
  section: Section
  usersMap: Map<string, PublicUser>
}) {
  switch (question.questionType) {
    case SlideQuestionType.sorting:
      return (
        <CombinedSortingResults
          answers={answers}
          index={index}
          question={question}
          roomStates={roomStates}
          section={section}
          usersMap={usersMap}
        />
      )
    case SlideQuestionType.multipleChoice:
      return (
        <MultipleChoice answers={answers} index={index} question={question} />
      )
    default:
      return null
  }
})

const CombinedSortingResults = observer(function CombinedSortingResults({
  answers,
  index,
  question,
  roomStates,
  section,
  usersMap,
}: {
  answers: Array<RoomStateAnswer>
  index: number
  question: SlideQuestion
  roomStates: Array<RoomState>
  section: Section
  usersMap: Map<string, PublicUser>
}) {
  const { t } = useTranslation()
  const { showDialog } = useDialogs()
  const sortingComponents = useMemo(() => {
    const componentsToRender = []
    if (question.hasGroupSlideId && question.hasSlideId) {
      componentsToRender.push(
        <SortingSynergyResults
          answers={answers}
          question={question}
          roomStates={roomStates}
        />
      )
    }
    if (question.hasSlideId) {
      const individualAnswers = answers.filter((ans) =>
        roomStates.every((roomState) => roomState.id !== ans.data.userId)
      )
      componentsToRender.push(
        <SortingResults
          answers={individualAnswers}
          question={question}
          showLinearProgress={
            !(question.hasGroupSlideId && question.hasSlideId)
          }
          title={t('instructor_assignment.individual_average_score')}
        />
      )
    }
    if (question.hasGroupSlideId) {
      const groupAnswers = answers.filter((ans) =>
        roomStates.some((roomState) => roomState.id === ans.data.userId)
      )
      componentsToRender.push(
        <SortingResults
          answers={groupAnswers}
          question={question}
          showLinearProgress={
            !(question.hasGroupSlideId && question.hasSlideId)
          }
          title={t('instructor_assignment.group_average_score')}
        />
      )
    }
    return componentsToRender
  }, [answers, question, roomStates, t])

  return (
    <div
      className={classNames(
        'mt-3 flex cursor-pointer flex-row items-center bg-primary px-5 py-4',
        {
          'h-[176px]': question.hasGroupSlideId && question.hasSlideId,
          'h-[86px]': !(question.hasGroupSlideId && question.hasSlideId),
        }
      )}
      onClick={() => {
        showDialog(() => (
          <AssignmentQuizSortingDialog
            answers={answers}
            question={question}
            roomStates={roomStates}
            section={section}
            usersMap={usersMap}
          />
        ))
      }}
    >
      <span className="text-title-medium mr-3">{index + 1}</span>
      <span className="text-body-medium line-clamp-3 flex-grow">
        {question.questionWithoutNumbersAtBeginning}
      </span>
      <div className="flex w-[250px] max-w-[250px] flex-col">
        {sortingComponents}
      </div>
    </div>
  )
})

const SortingResults = observer(function SortingResults({
  answers,
  question,
  showLinearProgress,
  title,
}: {
  answers: Array<RoomStateAnswer>
  question: SlideQuestion
  showLinearProgress: boolean
  title: string
}) {
  const average = getAggregateQuizAnswerScore({ answers, question })
  const correctPercent =
    ((question.getWorstSortingScore - average) /
      question.getWorstSortingScore) *
    100
  return (
    <>
      <div className="mb-1.5 mr-1 flex flex-row gap-1">
        <span className="text-body-medium text-grey-text">{title}</span>
        <span className="text-title-small">{average.toFixed(0)}</span>
      </div>
      {showLinearProgress && (
        <>
          <LinearProgressIndicator
            percentage={correctPercent}
            className="mr-1"
          />
          <div className="text-body-medium flex flex-row text-grey-text">
            <span>{question.getWorstSortingScore}</span>
            <span className="ml-auto">0</span>
          </div>
        </>
      )}
    </>
  )
})

const SortingSynergyResults = observer(function SortingSynergyResults({
  answers,
  question,
  roomStates,
}: {
  answers: Array<RoomStateAnswer>
  question: SlideQuestion
  roomStates: Array<RoomState>
}) {
  const groupScores: Map<string, number> = new Map()
  const userScoresForGroup: Map<string, Array<number>> = new Map()
  for (const roomState of roomStates) {
    const groupAnswers = answers.filter(
      (answer) => answer.data.userId === roomState.id
    )
    const groupAverage = getAggregateQuizAnswerScore({
      answers: groupAnswers,
      question,
    })
    groupScores.set(roomState.id, groupAverage)

    for (const userId of roomState.data.userIds) {
      const userAnswers = answers.filter(
        (answer) =>
          answer.data.userId === userId && answer.data.roomId === roomState.id
      )
      if (!userAnswers.length) continue

      const userAverage = getAggregateQuizAnswerScore({
        answers: userAnswers,
        question,
      })
      if (!userScoresForGroup.has(roomState.id))
        userScoresForGroup.set(roomState.id, [])

      userScoresForGroup.get(roomState.id)!.push(userAverage)
    }
    userScoresForGroup.get(roomState.id)?.sort((a, b) => a - b)
  }

  let totalGroupsWhereGroupHasLowerScoreThanBestIndividual = groupScores.size
  for (const groupScore of groupScores.entries()) {
    const [groupScoreKey, groupScoreValue] = groupScore
    const userScores = userScoresForGroup.get(groupScoreKey)
    if (!userScores || !userScores.length) continue
    const firstUserScore = userScores[0]
    if (groupScoreValue > firstUserScore) {
      totalGroupsWhereGroupHasLowerScoreThanBestIndividual--
    }
  }

  const bestScore =
    totalGroupsWhereGroupHasLowerScoreThanBestIndividual / groupScores.size
  const bestColor = bestScore > 0.5 ? 'green' : 'red'

  let totalUsersWhereGroupHasLowerScoreThanAverageIndividual = groupScores.size
  for (const groupScore of groupScores.entries()) {
    const [groupScoreKey, groupScoreValue] = groupScore
    const userScores = userScoresForGroup.get(groupScoreKey)
    if (!userScores || !userScores.length) continue
    const averageUserScore =
      userScores.reduce((a, b) => a + b) / userScores.length
    if (groupScoreValue > averageUserScore) {
      totalUsersWhereGroupHasLowerScoreThanAverageIndividual--
    }
  }

  const averageScore =
    totalUsersWhereGroupHasLowerScoreThanAverageIndividual / groupScores.size
  const averageColor = averageScore > 0.5 ? 'green' : 'red'

  const { t } = useTranslation()
  const tScoped = useCallback(
    (key: string) => t(`instructor_assignment.${key}`),
    [t]
  )

  const bestPercent = bestScore * 100
  const averagePercent = averageScore * 100

  return (
    <>
      <span className="text-body-medium mb-1.5 text-grey-text">
        {tScoped('group_score_vs_best_individual_score')}
      </span>
      <div className="mb-1.5 flex w-[250px] flex-row items-center justify-between">
        <LinearProgressIndicator
          percentage={bestPercent}
          color={bestColor}
          className="w-[212px]"
        />
        <span className="text-title-small text-on-primary">
          {bestPercent.toFixed(0)}%
        </span>
      </div>
      <span className="text-body-medium mb-1.5 text-grey-text">
        {tScoped('group_score_vs_average_individual_score')}
      </span>
      <div className="mb-1.5 flex w-[250px] flex-row items-center justify-between">
        <LinearProgressIndicator
          percentage={averagePercent}
          color={averageColor}
          className="w-[212px]"
        />
        <span className="text-title-small text-on-primary">
          {averagePercent.toFixed(0)}%
        </span>
      </div>
    </>
  )
})

const MultipleChoice = observer(function MultipleChoice({
  answers,
  index,
  question,
}: {
  answers: RoomStateAnswer[]
  index: number
  question: SlideQuestion
}) {
  const { showDialog } = useDialogs()
  const average = getAggregateQuizAnswerScore({ answers, question })
  const correctPercent = average * 100
  const color = correctPercent <= 40 ? 'red' : 'green'

  return (
    <div
      className="mt-3 flex h-[75px] cursor-pointer flex-row items-center rounded-2xl bg-primary px-5"
      onClick={() => {
        showDialog(() => (
          <AssignmentQuizMultipleChoiceDialog
            answers={answers}
            question={question}
          />
        ))
      }}
    >
      <span className="text-title-medium mr-3">{index + 1}</span>
      <span className="text-body-medium line-clamp-3 flex-grow">
        {question.questionWithoutNumbersAtBeginning}
      </span>
      <div className="flex w-[250px] flex-row items-center justify-between">
        <LinearProgressIndicator
          percentage={correctPercent}
          color={color}
          className="w-[212px]"
        />
        <span className="text-title-small text-on-primary">
          {correctPercent.toFixed(0)}%
        </span>
      </div>
    </div>
  )
})
