import type { QueryParams, Store } from 'mobx-router'
import { RouterStore } from 'mobx-router'
import { routes } from '../config/routes'
import type { BreakoutUser } from '@breakoutlearning/firebase-repository/models/BreakoutUser'
import { makeObservable, observable, runInAction } from 'mobx'

export class RootStore {
  public router: RouterStore<Store>

  @observable
  impersonatingUserId?: string

  constructor() {
    this.router = new RouterStore<Store>(this)
    makeObservable(this)
  }

  navigateTo(
    routeName: string,
    paramsObj?: QueryParams,
    queryParams?: QueryParams
  ) {
    const route = routes[routeName]
    if (!route) {
      throw new Error(`Route with name ${routeName} not found`)
    }
    this.router.goTo(route, paramsObj, queryParams)
  }

  navigateToWithHook(
    routeName: string,
    {
      hook,
      params,
    }: {
      hook: () => void
      params?: QueryParams
    }
  ) {
    const route = routes[routeName]
    if (!route) {
      throw new Error(`Route with name ${routeName} not found`)
    }
    this.router.goTo(route, params)
    window.requestAnimationFrame(hook)
  }

  navigateToAdminSlideDeck(slideDeckId: string, queryParams?: QueryParams) {
    this.navigateTo('adminSlideDeck', { slideDeckId }, queryParams)
  }

  navigateToAdminCatalog(catalogId: string) {
    this.navigateTo('adminCatalog', { catalogId })
  }

  navigateToAdminCatalogs() {
    this.navigateTo('adminCatalogs')
  }

  impersonateUser(user: BreakoutUser, userId: string) {
    // only allow admin to impersonate
    if (!user.isAdmin) return
    if (!userId) return // don't allow empty userId
    runInAction(() => {
      this.impersonatingUserId = userId
    })
  }

  stopImpersonation() {
    runInAction(() => {
      this.impersonatingUserId = undefined
    })
  }

  get impersonatedUserId() {
    return this.impersonatingUserId
  }
}
