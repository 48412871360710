import { Button } from 'components/Button'
import { BookClose } from 'components/icons/BookClose'
import { Cross } from 'components/icons/Cross'
import { Money } from 'components/icons/Money'
import { PeopleGroup } from 'components/icons/PeopleGroup'
import { Search } from 'components/icons/Search'
import { SendIcon } from 'components/icons/Send'
import { Actions, Header, Headline } from 'pages/layout/TwoColumn'
import { useDialogs } from 'hooks/dialogs'
import { useRootStore } from 'hooks/rootStore'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { SectionInvitationDialog } from './SectionInvitationDialog'
import {
  InstructorSectionView,
  SectionState,
} from '@breakoutlearning/firebase-repository/types'
import { useInstructorSectionCubit } from 'hooks/cubits/instructorSection'
import { ChevronLeft } from 'components/icons/ChevronLeft'
import { EndClassDialog } from '../dialogs/EndClassDialog'
import { useBreakoutUser } from 'hooks/profile'
import { BreakoutTooltip } from 'components/design-system/BreakoutTooltip'
import { InvoicedClassDialog } from '../dialogs/InvoicedClassDialog'
import { ImpersonationInfo } from 'components/ImpersonationInfo'
import { FloatingIconActionButton } from 'components/breakout/FloatingIconActionButton'
import { AdminPanelSettingsIcon } from 'components/icons/AdminPanelSettings'

const ModeSwitchButton = observer(function ModeSwitchButton() {
  const { t } = useTranslation()
  const cubit = useInstructorSectionCubit()

  const selectedView = cubit.selectedView

  const section = cubit.section
  const assignmentCount =
    section && cubit.assignmentCount !== undefined
      ? `(${cubit.assignmentCount})`
      : ''
  const studentCount = section ? `(${cubit.userCount})` : ''

  return (
    <>
      {selectedView === InstructorSectionView.students && (
        <Button
          className="text-label-large bg-secondary text-on-secondary"
          onClick={() => cubit.changeSelectedView(InstructorSectionView.cases)}
        >
          <BookClose className="mr-1 inline" size={14} />
          {t('instructor_class.cases')} {assignmentCount}
        </Button>
      )}
      {selectedView === InstructorSectionView.cases && (
        <Button
          className="text-label-large bg-secondary text-on-secondary"
          onClick={() =>
            cubit.changeSelectedView(InstructorSectionView.students)
          }
        >
          <PeopleGroup className="mr-1 inline" size={14} />
          {t('instructor_class.students')} {studentCount}
        </Button>
      )}
    </>
  )
})

export const InstructorClassPageHeader = observer(
  function InstructorClassPageHeader() {
    const cubit = useInstructorSectionCubit()
    const { t } = useTranslation()
    const store = useRootStore()
    const { showDialog } = useDialogs()
    const user = useBreakoutUser()

    const section = cubit.section

    const sectionHasAssignments =
      section.isLoaded && cubit.sectionAssignmentsSorted.length > 0

    // assume completed to limit options if not loaded
    const sectionCompleted =
      !section.hasData || section.data.sectionState === SectionState.completed

    const sectionInvoiced = section.data.invoiced ?? false

    //   const assignmentCount =
    //   section && cubit.assignmentCount !== undefined
    //     ? `(${cubit.assignmentCount})`
    //     : ''
    // const studentCount =
    //   section && cubit.userCount !== undefined ? `(${cubit.userCount || ''})` : ''

    return (
      <Header data-testid="instructor-class-header">
        <Headline
          className="text-display-medium font-display min-w-[35%] cursor-pointer"
          onClick={() => store.navigateTo('instructorClasses')}
        >
          <div className="flex w-full flex-row items-center ">
            <div className="flex-1 pr-1">
              <ChevronLeft size={24} />
            </div>
            <BreakoutTooltip content={section.data.className}>
              <p
                className="w-full flex-auto truncate"
                data-testid="instructor-class-header-section-name"
              >
                {section.hasData ? section.data.className : 'Loading...'}
              </p>
            </BreakoutTooltip>
          </div>
          <BreakoutTooltip content={section.data.sectionName}>
            <span
              className="text-body-medium ml-6 block w-full flex-auto truncate pr-10"
              title={section.data.sectionName}
            >
              {section.data.sectionName}
            </span>
          </BreakoutTooltip>
          <ImpersonationInfo />
        </Headline>
        <Actions>
          {!sectionCompleted &&
            sectionHasAssignments &&
            cubit.sectionAssignmentsSorted.length > 0 && (
              <Button
                onClick={() => {
                  console.log('going lib with section id', section.id)
                  store.navigateTo('instructorLibrary', undefined, {
                    sectionId: section.id,
                  })
                }}
                className="text-label-large bg-tertiary text-on-tertiary"
              >
                <Search className="mr-1 inline" size={14} />
                {t('instructor_class.explore_catalog')}
              </Button>
            )}
          <ModeSwitchButton />
          {section && !sectionCompleted && (
            <Button
              className="text-label-large bg-secondary text-on-secondary"
              tooltip={t('instructor_class.invite_students_tooltip')}
              onClick={() => {
                showDialog(() => <SectionInvitationDialog cubit={cubit} />)
              }}
            >
              <SendIcon className="mr-1 inline" size={14} />
              {t('instructor_class.invite_students')}
            </Button>
          )}
          {!sectionCompleted && (
            <Button
              defaultWidth={false}
              className="bg-core-error"
              tooltip={t('instructor_class.end_class_tooltip')}
              onClick={() => {
                showDialog(() => <EndClassDialog cubit={cubit} />)
              }}
            >
              <Cross
                className="mx-6 inline-block  font-bold text-white"
                size={18}
                strokeWidth={3}
              />
            </Button>
          )}

          {user.isAdmin && (
            <FloatingIconActionButton
              kind="secondary"
              className="z-10 flex justify-end"
              menuBottom={true}
              Icon={AdminPanelSettingsIcon}
              actions={[
                {
                  text: sectionInvoiced
                    ? t('instructor_class.invoiced_tooltip')
                    : t('instructor_class.make_invoiced_tooltip'),
                  Icon: Money,
                  onClick: () => {
                    if (!sectionInvoiced)
                      showDialog(() => (
                        <InvoicedClassDialog
                          invoicedSection={cubit.invoicedSection}
                        />
                      ))
                  },
                },
              ]}
            />
          )}
        </Actions>
      </Header>
    )
  }
)
