import { MeetingCubit } from '@breakoutlearning/firebase-repository/cubits/MeetingCubit'
import { Spinner } from 'components/Spinner'
import { useRepository } from 'hooks/auth'
import { MeetingContextProvider } from 'hooks/meeting'
import { useObjectUrlCacheManager } from 'hooks/objectUrl'
import { useBreakoutUser } from 'hooks/profile'
import { useRouter } from 'hooks/router'
import { lazy, useEffect, useMemo, useState } from 'react'
const MeetingPage = lazy(() => import('./MeetingPage'))

export function MeetingEntrypoint() {
  const router = useRouter()
  const params = router.params
  const roomId =
    params?.roomId && typeof params.roomId === 'string' ? params.roomId : null
  const currentUser = useBreakoutUser()
  const repository = useRepository()

  const [meeting, setMeeting] = useState<MeetingCubit | null>(null)

  const objectUrlCache = useObjectUrlCacheManager()

  useEffect(() => {
    if (!roomId) return
    const meeting = new MeetingCubit(currentUser, repository, roomId)

    // This is a hot reload cleanup - it cancels the timer when the component is
    // hot reload happens
    if (import.meta.hot) {
      import.meta.hot.dispose((module) => {
        console.log('dispose', module)
        meeting.cancelSlideTimer()
      })
    }

    meeting.initialize()
    setMeeting(meeting)

    return () => {
      meeting?.dispose()
      objectUrlCache.clear('meeting')
    }
  }, [currentUser, repository, roomId, objectUrlCache])

  const meetingContext = useMemo(() => {
    if (!meeting) return null
    return { meeting: meeting }
  }, [meeting])

  if (!meetingContext)
    return (
      <div className="absolute box-border h-full w-full">
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      </div>
    )

  const isReady = meetingContext

  return (
    <div className="absolute box-border h-full w-full">
      {!isReady && (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )}
      {isReady && (
        <MeetingContextProvider value={meetingContext}>
          <MeetingPage />
        </MeetingContextProvider>
      )}
    </div>
  )
}
