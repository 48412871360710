import { Spinner } from 'components/Spinner'
import { BreakoutUserAvatar } from 'components/breakout/BreakoutUserAvatar'
import { EmptyState } from 'components/breakout/EmptyState'
import { BreakoutPill } from 'components/design-system/BreakoutPill'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { ChatQuestionIcon } from 'components/icons/ChatQuestion'
import { Search } from 'components/icons/Search'
import { Shield } from 'components/icons/Shield'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const InstructorAssignmentResultsQuestions = observer(
  function InstructorAssignmentResultsQuestions() {
    const cubit = useInstructorAssignmentCubit()
    const { t } = useTranslation()

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const formData = new FormData(e.currentTarget)
      const filter = formData.get('filter') as string
      if (!filter || cubit.questionFilters.includes(filter)) return
      cubit.addQuestionFilter(filter)
      // clear form
      e.currentTarget.reset()
    }

    if (!cubit.professorFeedbackDataLoaded) {
      return (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )
    }

    if (!cubit.questions.length) {
      return (
        <EmptyState
          Icon={ChatQuestionIcon}
          text={t('instructor_assignment.questions_missing')}
        />
      )
    }

    return (
      <table className="w-full table-fixed text-left">
        {/** Table Headers */}
        <thead className="border-b-1 border-medium-grey px-5 py-[5px]">
          <tr className="h-[60px] w-full text-grey-text">
            <th className="text-body-medium !important w-[220px] py-[5px] pl-5">
              {t('instructor_assignment.full_name')}
            </th>
            <th className="text-body-medium !important w-[175px] py-[5px]">
              {t('instructor_assignment.group')}
            </th>
            <th className="text-body-medium !important w-full py-[5px]">
              {t('instructor_assignment.questions')}
            </th>
            <th className="text-body-medium !important w-[220px] pr-5">
              <form onSubmit={onSubmit}>
                <BreakoutTextInput
                  name="filter"
                  autoFocus
                  LeadingIcon={Search}
                  inputClassName="text-body-medium font-medium placeholder-grey-text"
                  kind="tertiary"
                  placeholder={t('instructor_assignment.filter_name_question')}
                />
              </form>
            </th>
          </tr>
        </thead>
        {/**make a row for each question in cubit, last column spans rest of table */}
        <tbody>
          {cubit.questionFilters.length > 0 && (
            <tr>
              <td colSpan={4} className="border-b-1 border-medium-grey">
                <div>
                  <div className="py-[10px] ">
                    <BreakoutPill.Chips
                      kind="primary"
                      labels={cubit.questionFilters}
                      onClick={(f) => cubit.removeQuestionFilter(f)}
                    />
                  </div>
                </div>
              </td>
            </tr>
          )}
          {cubit.filteredQuestions.map((question, index) => (
            <tr key={index} className="border-b-1 border-medium-grey">
              <td className="py-[16px] pl-5">
                <div className="flex flex-row items-center">
                  <BreakoutUserAvatar user={question.user} radius={16} />
                  <span className="text-label-medium ml-2 h-full">
                    {question.user.fullName}
                  </span>
                  {question.isGroupLeader && (
                    <Shield className="ml-1 stroke-breakout-gold" size={16} />
                  )}
                </div>
              </td>
              <td className="text-label-medium py-[16px] align-middle">
                {question.group}
              </td>
              {/* last element spans rest of table (2 columns)*/}
              <td colSpan={2} className="text-label-medium py-[16px]">
                <div className="flex flex-col gap-1.5">
                  {question.questions.map((q, i) => (
                    <span key={i}>{q}</span>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }
)
