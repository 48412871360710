import React, {
  useId,
  forwardRef,
  useMemo,
  createRef,
  useState,
  useEffect,
} from 'react'

// utils
import { RippleEffect } from './RippleEffect'
import type { FormFieldDefaults } from './types'
import { FormError } from './form/FormError'
import classNames from 'classnames'

interface CheckboxProps extends FormFieldDefaults<'input'> {
  labelProps?: React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >
  containerProps?: React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >
  value?: boolean
  inputRef?: React.Ref<HTMLInputElement>
}

export const BreakoutCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function BreakoutCheckbox(
    {
      label,
      disabled,
      containerProps,
      labelProps,
      inputRef: passedRef,
      error,
      errorClass,
      testId,
      name,
      checked: checkedProp,
      value,
      onChange,
      ...rest
    },
    ref
  ) {
    // Checkboxes work with controlled + uncontrolled state.
    const [checked, setChecked] = useState(
      value || rest.defaultChecked || false
    )

    useEffect(() => {
      if (typeof value === 'undefined') return
      if (value === checked) {
        return
      }
      // If we are controlling the value, we need to update the checked state.
      setChecked(value || false)
    }, [checked, value])

    const checkedValue = checkedProp !== undefined ? checkedProp : checked

    const checkboxId = useId()

    const rippleEffect = new RippleEffect()

    const inputRef = useMemo(() => {
      if (passedRef && typeof passedRef !== 'function') {
        return passedRef
      }
      return createRef<HTMLInputElement>()
    }, [passedRef])

    const handleClick = () => {
      if (disabled) return
      const newChecked = !checked
      setChecked(newChecked)
      typeof onChange === 'function' && onChange(newChecked)
    }

    return (
      <>
        <div ref={ref}>
          <div className="inline-flex items-center">
            <label
              {...containerProps}
              className={classNames(
                'relative flex items-center rounded-full p-3',
                {
                  'cursor-not-allowed': disabled,
                }
              )}
              htmlFor={rest.id || checkboxId}
              onMouseUp={(e) => {
                if (rippleEffect) rippleEffect.createFromEvent(e, 'dark')

                handleClick()
              }}
            >
              <input
                {...rest}
                checked={checkedValue}
                ref={inputRef}
                type="checkbox"
                disabled={disabled}
                // Intentional NoOp. On change is handled by handleClick above.
                onChange={function () {}}
                data-testid={testId || name ? `${name}-checkbox` : undefined}
                className={classNames(
                  "border-blue-gray-200 before:content[''] before:bg-blue-gray-500 peer relative h-5 w-5 appearance-none rounded-md border bg-white transition-all before:absolute before:left-2/4 before:top-2/4 before:block before:h-12 before:w-12 before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-full before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10",
                  {
                    'opacity-50': disabled,
                    'cursor-pointer': !disabled,
                    'cursor-not-allowed': disabled,
                  }
                )}
                id={rest.id || checkboxId}
              />
              <span
                className={classNames(
                  'pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100'
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth={1}
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </label>
            {label && (
              <label
                {...labelProps}
                className={classNames(
                  'text-body-medium select-none text-on-surface-var',
                  {
                    'cursor-pointer': !disabled,
                    'cursor-not-allowed': disabled,
                  }
                )}
                onMouseUp={handleClick}
                htmlFor={rest.id || checkboxId}
              >
                {label}
              </label>
            )}
          </div>
          <div className="block w-full">
            <FormError error={error} errorClass={errorClass} />
          </div>
        </div>
      </>
    )
  }
)
