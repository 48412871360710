import { Suspense, lazy } from 'react'

const AdminEntrypoint = lazy(() => import('./AdminEntrypoint'))

// This file is the boundary which allows vite to code split the admin pages
function AdminLoader({ page }: { page: string }) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AdminEntrypoint page={page} />
    </Suspense>
  )
}

export function AdminLibraryLoader() {
  return <AdminLoader page="adminLibrary" />
}

export function AdminDashboardLoader() {
  return <AdminLoader page="adminDashboard" />
}

export function AdminCatalogsLoader() {
  return <AdminLoader page="adminCatalogs" />
}

export function AdminUsersLoader() {
  return <AdminLoader page="adminUsers" />
}
