import { captureException } from '@sentry/react'

export function copyAsyncTextToClipboard(callback: () => Promise<string>) {
  return new Promise<{
    success: boolean
    output: string
  }>((topResolve) => {
    // I'm not gonna lie, I hate this - it's only because Safari forces us to use ClipboardItem
    // but gives us no ability to get the value of the item we just wrote to the clipboard.
    // so I need to set a variable outside of the promise to get the value afterwards.
    let output = ''
    const promise = new Promise<Blob>((resolve, reject) => {
      callback()
        .then((text) => {
          output = text
          const blob = new Blob([text], { type: 'text/plain' })
          resolve(blob)
        })
        .catch(reject)
    })

    const clipboardItem = new ClipboardItem({
      'text/plain': Promise.resolve(promise),
    })

    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        // at this point, output should be set
        topResolve({
          success: true,
          output,
        })
      })
      .catch((e) => {
        captureException(e) // Capture the error in Sentry

        topResolve({
          success: false,
          output,
        })
      })
  })
}
