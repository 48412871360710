const language = {
  translation: {
    banners: {
      new_app_version_popup:
        'New app version is available, refresh your browser to update',
      new_app_version_popup_refresh: 'Refresh',
      do_later: "I'll do this later",
    },
    pills: {
      live: 'Live',
      invited: 'Invited',
      not_started: 'Not Started',
      in_progress: 'In Progress',
      completed: 'Completed',
      expired: 'Expired',
      enrolled: 'Enrolled',
      abandoned: 'Abandoned',
      draft: 'Draft',
      archived: 'Archived',
      published: 'Published',
      must_schedule: 'Schedule Now',
      scheduled: 'Scheduled',
      ready_to_start: 'Ready to Start',
      pending: 'Pending',
    },
    inputs: {
      click_icon_to_upload: 'Click the icon to upload',
    },
    design_system: {
      '12h': '12h',
      '24h': '24h',
      am: 'am',
      pm: 'pm',
      now: 'Now',
      ok: 'Ok',
    },
    login: {
      title: 'A Reinvented\nLearning Experience',
      title_sub:
        'Breakout revolutionizes education by offering a multimedia experience crafted to enhance student learning.',
      google_login: 'Continue with Google',
      microsoft_login: 'Continue with Microsoft',
      login_terms: '* By creating an account you accept our',
      terms_and_conditions: 'Terms and Conditions',
    },
    walkthrough: {
      title: 'The Breakout Learning Experience',

      description:
        'The Breakout Learning experience is divided in multiple segments. Each segment has a video, a poll, and a discussion.',
      video_title: 'Segment Videos',
      video_description:
        'You will be presented with dramatizations that place you into the shoes of the protagonists as they are faced with difficult decisions.',
      poll_title: 'Segment Polls',
      poll_description:
        'At key decision-making moments, you will be prompted to decide what would you do if you were responsible for making the decision.',
      poll_warning: 'All students are required to participate in the polls.',
      discussion_title: 'Segment Discussions',
      discussion_description:
        'This is the part that we closely monitor, make sure you discuss your thoughts and defend your decisions.',
      discussion_warning:
        'A balanced discussion is important, so make sure you engage with your peers.',
      exhibits: 'Exhibits',
      exhibits_description:
        'Exhibits offer information that will help you understand the segment and discuss it with your peers.',
      exhibits_warning:
        'Make sure to review the exhibits section before every discussion.',
      skip_walkthrough: 'Skip Walkthrough',
      next: 'Next',
      back: 'Back',
      start_session: 'Start Session',
    },
    preview: {
      slide_type_discussion: 'Slide - Discussion',
      close_exhibit: 'Close Exhibit',
    },
    navigation: {
      home: 'Home',
      profile: 'Profile',
      logout: 'Log out',
      instructor_classes: 'Instructor View',
      instructor_library: 'Explore Catalog',
      instructor_assistants: 'Assistants',
      admin_dashboard: 'Admin Dashboard',
      admin_library: 'Admin Library',
      admin_catalogs: 'Catalogs',
      admin_users: 'Users',
    },
    onboarding: {
      onboarding: 'Onboarding',
      welcome: 'Welcome to Breakout Learning',
      welcome_sub:
        'We make traditional learning modern with engaging content, and AI-moderated small group discussions.',
      class_assignments: 'Class Assignments',
      class_assignments_sub:
        'Your job is to work with your peers to finish the assignment on time.',
      enrolling: 'Enrolling In Experiences - Breakout Learning Tokens',
      enrolling_sub:
        'Purchase and use Breakout Learning Tokens to unlock the material, and join the experience.',
      groups: 'Get In Groups',
      groups_sub:
        'Breakout Learning Experiences happen in small groups. Join or create a group to complete the experience with your peers.',
      prework: 'Pre-Work & Quiz',
      prework_sub:
        'Get ready for your small group discussion by listening to the Pre-Work podcast or reading the written version. Once you’re finished, take a brief quiz before joining the experience.',
      experience: 'Main Experience',
      experience_sub:
        'Meet with your group in the Breakout Learning Room at the scheduled time. You’ll watch short videos, formulate your opinions and discuss with your peers.',
      podcast: 'Link your podcast App',
      podcast_sub:
        "For experiences that have Pre-Work, you'll be required to listen to a podcast. We can deliver this right to your podcast app.",
      before_you_go: 'Before you go..',
      link_podcast_app: 'Link Breakout Learning to your podcast App',
      continue: 'Continue',
      link_copied_clipboard: 'Link copied to clipboard',
    },
    buttons: {
      hide_completed: 'Hide Completed',
      show_completed: 'Show Completed',
    },
    library: {
      headline: 'Library',
      join_session: 'Join Session',
    },
    summary: {
      ai_summary: 'AI Summary',
      our_servers_are_busy:
        "Our servers are busy processing your session. When the data is ready it will be available on your assignment's page. Thanks again for participating in this Breakout Learning session.",
      summary_hint:
        'Breakout Learning captures lots of amazing insights from your session including this AI Summary. Your instructor will have access to all of it and will share with you most of these insights during your next class.',
      quiz_performance: 'Group Quiz Performance',
      quiz_performance_hint: 'This measures the aggregate results of the quiz',
      poll_agreement: 'Group Agreement Percentage',
      poll_agreement_hint: 'This measures how your poll answers were similar',
    },
    instructor_assignment: {
      quiz_results: 'Quiz Results',
      view_details: 'View Details',
      completion: 'Completion',
      view_teaching_slides: 'View Teaching Slides',
      scheduled_date_invalid: 'Scheduled time must be later than current time',
      deadline: 'Deadline: {{date}}',
      choose_deadline: 'Choose Deadline',
      assignment_deadline: 'Assignment Deadline',
      view_session: 'View Session',
      send_test_email: 'Send Test Email',
      send_test_email_confirmation:
        'Are you sure you want to send a test of this professor email to yourself?',
      yes: 'Yes',
      no: 'No',
      questions: 'Questions',
      polls: 'Polls',
      quiz: 'Quiz',
      rubric: 'Rubric',
      questions_missing:
        'Questions may be available after at least 1 group completes the assignment.',
      full_name: 'Full Name',
      group: 'Group',
      filter_name_question: 'Filter by Name/Question',
      filter_names: 'Filter Names',

      show_summary: 'View AI Summary',
      monitor_session: 'Monitor Session',

      add_member: 'Add Member',

      room_state_status_live: 'Live',
      room_state_status_abandoned: 'Abandoned',
      room_state_status_must_schedule: 'Must Schedule',
      room_state_status_completed: 'Completed',
      room_state_status_scheduled: 'Scheduled',
      room_state_status_expired: 'Expired',

      not_assigned_to_group: 'Not Assigned to Group',
      student_count_zero: 'No students',
      student_count_one: '1 Student',
      student_count_other: '{{count}} Students',

      blooms_taxonomy: "Bloom's Taxonomy",
      blooms_score: "Bloom's Score",
      bloom_0: 'Did Not Discuss',
      bloom_1: 'Remembering',
      bloom_2: 'Understanding',
      bloom_3: 'Applying',
      bloom_4: 'Analyzing',
      bloom_5: 'Evaluating',
      bloom_6: 'Creating',
      bloom_low: 'Low Understanding',
      bloom_medium: 'Medium Understanding',
      bloom_high: 'High Understanding',
      pass_fail: 'Pass/Fail',
      pass_fail_0: 'Did Not Discuss',
      pass_fail_1: 'Discussed',
      groups: 'Groups',
      rubric_details: 'Rubric Details',
      switch_to_student_view: 'Switch to Student View',
      switch_to_group_view: 'Switch to Group View',
      justification: 'Justification',
      highlighted_justification: 'Highlighted Justification',
      no_results: 'We did not find any results with these filters.',
      search_students: 'Search Students',
      all_results: 'All Results',
      group_leader_name: 'Group Leader: {{name}}',
      assignment_summary: 'Assignment Summary',
      quiz_assessment: 'Quiz Assessment',
      n_answers_captured: '{{count}} Answers Captured',
      number_of_students: 'Number of Students',
      n_questions_captured: '{{count}} Questions Captured',
      overall_rubric_assessment: 'Overall Rubric Assessment',
      rubric_assessment: 'Rubric Assessment',
      n_captured_responses: '{{count}} Captured Responses',
      polls_answered: 'Polls Answered',
      highlighted_question: 'Highlighted Question',
      most_common_wrong_answer: 'Most Common Wrong Answer',
      number_of_groups: 'Number of Groups',
      group_rubric_assessment: 'Group Rubric Assessment',
      group_scoring_formula: 'Group Scoring Formula',
      methodology: 'Methodology',
      view_ai_summary: 'View AI Summary',
      ai_summary: 'AI Summary',
      our_servers_are_busy:
        "Our servers are busy processing your session. When the data is ready it will be available on your assignment's page. Thanks again for participating in this Breakout Learning session.",
      summary_hint:
        'Breakout Learning captures lots of amazing insights from your session including this AI Summary. Your instructor will have access to all of it and will share with you most of these insights during your next class.',
      students_signed_up: 'Students Signed Up',
      groups_created: 'Groups Created',
      polls_missing:
        'Polls results will be available after at least 1 group completes the assignment.',
      quizzes_missing:
        'Quiz results will be available after the students complete the assignment.',
      sessions_summary_missing:
        'Sessions summary will be available after at least 1 group completes the assignment.',
      students_missing:
        'Students details will be available after they join the class.',
      results_missing:
        'Results will be available after at least 1 group completes the assignment.',
      score: 'Score',
      average_score: 'Average Score',
      individual_average_score: 'Individual Average Score',
      average_individual_score: 'Average Individual Score',
      group_score_vs_best_individual_score: 'Group Better Than Best Individual',
      group_score_vs_average_individual_score:
        'Group Better Than Average Individual',
      best_individual_score: 'Best Individual Score',
      group_average_score: 'Group Average Score',
      group_score: 'Group Score',
      n_students: '{{count}} Students',
      slide_question: 'Question',
      slide_question_type_sorting: 'Sorting Exercise',
      students: 'Students',
      version_label: 'Version {{version}}',
      description: 'Description',
      poll_n: 'Poll {{count}}',
    },
    instructor: {
      arguments: 'Arguments',
      rubric: 'Rubric',
      score: 'Score',
      highlighted_justification: 'Highlighted Justification',
      justification: 'Justification',
      rubric_assessment: 'Rubric Assessment',
      show_arguments: 'Show Arguments',
      hide_arguments: 'Hide Arguments',
      rubric_result_0: 'Did Not Discuss',
      rubric_result_1: 'Discussed',
      rubric_result_2: 'Understanding',
      rubric_result_3: 'Applying',
      rubric_result_4: 'Analyzing',
      rubric_result_5: 'Evaluating',
      rubric_result_6: 'Creating',

      group_user_remove_confirmation:
        'Are you sure you want to remove this student from the group?',
      group_user_student_name: 'Student Name',
      group_user_group_name: 'Group Name',
      group_user_remove_from_group: 'Remove from Group',

      group_user_add_student_to_group: 'Add Student to Group',
      group_user_add_search_by_name: 'Search by Name',
      group_user_not_in_group: 'Not in a group',
      group_user_add_to_group:
        'Are you sure you want to add this student to the group?',
      group_leader: 'Group Leader',

      confirm: 'Confirm',
    },
    instructor_library: {
      instructor_view: 'Instructor View',
      admin_view: 'Admin View',
      headline: 'Explore Catalog',
      students: 'Students',
      experiences: 'Experiences',
      price_student_free: 'Free',
      price_student_zero: '<bold>0</bold> Tokens/Student',
      price_student_one: '<bold>{{ count }}</bold> Token/Student',
      price_student_other: '<bold>{{ count }}</bold> Tokens/Student',
      price_tokens_zero: '0 Tokens',
      price_tokens_one: '1 Token',
      price_tokens_other: '{{ count }} Tokens',
      price_tokens_per_student: 'per Student',
      experience_settings: 'Experience Settings',
      assignment_type_student_led: 'Student Led',
      assignment_type_instructor_led: 'Instructor Led',
      assignment_type: 'Assignment Type',
      select_class: 'Select Class',
      students_self_grouping: 'Student Self Grouping',
      automatic_randomized_grouping: 'Automatic Randomized Grouping',
      student_grouping: 'Student Grouping',
      automatic_grouping: 'Automatic Grouping',
      confirm_auto_grouping1:
        'The software will assign students to groups based on your selected group size. Any student that is left without a group will be placed into a group on:',
      confirm_auto_grouping2:
        'You can also manually assign students to groups from the Students tab in your professor view.',
      confirm_self_grouping1:
        'Students will be responsible for creating and joining groups. The software allows a maximum of six students per group.',
      confirm_self_grouping2:
        'You can also manually assign students to groups using the Students tab in your professor view.',
      assign_to_class: 'Assign to Class',
      deadline: 'Deadline',
      section_missing: 'Please select a class',
      deadline_invalid: 'Deadline is invalid',
      deadline_must_be_future: 'Deadline must be in the future',
      deadline_too_far_out: 'Deadline must be less than a year from now',
      deadline_required: 'Please select a deadline',
      deadline_missing: 'Please set deadline',
      desired_group_size: 'Desired Group Size',
      grouping_size_invalid: 'group size must be between 2 and 6',
      preview: 'Preview',
      add_class: 'Add Class',
      retire_as_ta: 'Retire as TA',
      retire_as_ta_confirmation:
        "Are you sure you want to cease being this instructor's TA?",
      yes: 'Yes',
      no: 'No',
      no_classes_found: 'No classes found.',
      pending: 'Pending',
      completed: 'Completed',
    },
    instructor_class: {
      cases: 'Experiences',
      students: 'Students',
      explore_catalog: 'Explore Catalog',
      invite_students: 'Invite Students',
      invite_students_tooltip:
        'Create an invitation link to share with your students',
      end_class_tooltip: 'End Class',
      make_invoiced_tooltip: 'Make Class Invoiced',
      invoiced_tooltip: 'Section Is Invoiced',
      full_name: 'Full Name',
      assignment: 'Assignment',
      quiz_results: 'Quiz Results',
      rubric_assessment: 'Rubric Assessment',
      filter_names: 'Filter Names',
      end_class: 'End Class',
      end_class_confirmation:
        'Ending the class will disallow further experiences to be assigned to this class.',
      invoiced_class: 'Make Section Invoiced',
      invoiced_class_confirmation:
        'Are you sure you want to make this section invoiced?',
      yes: 'Yes',
      no: 'No',
      no_students: 'No students are enrolled in this class',
      no_students_description:
        'Copy your invitation link and share it with your class to get started',
      no_cases: 'No experiences added to this class',
      no_cases_description:
        'Explore our collection of interactive experiences and assign them to this class',
      create_invite_link: 'Create Invite Link',
      link_copied_clipboard: 'Link copied to clipboard',
      invite_students_instructions:
        'Students who receive this link and use it within the validity period will be added to this class.\n\nA One Month Link will be valid for a month and anyone with it will be added to this class.\n\nA One Time Link can be used only one time by one person.\n\nLink created will be copied to your clipboard for sharing.',
      linked_copied_clipboard_failure: 'Copy the link below',
      link_warning: 'Make sure you only share this link with your students.',
      one_time_link: 'Create One Time Link',
      one_month_link: 'Create One Month Link',
    },
    instructor_classes: {
      headline: 'Classes',
      impersonating: 'Impersonating: {{instructor}}',
      stop_impersonating: 'Stop Impersonating',
    },
    instructor_assistants: {
      headline: 'Assistants',
      create_assistant_invitation: 'Create Assistant Invitation',
      teachers_assistants_welcome:
        'Assistants are authorized to operate the platform on your behalf. They can create classes, add experiences, and invite students.',
      remove_ta: 'Remove TA',
      remove_ta_confirmation: 'Are you sure you want to remove this TA?',
      yes: 'Yes',
      no: 'No',
      link_copied_clipboard: 'Link copied to clipboard',
      link_warning_assistants:
        'Make sure you only share this link with your assistants.',
    },
    admin_dashboard: {
      headline: 'Admin Dashboard',
      running: 'Running Classes',
      scheduled: 'Scheduled Classes',
    },
    video_upload: {
      processing_video_upload: 'Processing Video Upload',
      processing_video_saving: 'Saving Video…',
      processing_video_saved: 'Video saved!',
      show_processing_video: 'processing_video.webm',
    },
    admin_library: {
      headline: 'Admin Library',
      versions: '{{count}} Versions',
      versions_one: '1 Version',
      versions_zero: '0 Versions',

      show_hidden: 'Show Hidden',
      hide_hidden: 'Hide Hidden',

      add_case_study: 'Add Experiences',

      video: 'Video',
    },
    admin_slide_deck: {
      delete: 'Delete',
      preview: 'Preview',
      make_a_copy: 'Make a Copy',
      experience_details: 'Experience Details',
      slides: 'Slides',
      interactive_elements: 'Interactive Elements',
      rubrics: 'Rubrics',
      exhibits: 'Exhibits',
      course_materials: 'Course Materials',
      authors: 'Authors',
      slide_deck_create_copy_dialog_title_waiting: 'Creating a new copy...',
      slide_deck_create_copy_dialog_subtitle_waiting:
        'Please do not close this page till the process is completed',
      slide_deck_create_copy_dialog_title_resolved: 'New Version Created',
      slide_deck_create_copy_dialog_subtitle_resolved:
        'A new duplicate for this version has been created and added to your library',
      slide_deck_deep_copy_dialog_button_title: 'Go to New Version',
      slide_deck_deep_copy_input_dialog_title: 'Create a copy',
      slide_deck_deep_copy_input_dialog_subtitle:
        'A new duplicate of this experience will be created and added to your library.',
      version_name: 'Version Name',
      version_invalid: 'Version name invalid',
      version_exists: 'Version exists',
      select_catalog: 'Select Catalog',
      slide_deck_delete_dialog_title: 'Delete Experience',
      slide_deck_delete_dialog_subtitle:
        'Are you sure you want to delete this experience?',
    },
    admin_catalogs: {
      headline: 'Catalogs',
      new_catalog: 'New Catalog',

      create_new_catalog: 'Create a new catalog',

      experiences: '{{count}} Experiences',
      experiences_one: '1 Experience',
      experiences_zero: 'No Experiences',

      catalog_name: 'Catalog Name',

      catalog_description: 'Catalog Description',
      confirm: 'Confirm',

      name_required: 'Name is required',
      description_required: 'Description is required',

      invite_users: 'Invite Users',
      add_experiences: 'Add Experiences',
      edit_sections: 'Edit Sections',

      version: 'Version',

      experiences_tab: 'Experiences',
      details_tab: 'Details',

      delete_catalog: 'Delete Catalog',
      delete_catalog_protected: 'Catalog is protected and can not be deleted',

      catalog_details: 'Catalog Details',

      catalog_delete_dialog_title:
        'Are you sure you want to delete this catalog?',
      catalog_delete_dialog_subtitle:
        "Once a catalog is deleted, all users with access to this catalog won't be able to see it in their library.",

      catalog_remove_user_dialog_title:
        'Are you sure you want to remove access from this user?',
      catalog_remove_user_dialog_subtitle:
        'Users who are removed will not see this catalog in their library page.',

      authorized_users: 'Authorized Users',
      add_new_user: 'Add A New User',
      select_user: 'Select User',

      remove: 'Remove',

      no_instructors: 'No instructors found',

      select_section: 'Select Section',

      select_experience: 'Select Experience',
      select_version: 'Select Version',

      create_invite_link: 'Create Invite Link',
      catalog_invite_link_description:
        'Users who receive this link and use it within the validity period will get access to this catalog.\n\nLink created will be copied to your clipboard for sharing.',

      catalog_invite_link_warning:
        'Make sure you ONLY share this link with the intended users.',

      link_copied_clipboard: 'Link copied to clipboard',
      one_time_link: 'Create One Time Link',
      one_month_link: 'Create One Month Link',
    },
    admin_users: {
      headline: 'Users',
      tokens_granted: 'Tokens Granted',
      grant_tokens: 'Grant Tokens',
      grant_tokens_dialog_sub:
        'Choose a token amount to grant to the selected user',
      grant_amount_tokens: 'Grant {{count}} Tokens',
      impersonate: 'Impersonate',

      create_instructor_invitation: 'Create Instructor Invitation',
      admin_users_instructor_invite_dialog_title: 'Invite Instructor',
      admin_users_instructor_invite_dialog_subtitle:
        'The catalog dictates which experiences the instructor will have access to',

      select_catalog: 'Select Catalog',
    },
    profile: {
      headline: 'User Profile',
      tokens_headline: 'Breakout Learning Tokens',
      tokens_explanation:
        'Breakout Learning uses a token system for experiences. You have <bold>{{tokensAvailable }}</bold> tokens. To join the experiences your professors assign to your class, you need to purchase tokens.',
      tokens_available: 'Available Tokens',
      tokens_consumed: 'Tokens Consumed',

      details_headline: 'Personal Details',

      first_name: 'First Name',
      last_name: 'Last Name',
      email_address: 'Email Address',

      select_language: 'Select Language',

      save_profile: 'Save Profile',

      other_resources: 'Other Resources',
      test_av: 'Test Audio/Video',
      privacy_policy: 'Privacy Policy',
      disable_animations: 'Disable Animations',
      enable_animations: 'Enable Animations',

      profile_updated: 'Profile updated successfully',
    },
    student_assignment: {
      restart_case_study: 'Restart Experience',
      process_ai_again: 'Process AI Again',
      ai_processing_started: 'AI Processing Started',
      ai_processing_running: 'AI Processing Already Running',
      group_name_exists: 'Group name already exists.',
      group_name_required: 'Group name is required.',
      scheduled_date_after_deadline:
        'Scheduled time must be before the class deadline (${deadline})',
      scheduled_date_invalid: 'Invalid date',
      scheduled_date_in_past: 'Scheduled time must be in the future',
      scheduled_date_required: 'Please pick a scheduled time',
      edit_group_details: 'Edit Group Details',
      leave_group: 'Leave Group',
      no_students: 'No students are enrolled in this class',
      assignment_expired: 'Assignment Expired',
      assignment_expired_description:
        'It looks like you missed out on this assignment.The deadline has passed. We look forward to seeing you in the next one!',
      prework_listen_to_podcast: 'Listening to the podcast',
      prework_read_case_summary: 'Reading the summary of the experience',
      time_to_join: 'Time to Join Session',
      session_live: 'Session is Live',
      quiz_required:
        'You must take the pre-session quiz before joining the session.',
      course_materials: 'Course Materials',
      course_materials_info:
        'Course materials help you understand the core concepts of the experience and prepare you for the discussion.',
      start_quiz: 'Start Quiz',
      quiz_duration: 'Time needed to complete (10 mins)',
      start_quiz_warning:
        'You can take this quiz only once. So make sure you have the time to complete it.',
      complete_pre_work: 'Complete Pre-Work',
      complete_pre_work_title: 'Complete Pre-Work and Take Quiz',
      complete_pre_work_body:
        'Please complete the pre-work for this assignment and take the quiz.',
      please_join_now: 'Please join now.',
      join_session: 'Join Session',
      pre_work: 'Pre-Work',
      pre_work_complete:
        'Complete the Pre-work to prepare you to join the discussion.',
      listening_to_the_experience: 'Listening to the experience summary',
      reading_the_experience_summary: 'Reading the experience summary',
      start_session: 'Start Session',
      quiz_results: 'Quiz Results',
      quiz_completed: 'Quiz Completed',
      quiz_completed_description:
        'Thank you for taking the quiz. You will be able to join the session with your peers when it starts.',
      exit_portal: 'Exit to Portal',
      view_details: 'View Details',
      tokens_available_title: 'Tokens Available',
      enroll_in_case: 'Enroll in Experience',
      token_description:
        'Breakout Learning uses a token system for experiences. To join the experiences your professors assign to your class, you need to purchase tokens.',
      purchase_tokens: 'Purchase Tokens',
      token: 'Token',
      tokens_consumed_title: 'Tokens Consumed',
      tokens_available_colon: 'Tokens Available:',
      tokens_required_colon: 'Tokens Required:',
      tokens_to_purchase: 'Tokens To Purchase',
      tokens: 'Tokens',
      total_price_colon: 'Total Price:',
      purchase_tokens_description:
        'Breakout Learning works with a Token based system. You need to purchase tokens to enroll in experiences. Tokens are valid for 1 year and can be used for any classes we host.',
      pay_with_stripe: 'Pay with Stripe',
      pay_with_stripe_failure: 'Unexpected payment failure',
      enroll_failure: 'Unexpected enrollment failure',
      correct_answer: 'Correct Answer',
      view_experience: 'View Experience',
      view_summary: 'View Summary',
      join_group: 'Join Group',
      create_new_group: 'Create New Group',
      group_name: 'Group Name',
      students_in_group: 'Students in Group',
      group_details: 'Group Details',
      scheduled_date: 'Scheduled Date',
      edit: 'Edit',
      schedule_now: 'Schedule Now',
      action: 'Action',
      join_group_confirm: 'Are you sure you want to join this group?',
      confirm: 'Confirm',
      start_session_confirmation:
        'Are you sure you want to start the session?\n\nYou will be responsible for controlling the slides throughout the session.\n\nYou should only do this when the group is expecting to meet because each meeting can only run once.',
      step_1: 'Step 1',
      step_2: 'Step 2',
      step_3: 'Step 3',
      join_or_create_group: 'Join or Create Group',
      join_or_create_group_description:
        'Please join a group to complete this step. You can also create a new group and your peers will be able to join it.',
      uses_automatic_randomized_grouping: 'Assignment uses automatic grouping',
      group_tbd: 'Group (TBD)',
      group_tbd_description:
        'Our system will assign you to a group soon. You can still join a group manually if you coordinate with your professor or TA',
      manually_join_group: 'Manually Join a Group',

      not_scheduled: 'Not Scheduled',
      scheduled: 'Scheduled',
      not: 'Not',
      show_prework: 'Show Pre-Work',
      prework: 'Pre-Work',
      prework_description: 'Complete the Pre-Work by:',
      processing_payment: 'Processing Payment',
    },
    student_library: {
      see_how_it_works: 'See How It Works',
      privacy_policy: 'Privacy Policy',
      help_center: 'Help Center',
      message_support: 'Message Support',
      other_resources: 'Other Resources',
      // English is so nice, that's the only setup you need
      token_number: '{{count}} Tokens', // many
      token_number_one: '{{count}} Token', // one
      empty_placeholder:
        'Experiences will appear here when invited by professor',
      empty_placeholder_description:
        "If you got invited and cases didn't appear here, your invitation \nis probably expired. Your instructor or TA can supply you with\nanother. Alternately, you can send us an email at:",
    },
    meeting: {
      discussion_index:
        'Discussion {{discussionSlideIndex}} of {{discussionSlideCount}}',
      welcome_to_breakout: 'Welcome to Breakout',
      feedback_title: 'Share your feedback with your professor.',
      processing_session: 'Processing Session Data... Please Wait',
      take_poll:
        'First, take the poll below, then advance to the next slide to begin your discussion.',
      waiting_poll: 'Waiting for your peers to complete the poll',
      take_quiz: 'Please take the following quiz',
      take_group_quiz:
        'Please answer the following question with input from your group.',
      waiting_quiz: 'Waiting for your peers to complete the quiz',
      exhibit_grid: 'Exhibit Grid',
      advance_to_next_slide: 'Advance to the next slide',
      advance_to_next_question: 'Advance to the next question',
      when_ready: 'When your group is ready!',
      waiting_for_next_slide:
        'Waiting for the group leader to advance to the next slide',
      progress_time_left: 'Time left',
      progress_loading: 'Loading',
      progress_time_up: 'Time is up',
      time_left_zero: '<1 min',
      time_left_one: '1 min',
      time_left_other: '{{count}} mins',
      great_session: 'You did great on this session. Thank you!',
      disconnect: 'Leave Room',
      disconnect_prompt: 'Are you sure you want to leave this room?',
      quiz_question_count: 'Question {{index}} out of {{count}}',

      send_message: 'Send Message',

      confirm: 'Confirm',

      bottom_icon_disable_camera: 'Disable Camera',
      bottom_icon_enable_camera: 'Enable Camera',
      bottom_icon_mute_audio: 'Mute Audio',
      bottom_icon_unmute_audio: 'Unmute Audio',
      bottom_icon_start_screen_share: 'Share Screen',
      bottom_icon_stop_screen_share: 'Stop Sharing Screen',
      bottom_icon_media_device: 'Configure Audio/Video',
      bottom_icon_assert_leadership: 'Assert Leadership',
      bottom_icon_toggle_sidebar: 'Toggle Sidebar',
      bottom_icon_leave_meeting: 'Leave Room',
      bottom_icon_captions: 'Toggle Captions',
      exit_portal: 'Exit to Portal',
      end_timer: 'End Timer',
      next_slide: 'Next Slide',
      group_leader: 'Group Leader',
      promote_to_group_leader: 'Promote to Group Leader',

      share: 'Share',
      stop_sharing: 'Stop Sharing',
      confirm_exhibit_action_title:
        'Are you sure your want to share this exhibit?',
      confirm_exhibit_action:
        'This action will present this exhibit file to all the members in this room.',
    },
    admin: {
      version: 'Version',
      add_new_rubric: 'Add New Rubric',
      required: 'required',
      saving_slide_deck: 'Saving Slide Deck…',
      error: 'Error',
      seconds: 'Seconds',
      dashboard: 'Admin Dashboard',
      observe: 'Observe',
      grant_tokens: 'Grant Tokens',
      users: 'Users',
      add_new_course_material: 'Add New Course Material',
      add_new_author: 'Add New Author',
      delete_material: 'Are you sure you want to delete this course material?',
      delete_author: 'Are you sure you want to delete this author?',
      delete_exhibit: 'Are you sure you want to delete this exhibit?',
      delete_rubric: 'Are you sure you want to delete this rubric?',
      author_deleted: 'Author deleted.',
      add_new_question: 'Add New Interactive Element',

      delete_question: 'Are you sure you want to delete this question?',
      question_deleted: 'Question deleted.',
      confirm: 'Confirm',
      course_materials: 'Course Materials',
      authors: 'Authors',
      questions: 'Interactive Element',
      add_to_case: 'Add to Experience',
      save: 'Save',
      slide_details: 'Slide Details',
      add_new_slide: 'Add New Slide',
      delete_slide_title: 'Delete Slide',
      delete_slide: 'Are you sure you want to delete this slide?',
      delete_slide_image_title: 'Delete Slide Image',
      delete_slide_image: 'Are you sure you want to delete the slide image?',
      delete_slide_video_title: 'Delete Slide Video',
      delete_slide_video: 'Are you sure you want to delete the slide video?',
      add_new_exhibit: 'Add New Exhibit',
      exhibits: 'Exhibits',
    },
    admin_details: {
      archive: 'Archive',
      removed_from_catalog: 'Removed from Catalog',
      catalog_remove_version_dialog_title:
        'Are you sure you want to remove version from catalog?',
      catalog_remove_version_dialog_subtitle:
        'Once an experience is removed from a catalog, all users who have access to this catalog will not be able to see this experience.',
      catalog_remove_feature_warning:
        'Experience must be un-featured before it is removed from the last catalog',
      proceed_anyways: 'Proceed Anyways',
      cancel: 'Cancel',
      added_to_catalog: 'Added to Catalog',
      publish_error: 'Unexpected Error publishing slide deck',
      slide_deck_publish_dialog_title:
        'This action will mark this experience as published',
      slide_deck_publish_dialog_subtitle:
        'Once an experience is published, you will not be able to make any changes to it',
      no_catalogs: 'No Catalogs',
      confirm: 'Confirm',
      select_catalog: 'Select Catalog',
      experience_details: 'Experience Details',
      catalogs: 'Catalogs',
      catalog_remove_case_dialog_title:
        'Are you sure you want to remove this experience from the catalog?',
      catalog_remove_case_dialog_subtitle:
        'Once an experience is removed from a catalog, all users who have access to this catalog will not be able to see this experience.',
      catalog_add_case_draft_warning:
        'WARNING: once an experience has been added to a catalog it can no longer be edited.',
      catalog_add_case_type_exists_warning:
        'WARNING: an experience of this type already exists in the catalog.',
      remove: 'Remove',
      remove_from_catalog: 'Remove from Catalog',
      add_to_catalog: 'Add To Catalog',
      add_version_to_catalog: 'Add Version to a Catalog',
      objectives: 'Objectives',
      add_new_item: 'Add New Item',
      details_saved: 'Details saved ✅',
      value_objectives: '{count} Objectives Added',
      value_objectives_one: '1 Objective Added',
      value_no_objectives: 'No Objectives Added',
      required_one_objective: 'At least one objective is required',
      required_description: 'Please add an experience summary',
      disciplines: 'Disciplines',
      description: 'Experience Summary',
      required_discipline: 'Please enter a discipline',
      discipline_already_exists: 'Discipline already exists',
      objective_already_exists: 'Objective already exists',
      value_no_disciplines: 'No Disciplines',
      industries: 'Industries',
      required_industry: 'Please enter a industry',
      industry_already_exists: 'Industry already exists',
      value_no_industries: 'No Industries',
      save_objectives: 'Save Objectives',
      save_disciplines: 'Save Disciplines',
      save_industries: 'Save Industries',
      version_name: 'Version Name',
      cannot_use_initial_version_name: 'Cannot use 0.0.0 as the version name',
      required_version_name: 'Please enter a version name',
      unique_version_name: 'A version with this name already exists',
      subtitle_name: 'Experience Subtitle',
      required_subtitle_name: 'Please enter an experience subtitle',
      title: 'Experience Title',
      required_title: 'Please enter an experience title',
      back_to_select: 'Back to Select',
      view: 'View',
      edit: 'Edit',
      slide_deck_existing_versions_dialog_replace_button_title: {
        plural: 'Replace Versions', // many
        singular: 'Replace Version', // one
      },
      slide_deck_existing_versions_dialog_publish_button_title: {
        plural: 'Publish All Versions', // many
        singular: 'Publish Both Versions', // one
      },
      slide_deck_existing_versions_dialog_title: {
        plural: 'Existing versions found', // many
        singular: 'Existing version found', // one
      },
      slide_deck_existing_versions_dialog_subtitle: {
        plural:
          'This catalog already has {{count}} versions from this experience, you can publish all versions or replace the existing ones', // many
        singular:
          'This catalog already has a version from this experience, you can publish both versions or replace the existing one', // one
      },
      slide_deck_existing_featured_versions_dialog_replace_button_title: {
        plural: 'Replace Versions', // many
        singular: 'Replace Version', // one
      },
      slide_deck_existing_featured_versions_dialog_publish_button_title: {
        plural: 'Feature All Versions', // many
        singular: 'Feature Both Version', // one
      },
      slide_deck_existing_featured_versions_dialog_title: {
        plural: 'Featured versions found', // many
        singular: 'Featured version found', // one
      },
      slide_deck_existing_featured_versions_dialog_subtitle: {
        plural:
          'This experience already has {{count}} featured versions, you can feature all versions or replace the existing ones', // many
        singular:
          'This experience already has a featured version, you can feature both versions or replace the existing one', // one
      },
      slide_deck_replace_existing_featured_versions_dialog_title: {
        plural: 'Featured versions', // many
        singular: 'Featured version', // one
      },
      slide_deck_replace_existing_featured_versions_dialog_subtitle: {
        plural: 'Proceeding will un-feature the following versions', // many
        singular: 'Proceeding will un-feature the following version', // one
      },
      slide_deck_invalid_for_feature:
        'Slide deck can not be featured (requires icon and hero image)',
      saving: 'Saving...',
      saved: 'Saved',
      label_price: 'Token(s) / Student',
      required_price: 'Please enter a token amount',
      min_price: 'Minimum token amount is 0',
      invalid_price: 'Please enter a valid positive integer',
      invalid_google_slide_url: 'Please enter a valid Google Slide URL',
      label_teaching_slides_url: 'Teaching Slides URL',
      label_featured: 'Slide Deck Featured',
      slide_deck_featured_tooltip:
        'Only Slide Decks with a name, logo, and a  large featured image\n (in materials) can be featured. Once these elements are provided, the Slide Deck will be showcased on the login page.',
    },
    slide: {
      image_dimensions_error: 'Image dimensions must be {{hor}}x{{ver}}',
      slide_type: 'Slide Type',
      slide_description: 'Slide Description',
      slide_description_invalid: 'Slide description required',
      slide_details: 'Slide Details',
      slide_duration: 'Duration',
      slide_duration_invalid: 'Integer seconds required (zero is fine)',
      slide_name: 'Slide Title',
      slide_name_invalid: 'Slide title required',
      slide_type_image: 'Slide - Image',
      slide_type_video: 'Slide - Video',
      slide_type_exhibit_grid: 'Slide - Exhibit Grid',
      slide_type_discussion: 'Slide - Discussion',
      slide_type_group_quiz: 'Slide - Group Quiz',
      slide_type_interactive_quiz: 'Slide - Interactive Quiz',
      slide_type_solo_quiz: 'Slide - Solo Quiz',
      slide_type_interactive_poll: 'Slide - Interactive Poll',
      slide_type_professor_feedback: 'Slide - Professor Feedback',
      slide_type_session_processing: 'Slide - Session Processing',
      slide_type_session_results: 'Slide - Session Results',
      slide_image: 'Slide Image',
      slide_video: 'Slide Video',
      show_slide_image: 'slide.jpg',
      show_slide_video: 'slide.webm',
      exhibits: 'Exhibits',
    },
    rubric: {
      select_slide: 'Select Slide',
      all_slides: 'All Slides',
      slide_rubric_prompt_invalid: 'prompt required',
      slide_rubric_prompt: 'Prompt',
      slide_rubric_description_invalid: 'description required',
      slide_rubric_description: 'Description',
      slide_rubric_invalid: 'rubric required',
      slide_rubric: 'Rubric',
      slide_rubric_label_0: 'Label 1',
      slide_rubric_label_0_invalid: 'label required',
      slide_rubric_label_1: 'Label 2',
      slide_rubric_label_1_invalid: 'label required',
      slide_rubric_label_pass: 'Pass Label',
      slide_rubric_label_fail: 'Fail Label',
      rubric: 'Rubric',
      rubric_details: 'Rubric Details',
      rubric_type: 'Rubric Type',
      rubric_type_blooms: "Bloom's Taxonomy",
      rubric_type_pass_fail: 'Pass/Fail',
    },
    exhibit: {
      label_slide: 'Slide',
      label_name: 'Name',
      label_description: 'Description',
      label_image: 'Image',
      view_image: 'View Image',
      required_slide: 'Please select a slide',
    },
    question: {
      label_slide: 'Slide',
      label_question_type: 'Question Type',
      label_question: 'Question',
      label_select_individual_slide: 'Select Individual Quiz Slide',
      label_select_group_slide: 'Select Group Quiz Slide',
      label_answers: 'Answers',
      label_sorting_answers: 'Items (Add in the right order)',
      label_correct_answer: 'Correct Answer',
      required_one_answer: 'Please add at least one answer',
      double_no_slide_error:
        'Either individual or group slide must be selected',
      placeholder_no_answers: 'No answers added',
      required_slide: 'Please select a slide',
      required_question_type: 'Please select a question type',
      required_question: 'Please add a question',
      required_correct_answer: 'Please select a correct answer',
      question_type_poll: 'Poll',
      question_type_custom_poll: 'Custom Poll',
      question_type_multiple_choice: 'Multiple Choice',
      question_type_sorting: 'Sorting Exercise',
      question_type_title: 'Slide Title',
      option_no_slide: 'No Slide',
      option_no_slide_for_group: 'No Slide (Sorting Exercise Only)',
      option_pre_meeting_quiz: '0. Pre-Meeting Quiz',
      individual_quiz_slide: 'Individual Quiz Slide',
      group_quiz_slide: 'Group Quiz Slide',
    },
    material: {
      pre_post_info:
        '"Pre-session" or "Post-session" indicates whether the student is expected to have access to the material before or after the meeting.\nA post-session material will become visible only after the session is over, whereas a pre-session material will appear in the list of Pre-Work materials.',
      see_in_podcast_player: 'See in podcast player',
      see_in_video_player: 'See in video player',
      required_name: 'Please add a material name',
      required_work_type: 'Please select a work type',
      required_description: 'Please add a description',
      required_link: 'Please add a link',
      required_image: 'Please upload an image',
      required_file: 'Please upload a file',
      required_url: 'Please add a url',
      invalid_link: 'Please enter a valid url (ex. https://www.example.com)',
      invalid_image_upload: 'Invalid image, please attempt to upload again',
      label_name: 'Name',
      label_type: 'Type',
      label_work_type: 'Pre or Post-Session Material?',
      label_link: 'Link',
      label_description: 'Description',
      label_viewable_by: 'Viewable By',
      label_viewable_by_student: 'Students',
      label_viewable_by_instructor: 'Instructors',
      label_viewable_by_public: 'Public',
      label_image: 'Upload Image',
      label_audio: 'Upload Audio',
      label_video: 'Upload Video',
      label_pdf: 'Upload PDF',
      view_pdf: 'View PDF',
      view_video: 'View Video',
      view_audio: 'Play Audio',
      view_image: 'View Image',
      view_file: 'View File',
      error_upload_pre_save: 'Something went wrong, please try uploading again',
      // material type:
      option_url: 'URL',
      option_pdf: 'PDF',
      option_mp3: 'MP3',
      option_mp4: 'MP4',
      option_webm: 'WebM',
      option_featuredLarge: 'Featured (Hero) Image',
      option_jpg: 'JPG',

      // work type:
      option_pre_work_type: 'Pre-Session',
      option_post_work_type: 'Post-Session',
      option_neither_work_type: 'Neither',
    },
    author: {
      label_name: 'Author Name',
      label_title: 'Author Title',
      label_institution: 'Author Institution',
      label_bio: 'Author Bio',
      label_image: 'Author Image',
      required_name: 'Please add an author name',
      required_title: 'Please add an author title',
      required_institution: 'Please add an author institution',
      required_bio: 'Please add an author bio',
      required_image: 'Please upload an image',
      view_image: 'View Image',
      error_upload_pre_save:
        'Something went wrong saving author, please try again',
    },
    dart: {
      company_name: 'Breakout Learning',
      dark_mode: 'Dark Mode',
      english: 'English',

      homepage: 'Homepage',
      slogan: 'Sign In and Begin your Journey',
      get_started: 'Get Started',
      go_route: 'Temporary Navigation',

      student_view: 'Student View',
      instructor_view: 'Instructor View',
      admin_view: 'Admin View',
      admin_dashboard: 'Admin Dashboard',
      view_users: 'View Users',
      assistants: 'Assistants',

      yes: 'Yes',
      no: 'No',
      pending: 'Pending',
      ok: 'Ok',
      na: '—',
      show_low_scores: 'Show Low Scores',
      hide_low_scores: 'Hide Low Scores',
      groups_colon: 'Groups:',
      all_group_average: 'All Group Average',
      video_not_readable_error_popup:
        "Breakout Learning can't access your camera. Please close all applications using your camera, or check your system settings.",
      video_not_allowed_error_popup:
        "Breakout Learning can't access your camera. Please ensure you've given this tab permission to your camera and microphone.",

      error: 'Error',
      error_popup: 'Error: {{errorMessage}}',
      warning_popup: 'Warning: {{errorMessage}}',
      alert_popup: 'Alert: {{errorMessage}}',
      invitation_accepted: 'Invitation Accepted',
      invitation_admin_denied: 'Invitation can not be consumed by admin',
      delete: 'Delete',
      cancel: 'Cancel',
      group_leader: 'Group Leader',
      group_leader_name: 'Group Leader: {{name}}',
      group_leader_promotion: 'Promote to Group Leader',

      users: 'Users',
      tas: 'TAs',
      instructors: 'Instructors',
      admins: 'Admins',
      tokens_granted: 'Tokens Granted',
      grant_tokens_dialog_sub:
        'Choose a token amount to grant to the selected user',
      grant_amount_tokens: 'Grant {{value}} Tokens',

      running_classes: 'Running Classes',
      slide: 'Slide',

      create_assistant_invitation: 'Create Assistant Invitation',

      create_author_invitation: 'Create Author Invitation',
      teachers_assistants_welcome:
        'Assistants are authorized to operate the platform on your behalf. They can create classes, add experiences, and invite students.',

      save_profile: 'Save Profile',

      library: 'Library',
      admin_library: 'Admin Library',
      upload_logo: 'Upload Logo',

      preview: 'Preview',
      publish: 'Publish',
      archive: 'Archive',
      publish_title: 'Are you sure you want to publish this experience?',
      publish_description:
        'This action will publish the experience to the explore page for professors to add to their classes',
      archive_title: 'Are you sure you want to archive this experience?',
      archive_description:
        'This action will prevent experience from showing up at the explore page for professors',
      case_details: 'Experience Details',
      slides: 'Slides',

      course_materials_description:
        'Course materials help you understand the core concepts of the experience and ready you to join the discussion.',
      interactive_element: 'Interactive Element',
      interactive_elements: 'Interactive Elements',
      rubrics: 'Rubrics',
      exhibits: 'Exhibits',
      active: 'Active',
      archived: 'Archived',
      hidden: 'Hidden',
      hide: 'Hide',
      show: 'Show',
      draft: 'Draft',
      case_study: 'Experience',
      case_title: 'Experience Title',
      case_title_invalid: 'experience title required',
      teaching_slides_url: 'Teaching Slides Url',
      teaching_slides_url_invalid: 'use google slides url from browser',
      case_subtitle: 'Experience Subtitle',
      case_subtitle_invalid: 'experience subtitle required',
      case_summary: 'Experience Summary',
      case_summary_invalid: 'experience summary required',
      case_objectives: 'Experience Objectives',
      case_objective_invalid: 'objective invalid',
      add_objective: 'Add Objective',
      add_new_item: 'Add New Item',
      no_objectives: 'No Objectives Added',
      objective: 'Objective',
      objectives_added: {
        token_number: '{{count}} Objectives Added', // many
        token_number_one: '{{count}} Objective Added', // one
      },
      disciplines: 'Disciplines',
      discipline: 'Discipline',
      no_disciplines: 'No Disciplines',
      industries: 'Industries',
      industry: 'Industry',
      no_industries: 'No Industries',
      price_student: 'Token(s)/Student',
      authors: 'Authors',
      author_image: 'Author Image',
      author_name: 'Author Name',
      author_title: 'Author Title',
      author_institution: 'Author Institution',
      author_bio: 'Author Bio',
      add_new_author: 'Add New Author',
      free: 'Free',
      price_student_invalid: 'price must be an integer',
      add_new_interactive_element: 'Add New Interactive Element',
      no_compatible_slides: 'No Compatible Slides',
      all_slides: 'All Slides',
      add_new_exhibit: 'Add New Exhibit',
      seconds: 'Seconds',
      no_slide_available: 'No slide available.',
      no_questions_available: 'No questions available',
      edit: 'Edit',
      select_instructor: 'Select Instructor',
      slide_deck_create_copy_dialog_title_waiting: 'Creating a new copy...',
      slide_deck_create_copy_dialog_subtitle_waiting:
        'Please do not close this page till the process is completed',
      slide_deck_create_copy_dialog_title_resolved: 'New Version Created',
      slide_deck_create_copy_dialog_subtitle_resolved:
        'A new duplicate for this version has been created and added to your library',
      slide_deck_deep_copy_dialog_button_title: 'Go to New Version',
      slide_deck_deep_copy_input_dialog_title: 'Create a copy',
      slide_deck_deep_copy_input_dialog_subtitle:
        'A new duplicate of this experience will be created and added to your library.',
      version_name: 'Version Name',
      version_invalid: 'Version name invalid',
      version_exists: 'Version exists',
      make_a_copy: 'Make a Copy',
      select_catalog: 'Select Catalog',
      slide_deck_hide_dialog_title:
        'This action will mark this experience as hidden',
      slide_deck_hide_dialog_subtitle:
        "Hidden experiences won't be visible by default. You can reverse this by choosing to re-publish the experience in the future.",
      slide_deck_publish_dialog_title:
        'This action will mark this experience as published',
      slide_deck_remove_feature_dialog_title:
        'This action will cause the experience to no longer be featured',
      slide_deck_remove_feature_dialog_subtitle:
        'Once an experience is no longer featured, it will no longer be visible on the login page.',
      select_to_add: 'Select {{value}} to add',
      enter_a_new: 'Enter a new {{value}}',

      slide_question_type: 'Question Type',
      slide_question_type_poll: 'Poll',
      slide_question_type_multiple_choice: 'Multiple Choice',
      slide_question_type_title: 'Slide Title',
      slide_question_type_custom_poll: 'Custom Poll',
      slide_question_invalid: 'question required',
      slide_question_sorting_answers_label: 'Items (Add in the right order)',
      slide_answer_invalid: 'answer may not be blank',
      group_slide_id_invalid: 'must have quiz slide or group quiz slide',

      slide_answers: 'Answers',
      select_slide: 'Select Slide',
      no_slide: 'No Slide',
      select_string: 'Select {{value}}',

      rubric_result_name: '{{name}}: {{result}}',
      finished_at: 'Finished At: {{finished_at}}',
      legend: 'Legend',
      blooms_taxonomy: "Bloom's Taxonomy",
      blooms_score: "Bloom's Score",
      bloom_0: 'Did Not Discuss',
      bloom_1: 'Remembering',
      bloom_2: 'Understanding',
      bloom_3: 'Applying',
      bloom_4: 'Analyzing',
      bloom_5: 'Evaluating',
      bloom_6: 'Creating',
      bloom_low: 'Low Understanding',
      bloom_medium: 'Medium Understanding',
      bloom_high: 'High Understanding',
      pass_fail: 'Pass/Fail',
      pass_fail_0: 'Did Not Discuss',
      pass_fail_1: 'Discussed',
      argument: 'Argument',
      justification: 'Justification',
      highlighted_justification: 'Highlighted Justification',
      hide_arguments: 'Hide Arguments',
      show_arguments: 'Show Arguments',

      search_students: 'Search Students',
      no_students_found: 'No students found',

      no_results: 'We did not find any results with these filters.',

      rubric_test: 'Rubric Test',
      rubric_tests: 'Rubric Tests',
      rubric_test_results: 'Rubric Test Results',
      slide_rubric_test_name_invalid: 'name required',
      slide_rubric_test_name: 'Name',
      slide_rubric_test_script_invalid: 'script required',
      slide_rubric_test_script: 'Script',
      slide_rubric_test_score_invalid: 'score required',
      slide_rubric_test_score: 'Score',
      add_new_rubric_test: 'Add New Rubric Test',
      add_to_rubric: 'Add to Rubric',
      run_test: 'Run Test',

      upload_exhibit: 'Upload Exhibit',
      slide_deck_exhibit: 'Exhibit',
      slide_exhibit_name_invalid: 'exhibit name required',
      slide_exhibit_name: 'Exhibit Name',
      slide_exhibit_description_invalid: 'exhibit description required',
      slide_exhibit_description: 'Exhibit Description',

      material_name_invalid: 'name required',
      material_name: 'Name',
      material_type: 'Type',
      material_description_invalid: 'description required',
      material_description: 'Description',
      material_link_invalid: 'link required',
      material_link: 'Link',

      catalog_title: 'Catalog Title',
      exit_portal: 'Exit to Portal',
      catalog_title_hint: 'Enter a title for this catalog',
      catalog_description: 'Catalog Description',
      catalog_description_hint: 'Enter a description for this catalog',
      catalog_title_required: 'catalog title required',
      catalog_description_required: 'catalog description required',
      catalog_details: 'Catalog Details',

      delete_catalog: 'Delete Catalog',
      delete_catalog_protected: 'Catalog is protected and can not be deleted',
      save_edits: 'Save Edits',
      save: 'Save',
      details: 'Details',
      invite_users: 'Invite Users',
      version_label: 'Version {{version}}',
      version: 'Version',
      versions: 'Versions',

      published: 'Published',

      next_question: 'Next Question',
      advance_to_next_slide: 'Advance to the next slide',
      advance_to_next_question: 'Advance to the next question',
      when_ready: 'When your group is ready!',
      waiting_for_next_slide:
        'Waiting for the group leader to advance to the next slide',
      end_timer: 'End Timer',
      time_is_up: 'Time is up',
      time_left: 'Time left',

      reconnect: 'Reconnect',
      reconnect_prompt: 'This will force a reconnection',
      reconnect_success: 'Reconnection was successful.',
      hide_captions: 'Hide Captions',
      show_captions: 'Show Captions',
      subscribe: 'Subscribe',
      unsubscribe: 'Unsubscribe',
      subscribe_menu: 'Subscribe Menu',
      discussion_index: 'Discussion {{index}} of {{count}}',
      feedback: 'Feedback',
      feedback_title: 'Share your feedback with your professor.',
      processing_session: 'Processing Session Data... Please Wait',
      exhibit_grid: 'Exhibit Grid',
      hear_thoughts:
        "While you wait, we'd love to hear your thoughts on Breakout.",
      share_feedback: 'Share Feedback',
      please_rate: 'Please rate your overall experience.',
      other_feedback: 'Any other feedback?',
      feedback_placeholder: 'Please share your feedback here.',
      thank_you_for_feedback: 'Thank you for sharing your feedback!',
      feedback_submitted: 'Feedback Submitted',
      great_session: 'You did great on this session. Thank you!',
      engagement_scores: 'Engagement Scores',
      engagement_scores_hint:
        'Engagement levels for all participants in session',

      no_data_available_yet: 'No data available yet',
      instructor_question: 'Highlighted Question for Professor',
      rubric_arguments: 'Highlighted Comment in Session',
      keep_watching: 'Keep Watching to Progress',
      keep_watching_reason:
        'Please ensure you watch the entire video to advance to the next section. Your progress depends on it!',
      play_video: 'Play Video',
      join_room_audio: 'Welcome back!',
      join_room_audio_explanation: 'Click the button below to join the meeting',
      join_room_button: 'Join Experience',

      submit: 'Submit',
      next: 'Next',
      previous: 'Previous',
      loading_slide: 'Loading slide…',
      stop_video: 'Stop Video',
      stop_video_question:
        'A video is currently playing. Do you want to stop it? It cannot be restarted.',
      take_poll:
        'First, take the poll below, then advance to the next slide to begin your discussion.',
      waiting_poll: 'Waiting for your peers to complete the poll',
      take_quiz: 'Please take the following quiz',
      take_group_quiz: {
        token_number:
          'Group Leader, please answer these questions with input from your group.', // many
        token_number_one:
          'Group Leader, please answer the following question with input from your group.', // one
      },
      waiting_quiz: 'Waiting for your peers to complete the quiz',
      confirm_exhibit_action_title:
        'Are you sure your want to share this exhibit?',
      confirm_exhibit_action:
        'This action will present this exhibit file to all the members in this room.',
      stop_broadcasting: 'Stop Sharing',
      broadcast: 'Share',
      view: 'View',
      view_full_screen: 'View Full Screen',
      observe: 'Observe',
      subscribe_permissions_title: 'Allow subscription',
      subscribe_permissions_content:
        'Allow all participants to subscribe tracks published by local participant?',

      peers: 'Peers',
      chat: 'Chat',
      agenda: 'Agenda',
      slide_mins: '{{minutes}} Mins',
      time_left_in_session: 'Time Left in Session',
      slide_minutes: '{{minutes}} Minutes',
      speaking: 'Speaking:',
      transcript_name: '{{name}}: ',

      overshare: "We value your input, but let's hear others too.",
      settings: 'Settings',
      speakers: 'Speakers',
      microphone: 'Microphone',
      completed_quiz: 'Completed Quiz',
      assert_group_leadership: 'Assert Group Leadership',
      group_leader_description:
        'Group leader is the participant who is responsible for moving between slides.',
      assume_leadership_confirmation:
        'Are you sure you want to assume the leadership role?',
      assume_leadership_confirmation_description:
        'You will be required to move between slides and follow the instructions given to you by the system throughout the experience.',

      login: 'Login',
      logout: 'Logout',
      log_out: 'Log Out',
      user_profile: 'User Profile',
      saving_user_profile: 'Saving User Profile…',
      first_name_invalid: 'first name required',
      first_name: 'First Name',
      last_name_invalid: 'last name required',
      last_name: 'Last Name',
      email_address_invalid: 'email address required',
      personal_details: 'Personal Details',

      login_email: 'Enter your email',
      login_password: 'Enter your password',
      invalid_email: 'Invalid email',
      invalid_password: 'Invalid password',
      continue_label: 'Continue',

      verify_email: 'Email Verification',
      check_your_email: 'Check your inbox',
      email_warning_prefix: 'We just sent an email to you at ',
      email_warning_suffix:
        ', it contains a link that will sign you in super quick!',
      back_to_login: 'Back to login',

      max_filter_applied: 'Max Filters Applied',
      filter_name_emails: 'Filter by Name/Email',
      email_address: 'Email Address',
      no_users_found: 'No users found',

      library_empty:
        'Assignments will appear here when you are invited by your instructor',
      library_empty_help:
        'If you were invited and the assignments do not appear here, your invitation is probably expired. Your instructor or TA can supply you with another invitation. Alternately, you can send us an email at:',
      support_email: 'support@breakoutlearning.com',

      just_class: 'Class',
      classes: 'Classes',
      classes_impersonated: 'Classes ({{name}})',
      students: 'Students',
      n_students: '{{count}} Students',

      show_completed: 'Show Completed',
      hide_completed: 'Hide Completed',
      show_archived: 'Show Archived',
      hide_archived: 'Hide Archived',
      show_hidden: 'Show Hidden',
      hide_hidden: 'Hide Hidden',
      cases: 'Experiences',
      add_class_for: 'Add Class for {{name}}',

      enroll: {
        token_number: 'Enroll ({{count}} Tokens)', // many
        token_number_one: 'Enroll ({{count}} Token)', // one
      },

      print_in_tokens: {
        token_number: 'Enroll ({{count}} Tokens)', // many
        token_number_one: 'Enroll ({{count}} Token)', // one
      },

      deadline: 'Deadline: {{date}}',
      view_session: 'View Session',
      start_session_confirmation:
        'Are you sure you want to start the session?\n\nYou will be responsible for controlling the slides throughout the session.\n\nYou should only do this when the group is expecting to meet because each meeting can only run once.',

      group_locked: 'Group Locked',
      group_locked_description:
        'This group is locked. The session may be complete or the group is full.\nPlease join another group.',
      join_session_description:
        'Please join the experience on time to complete this assignment.',
      experience: 'Experience',
      completed: 'Completed',
      prework: 'Pre-Work',
      prework_description: 'Complete the Pre-Work by:',
      complete_prework: 'Complete Pre-Work',
      complete_prework_description:
        'Please complete the Pre-Work for this assignment.',
      complete_prework_take_quiz: 'Complete Pre-Work and take quiz',
      complete_prework_take_quiz_description:
        'Please complete the Pre-Work for this assignment, then take the quiz.',
      summary: 'Summary',
      breakout_learning_tokens: 'Breakout Learning Tokens',
      test_audio_video: 'Test Audio/Video',
      setup_camera_and_microphone: 'Setup your camera and microphone',
      setup_camera_and_microphone_description:
        'Breakout Learning is a Zoom-like experience that requires joining video call sessions where your camera and microphone should be on.',
      setup_camera_and_microphone_mobile_warning:
        'You will need to set up your camera and microphone on your desktop',
      enable_animations: 'Enable Animations',
      disable_animations: 'Disable Animations',

      single: 'Single',
      best_choice_for: 'Best Choice for ',
      single_case_experience: 'single experience',
      single_class: 'single class ',
      just_with: ' with ',
      triplet: 'Triplet',
      multiple_cases: 'multiple experiences ',
      in_syllabus: 'in syllabus',
      bulk: 'Bulk',
      multiple_classes: 'multiple classes ',
      most_popular: 'Most Popular',

      total_price: 'Total Price: ${{price}}',
      enroll_in_case: 'Enroll in Experience',
      enroll_outcome: {
        token_number:
          'Breakout works with a Token based system. You need to buy {{count}} Tokens to access this experience.', // many
        token_number_one:
          'Breakout Learning works with a Token based system. You need to buy {{count}} Token to access this experience.', // one
        token_number_zero:
          'Breakout Learning works with a Token based system. You have enough tokens to access this experience.', // one
      },
      loading_stripe: 'Loading Stripe…',
      please_wait: 'Please wait…',
      no_group_leader: 'Group has no leader.',

      group_name_exists: 'Group name already exists.',

      leave_group: 'Leave Group',
      schedule_meeting: 'Schedule Meeting',

      scheduled: 'Scheduled',
      not: 'Not',
      date: 'Date',
      time: 'Time',
      group_details: 'Group Details',
      view_case_study: 'View Experience',

      pre_session_quiz: 'Pre-session Quiz',
      tile_number: '# {{number}}',
      talk_time: 'Talk Time (m): {{timeInMinutes}}',
      camera_off_time: 'Camera Off Time (m): {{timeInMinutes}}',

      welcome_to_breakout: 'Welcome to Breakout Learning',
      welcome_to_breakout_details:
        'Start your experience by creating your first class and adding assignments to it',
      class_name: 'Class Name',
      class_name_invalid: 'Class name is invalid',
      section_name: 'Section Name',
      section_name_invalid: 'Section name is invalid',

      students_view: 'Students View ({{count}})',
      cases_view: 'Experiences View ({{count}})',
      students_count: 'Students ({{count}})',
      cases_count: 'Experiences ({{count}})',
      invite_student_tooltip:
        'Create an invitation link to share with your students',
      invitation_created: 'Invitation list copied to your clipboard. {{link}}',
      invitation_create_error: 'Error creating invitation.',
      one_time_link: 'Create One Time Link',
      one_month_link: 'Create One Month Link',
      set_section_invoiced: 'Make Section Invoiced',
      set_section_invoiced_confirmation:
        'Are you sure you want to set this section as invoiced?',
      section_invoiced: 'Section Is Invoiced',
      invite_students: 'Invite Students',
      no_exhibits: 'No Exhibits Available',
      no_exhibits_sub:
        'Exhibits will appear here as you progress through the experience.',
      discussion_mode: 'Discussion Mode',
      discussion_mode_sub:
        'Peer video cards are now available on the main panel for discussion.',

      class_invitation: 'Class Invitation',
      class_joined_successfully: 'Class Joined Successfully!',
      class_joined_successfully_description:
        'New assignments added by your professor will now be available in your library.',
      enrolled: 'Enrolled',
      go_to_library: 'Go to Library',

      results: 'Results',

      group: 'Group',

      remove_user_from_group:
        'Are you sure you want to remove this student from group?',
      remove_from_group: 'Remove from Group',
      student_name: 'Student Name',
      add_member: 'Add Member',
      add_student_to_group: 'Add Student to Group',
      search_by_name: 'Search by Name',
      assigned_to_multiple_groups: 'Assigned to Multiple Groups',
      assigned_to_group: 'Assigned to: {{groupName}}',
      are_you_sure_you_want_to_add_this_student_to_the_group:
        'Are you sure you want to add this student to the group?',
      action: 'Action',

      av_test: 'AV Test',
      config: 'Config',
      home: 'Home',
      welcome: 'Welcome',
      loading: 'Loading',
      meeting: 'Meeting',
      video: 'Video',
      audio: 'Audio',
      purchase: 'Purchase',
      instructor: 'Instructor',

      library_state_live: 'Live',
      library_state_ready_to_start: 'Start Now',
      library_state_scheduled: 'Scheduled',
      library_state_pending: 'Pending',
      library_state_expired: 'Expired',
      library_state_schedule_now: 'Schedule Now',
      library_state_completed: 'Completed',
      library_state_invited: 'Invited',
      room_state_status_abandoned: 'Abandoned',
      room_state_status_must_schedule: 'Not Scheduled',
      section_state_not_started: 'Not Started',
      section_state_completed: 'Completed',
      in_progress: 'In Progress',

      upload_image: 'Upload Image',
      upload_audio: 'Upload Audio',

      google_podcasts: 'Google Podcasts',
      apple_podcasts: 'Apple Podcasts',
      podcast_addict: 'Podcast Addict',
      pocket_casts: 'Pocket Casts',
      downcast: 'Downcast',
      overcast: 'Overcast',
      copy_rss_feed: 'Copy RSS Feed',
      podcast_links: 'Podcast Links',
      podcast_links_sub:
        'Listen to your private podcast using one of these apps, or copy the RSS Feed into an app of your choice.',
      podcasts: 'Podcasts',
      image_aspect_ratio_error: 'Image must be {{hor}}:{{ver}} aspect ratio',
      image_dimensions_error_range:
        'Image dimensions must be between {{hor_min}}x{{ver_min}} and {{hor_max}}x{{ver_max}} pixels',
      image_maximum_dimension_error:
        'Image height and width must not exceed {{pixels}}px',

      warning_exclamation: 'Warning!',
      browser_unsupported_message:
        'Breakout Learning is not officially supported on this browser',
      browser_chrome_recommendation:
        'You may experience audio and video issues. Using Google Chrome is recommended for a stable experience.',
      proceed_anyways: 'Proceed Anyways',
      proceed: 'Proceed',

      url: 'URL',
      pdf: 'PDF',
      mp3: 'MP3',
      jpg: 'JPG',
      mp4: 'MP4',
      webm: 'WEBM',
      upload_pdf: 'Upload PDF',
      viewable_by: 'Viewable By',
      public: 'Public',
      invalid_material_type: 'type is required',
      invalid_material_permissions: 'viewable by is required',
      pre_session: 'Pre-session',
      post_session: 'Post-session',
      material_work_type_label: 'Pre or post-session material?',
      material_work_type_tooltip:
        '"Pre-session" or "Post-session" indicates whether the student is expected to have access to the material before or after the meeting.\nA post-session material will become visible only after the session is over, whereas a pre-session material will appear in the list of Pre-Work materials.',
      neither: 'Neither',
      upload_video: 'Upload Video',
      delete_file: 'Are you sure you want to delete this file?',
      delete_author: 'Are you sure you want to delete this author record?',

      featured_slide_deck_image: 'Featured (Hero) Image',
      slide_deck_featured: 'Slide Deck Featured',
      slide_deck_featured_tooltip:
        'Only Slide Decks with a name, logo, and a  large featured image (in materials) can be featured. Once these elements are provided, the Slide Deck will be showcased on the login page.',
      back: 'Back',

      av_test_continue: 'Looks Good! Continue',
      av_test_skip: 'Skip Walkthrough',
      av_test_next: 'Next',
      av_test_next_login_desktop: 'Next time you login on your desktop',

      stream_pause: 'Pause Everyone',
      stream_muted_notification: 'When the video is playing, you are muted.',
    },
  },
}

// eslint-disable-next-line import/no-default-export
export default language
