import { TwoColumnSubRouter } from 'components/core/TwoColumnSubRouter'
import type { QueryParams, Store } from 'mobx-router'
import { Route } from 'mobx-router'
import {
  AdminCatalogsLoader,
  AdminDashboardLoader,
  AdminLibraryLoader,
  AdminUsersLoader,
} from 'pages/admin/AdminLoader'
import { AvTestEntrypoint } from 'pages/av-test/AvTestEntrypoint'
import { HomePage } from 'pages/home/HomePage'
import { InstructorClassesEntryPage } from 'pages/instructor/InstructorClassesEntryPage'
import { InstructorLibraryEntryPage } from 'pages/instructor/InstructorLibraryEntryPage'
import { InstructorAssistantsPage } from 'pages/instructor/assistants/InstructorAssistantsPage'
import { InvitationTokenPage } from 'pages/invitation/InvitationTokenPage'
import { LoginWithPassword } from 'pages/login/LoginWithPassword'
import { LogoutPage } from 'pages/login/LogoutPage'
import { MeetingEntrypoint } from 'pages/meeting/MeetingEntrypoint'
import { OnboardingPage } from 'pages/onboarding/OnboardingPage'
import { ProfilePage } from 'pages/profile/ProfilePage'
import { PodcastsPage } from 'pages/student/podcasts/PodcastsPage'
import { Suspense, lazy } from 'react'
import { Environment, environment } from './environment'

export type RouteType = Route<
  Store,
  Record<string, string | number | boolean | undefined> | undefined,
  QueryParams
>

// Public routes are accessible without authentication
export class PublicRoute extends Route<
  Store,
  Record<string, string | number | boolean | undefined> | undefined,
  QueryParams
> {}

const twoColumn = <TwoColumnSubRouter />
export const routes: Record<string, RouteType> = {}
const subroutes: Record<string, JSX.Element> = {}

routes.loginWithPassword = new PublicRoute({
  path: '/login-with-password',
  component: <LoginWithPassword />,
  title: 'loginWithPassword',
})

routes.avTest = new Route({
  path: '/av-test',
  component: <AvTestEntrypoint />,
  title: 'avTest',
})

routes.invitationToken = new Route({
  path: '/invitation/:token',
  component: <InvitationTokenPage />,
  title: 'invitationToken',
})

routes.podcasts = new Route({
  path: '/podcast',
  component: <PodcastsPage />,
  title: 'podcasts',
})

routes.logout = new Route({
  path: '/logout',
  component: <LogoutPage />,
  title: 'logout',
})

routes.meeting = new Route({
  path: '/meeting/:roomId',
  component: <MeetingEntrypoint />,
  title: 'meeting',
})

routes.onboarding = new Route({
  path: '/onboarding',
  component: <OnboardingPage />,
  title: 'onboarding',
})

function addTwoColumnRoute(name: string, path: string, component: JSX.Element) {
  const route = new Route({
    path: path,
    component: twoColumn,
    title: name,
  })
  routes[name] = route
  subroutes[name] = component
}

// The HomePage controller handles home pages, but it also needs to handle
// the student experience - we need this because we need to keep a single component
// for transitions - if you switch between HomePage and StudentLibrary, the
// mount point changes and the library gets disposed and re-created, which is messing
// with cubits and transitions.
const homePageOrStudentLibrary = <HomePage />
addTwoColumnRoute('home', '/', homePageOrStudentLibrary)

addTwoColumnRoute(
  'assignmentWithRoom',
  '/assignment/:sectionId/:assignmentId/:roomStateId',
  homePageOrStudentLibrary
)
addTwoColumnRoute(
  'assignment',
  '/assignment/:sectionId/:assignmentId',
  homePageOrStudentLibrary
)

addTwoColumnRoute(
  'instructorLibrary',
  '/instructor/library',
  <InstructorLibraryEntryPage />
)
addTwoColumnRoute(
  'instructorSlideDeck',
  '/instructor/slide-deck/:slideDeckId',
  <InstructorLibraryEntryPage />
)

addTwoColumnRoute(
  'instructorStudentView',
  '/instructor/home',
  homePageOrStudentLibrary
)
addTwoColumnRoute(
  'instructorClass',
  '/instructor/classes/:id',
  <InstructorClassesEntryPage />
)
addTwoColumnRoute(
  'instructorClassAssignment',
  '/instructor/classes/:id/:assignmentId',
  <InstructorClassesEntryPage />
)
addTwoColumnRoute(
  'instructorClasses',
  '/instructor/classes',
  <InstructorClassesEntryPage />
)
addTwoColumnRoute(
  'instructorAssistants',
  '/instructor/assistants',
  <InstructorAssistantsPage />
)

addTwoColumnRoute(
  'adminDashboard',
  '/admin/dashboard',
  <AdminDashboardLoader />
)

addTwoColumnRoute('adminLibrary', '/admin/library', <AdminLibraryLoader />)
addTwoColumnRoute('adminCatalogs', '/admin/catalogs', <AdminCatalogsLoader />)
addTwoColumnRoute(
  'adminCatalog',
  '/admin/catalogs/:catalogId',
  <AdminCatalogsLoader />
)

addTwoColumnRoute(
  'adminSlideDeck',
  '/admin/slide-deck/:slideDeckId',
  <AdminLibraryLoader />
)

addTwoColumnRoute('adminUsers', '/admin/users', <AdminUsersLoader />)

addTwoColumnRoute('profile', '/profile', <ProfilePage />)

if (environment() !== Environment.production) {
  const DesignSystemEntrypoint = lazy(
    () => import('pages/design-system/DesignSystemPage')
  )
  const designSystemMount = (
    <Suspense fallback={<div>Loading...</div>}>
      <DesignSystemEntrypoint />
    </Suspense>
  )

  addTwoColumnRoute('designSystem', '/design-system', designSystemMount)
}

export function getRouteName(route: RouteType | undefined) {
  if (!route) return 'home'
  return route.title
}

export function getSubRouteComponent(routeName: string | undefined) {
  return subroutes[routeName || 'home']
}
