import PodcastsIcon from 'assets/podcasts.svg'
import { observer } from 'mobx-react-lite'
import { LibraryObjectList } from './LibraryObjectList'
import { useRootStore } from 'hooks/rootStore'
import { useTranslation } from 'react-i18next'
import {
  Actions,
  Contents,
  Header,
  Headline,
  MainPane,
} from 'pages/layout/TwoColumn'
import { ShowCompletedButton } from 'components/ShowCompletedButton'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { useCubitBuilder } from 'hooks/cubits'
import { useRepository } from 'hooks/auth'
import { StudentLibraryCubit } from '@breakoutlearning/firebase-repository/cubits/StudentLibraryCubit'
import { HelpButton } from './HelpButton'

function PodcastsButton() {
  const rootStore = useRootStore()
  return (
    <BreakoutButton
      size="large"
      onClick={() => rootStore.navigateTo('podcasts')}
      icon={<img src={PodcastsIcon} width={14} className="inline" />}
    >
      Podcasts
    </BreakoutButton>
  )
}

const LibraryActions = observer(function LibraryActions({
  cubit,
}: {
  cubit: StudentLibraryCubit
}) {
  return (
    <Actions>
      <PodcastsButton />
      <ShowCompletedButton
        showCompleted={cubit.showCompleted}
        onClick={() => cubit.toggleShowCompleted()}
      />
    </Actions>
  )
})

export function StudentLibraryPage() {
  const { t } = useTranslation()
  const repository = useRepository()
  const cubit = useCubitBuilder(() => {
    return new StudentLibraryCubit(repository)
  }, [repository])

  return (
    <MainPane testId="student-library">
      <Header>
        <Headline>{t('library.headline')}</Headline>
        <LibraryActions cubit={cubit} />
      </Header>
      <Contents className="h-full md:overflow-auto">
        <LibraryObjectList cubit={cubit} />
        <HelpButton className="!absolute bottom-10 right-12 z-10" />
      </Contents>
    </MainPane>
  )
}
