import type { InstructorSlideDeckCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorSlideDeckCubit'
import { Section } from '@breakoutlearning/firebase-repository/models/Section'
import { AssignmentGroupingType } from '@breakoutlearning/firebase-repository/models/SectionAssignment'
import {
  AssignmentType,
  SectionState,
} from '@breakoutlearning/firebase-repository/types'
import { Spinner } from 'components/Spinner'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutSelect } from 'components/design-system/BreakoutSelect'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Dialog, InlineDialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { Save } from 'components/icons/Save'
import { useRepository } from 'hooks/auth'
import { useBreakoutUser } from 'hooks/profile'
import { useRootStore } from 'hooks/rootStore'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { BreakoutDateTimeInput } from 'components/design-system/BreakoutDateTimeInput'
import { dateSchema } from 'util/schema-date'

const getSchema = (
  t: ReturnType<typeof useTranslation>['t'],
  minExpiration: DateTime,
  maxExpiration: DateTime
) =>
  z.object({
    assignmentType: z
      .preprocess((val) => {
        return Number(val)
      }, z.nativeEnum(AssignmentType))
      .default(AssignmentType.studentLed),
    sectionId: z.string({ message: t('instructor_library.section_missing') }),
    catalogId: z.string(),
    expiresAt: dateSchema({
      required: t('instructor_library.deadline_required'),
      min: {
        date: minExpiration,
        message: t('instructor_library.deadline_must_be_future'),
      },
      max: {
        date: maxExpiration,
        message: t('instructor_library.deadline_too_far_out'),
      },
    }),
    groupingType: z.preprocess((val) => {
      return Number(val)
    }, z.nativeEnum(AssignmentGroupingType)),
    groupingSize: z.coerce
      .number()
      .min(2, { message: t('instructor_library.grouping_size_invalid') })
      .max(6, { message: t('instructor_library.grouping_size_invalid') })
      .optional(),
  })
type FormValues = z.infer<ReturnType<typeof getSchema>>

export const AssignClassDialog = observer(function AddSectionDialog({
  cubit,
  onCreate,
}: {
  cubit: InstructorSlideDeckCubit
  onCreate?: () => void
}) {
  const store = useRootStore()
  const user = useBreakoutUser()

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const [initialDate, minScheduleDate, maxScheduleDate] = useMemo(() => {
    const now = DateTime.now()

    // set min date to start of tomorrow
    const min = now.plus({ days: 1 }).startOf('day')
    const max = now.plus({ days: 365 })

    /// create a DateTime that is 8 days from at midnight
    const initial = now.plus({ days: 7 }).endOf('day')
    return [initial, min, max]
  }, [])

  const { t } = useTranslation()
  const rootStore = useRootStore()

  const repository = useRepository()
  const queryParams = store.router.queryParams

  const sections = useMemo(
    () => Section.emptyCollection(repository),
    [repository]
  )

  // if section id is present in the query params, only show that section
  const sectionIdParam = useMemo(
    () => queryParams?.sectionId?.toString(),
    [queryParams]
  )

  const schema = useMemo(
    () => getSchema(t, minScheduleDate, maxScheduleDate),
    [maxScheduleDate, minScheduleDate, t]
  )

  const {
    control,
    setValue,
    trigger,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      catalogId: queryParams?.catalogId?.toString() || undefined,
      assignmentType: AssignmentType.studentLed,
      groupingType: AssignmentGroupingType.manual,
      sectionId: sectionIdParam,
      expiresAt: initialDate,
      groupingSize: 4,
    },
  })

  const groupingType = useWatch({ control, name: 'groupingType' })
  const expiresAt = useWatch({ control, name: 'expiresAt' })

  const onSubmit = useCallback(
    async (data: FormValues) => {
      const assignmentId = await cubit.createSectionAssignment({
        slideDeckId: cubit.slideDeckId,
        ...data,
      })

      if (assignmentId) {
        store.navigateTo('instructorClassAssignment', {
          id: data.sectionId,
          assignmentId: assignmentId,
        })
      }
      onCreate?.()
    },
    [cubit, onCreate, store]
  )

  const role = user.role || ''
  const catalogId = queryParams?.catalogId?.toString() || ''

  useEffect(() => {
    cubit
      .fetchSections(catalogId, role, rootStore.impersonatedUserId)
      .then((newSections) => {
        const sectionsSortedAndFiltered = cubit.sortSections(
          newSections.filter((s) => {
            const validID = sectionIdParam ? s.id === sectionIdParam : true
            // don't display completed sections
            return s.data.sectionState !== SectionState.completed && validID
          })
        )
        sections.replaceModels(sectionsSortedAndFiltered)

        //if no default value set the first section after fetch
        if (sectionIdParam || !sectionsSortedAndFiltered.length) return
        setValue('sectionId', sectionsSortedAndFiltered[0].id)
      })
  }, [
    cubit,
    sections,
    role,
    catalogId,
    sectionIdParam,
    setValue,
    rootStore.impersonatedUserId,
  ])

  return (
    <Dialog size="md">
      <DialogCloseButton />
      <div className="text-headline-large mb-6">
        {t('instructor_library.experience_settings')}
      </div>
      <form
        className="flex flex-col space-y-3"
        onSubmit={(e) => e.preventDefault()}
      >
        {user?.isAdmin && (
          // if user is an admin show the assignmentType select
          <Controller
            control={control}
            name="assignmentType"
            render={({ field, fieldState }) => (
              <BreakoutSelect
                {...field}
                error={fieldState.error}
                required
                kind="secondary"
                label={t('instructor_library.assignment_type')}
                name="assignmentType"
                options={[
                  {
                    value: AssignmentType.studentLed,
                    label: t('instructor_library.assignment_type_student_led'),
                  },
                  {
                    value: AssignmentType.instructorLed,
                    label: t(
                      'instructor_library.assignment_type_instructor_led'
                    ),
                  },
                ]}
              />
            )}
          />
        )}

        {sections.isLoading && <Spinner className="mx-auto" />}
        {sections.isLoaded && (
          <Controller
            control={control}
            name="sectionId"
            render={({ field, fieldState }) => (
              <BreakoutSelect
                {...field}
                testId="section-select"
                error={fieldState.error}
                required
                kind="secondary"
                label={t('instructor_library.select_class')}
                name="sectionId"
                placeholder={
                  !sections.length
                    ? t('instructor_library.no_classes_found')
                    : t('instructor_library.select_class')
                }
                options={sections.models.map((section) => ({
                  value: section.id,
                  label: `${section.data.className}: ${section.data.sectionName}`,
                }))}
              />
            )}
          />
        )}

        <Controller
          control={control}
          name="expiresAt"
          render={({ field, fieldState }) => {
            return (
              <BreakoutDateTimeInput
                {...field}
                error={fieldState.error}
                data-testid="room-state-form-date"
                type="datetime-local"
                label={t('instructor_assignment.assignment_deadline')}
                required
                kind="secondary"
                id="meeting-time"
                name="scheduledAt"
                hideNowButton
                min={minScheduleDate}
                max={maxScheduleDate}
                value={field.value}
                onChange={field.onChange}
              />
            )
          }}
        />
        <Controller
          control={control}
          name="groupingType"
          render={({ field }) => (
            <BreakoutSelect
              {...field}
              label={t('instructor_library.student_grouping')}
              name="groupingType"
              kind="secondary"
              onChange={(value) => {
                field.onChange(value)
                if (value === AssignmentGroupingType.manual) {
                  setValue('groupingSize', undefined)
                } else {
                  setValue('groupingSize', 4)
                }
              }}
              options={[
                {
                  value: AssignmentGroupingType.manual,
                  label: t('instructor_library.students_self_grouping'),
                },
                {
                  value: AssignmentGroupingType.automaticRandom,
                  label: t('instructor_library.automatic_randomized_grouping'),
                },
              ]}
            />
          )}
        />

        {groupingType === AssignmentGroupingType.automaticRandom && (
          <Controller
            control={control}
            name="groupingSize"
            render={({ field, fieldState }) => (
              <BreakoutTextInput
                {...field}
                kind="secondary"
                error={fieldState.error}
                type="number"
                label={t('instructor_library.desired_group_size')}
                name="groupingSize"
                min="2"
                max="6"
              />
            )}
          />
        )}

        <div className="mt-4">
          <BreakoutButton
            size="large"
            type="button"
            className="mt-2"
            onClick={async (e) => {
              e.preventDefault()

              const isValid = await trigger()
              if (!isValid) return
              setShowConfirmDialog(true)
            }}
            data-testid="assign-class-button"
            icon={<Save size="18" />}
            fullWidth
            loading={isSubmitting}
          >
            {t('instructor_library.assign_to_class')}
          </BreakoutButton>
          <ConfirmDialog
            showConfirmDialog={showConfirmDialog}
            setShowConfirmDialog={setShowConfirmDialog}
            groupingType={groupingType}
            isSaving={isSubmitting}
            expiresAt={expiresAt?.toString()}
            onSubmit={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </Dialog>
  )
})

function ConfirmDialog({
  showConfirmDialog,
  setShowConfirmDialog,
  groupingType,
  isSaving,
  expiresAt,
  onSubmit,
}: {
  showConfirmDialog: boolean
  groupingType: AssignmentGroupingType
  isSaving: boolean
  expiresAt: string
  setShowConfirmDialog: (show: boolean) => void
  onSubmit: () => Promise<void>
}) {
  const { t } = useTranslation()

  const groupingDate = expiresAt
    ? DateTime.fromISO(expiresAt).minus({
        milliseconds:
          DateTime.fromISO(expiresAt).diffNow('milliseconds').milliseconds *
          0.2,
      })
    : undefined

  return (
    <InlineDialog
      open={showConfirmDialog}
      onDismiss={() => setShowConfirmDialog(false)}
      size="xs"
      className="!bg-core-tertiary"
    >
      <DialogCloseButton
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setShowConfirmDialog(false)
          return false
        }}
      />
      {groupingType === AssignmentGroupingType.automaticRandom ? (
        <div className="mb-10 mt-5">
          <div className="text-headline-large mb-5">
            {t('instructor_library.automatic_grouping')}
          </div>
          <div className="text-body-medium mb-5">
            {t('instructor_library.confirm_auto_grouping1')}
            <br />
            {groupingDate ? (
              <strong>{groupingDate.toFormat('MMM dd, yyyy (hh:mm a)')}</strong>
            ) : null}
          </div>
          <div className="text-body-medium mb-5">
            {t('instructor_library.confirm_auto_grouping2')}
          </div>
          <div>
            <BreakoutButton
              size="large"
              fullWidth
              onClick={onSubmit}
              loading={isSaving}
              data-testid="assign-class-confirm-auto"
            >
              {t('instructor.confirm')}
            </BreakoutButton>
          </div>
        </div>
      ) : (
        <div className="mb-10 mt-5">
          <div className="text-headline-large mb-5">
            {t('instructor_library.students_self_grouping')}
          </div>
          <div className="text-body-medium mb-5">
            {t('instructor_library.confirm_self_grouping1')}
          </div>
          <div className="text-body-medium mb-5">
            {t('instructor_library.confirm_self_grouping2')}
          </div>
          <div>
            <BreakoutButton
              size="large"
              fullWidth
              loading={isSaving}
              data-testid="assign-class-confirm"
              onClick={onSubmit}
            >
              {t('instructor.confirm')}
            </BreakoutButton>
          </div>
        </div>
      )}
    </InlineDialog>
  )
}
