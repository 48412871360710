import { Dialog } from 'components/dialogs/Dialog'
import { observer } from 'mobx-react-lite'
import { SummaryDecoration } from 'pages/meeting/MeetingPanelMain/MeetingResultsSlideView'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import type { FirestoreRoomStateSummary } from '@breakoutlearning/firebase-repository/types'
import { LazyMarkdown } from 'components/LazyMarkdown'
import { useEffect } from 'react'
import { useRepository } from 'hooks/auth'

export const SummaryDialog = observer(function SummaryDialog({
  summary,
  roomStateName,
}: {
  summary: FirestoreRoomStateSummary
  roomStateName: string
}) {
  const repository = useRepository()
  useEffect(() => {
    repository.logEvent('room_view_summary')
  }, [repository])

  return (
    <Dialog
      size="lg"
      className="flex flex-col justify-start overflow-hidden bg-secondary"
    >
      <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
      <div className="h-full overflow-auto">
        <SummaryDecoration
          summaryUpdatedAt={summary.updatedAt}
          roomStateName={roomStateName}
        >
          <div className="react-markdown mt-5">
            <LazyMarkdown>{summary.summary}</LazyMarkdown>
          </div>
        </SummaryDecoration>
      </div>
    </Dialog>
  )
})
