export function startFirestoreDocTracker() {
  const originalFetch = window.fetch
  let counter = 0
  let timeout: NodeJS.Timeout | undefined
  window.fetch = (...args) => {
    return (async (args) => {
      const result = await originalFetch(...args)

      const url = result.url
      if (!url.includes('RID=rpc')) return result

      // console.log(result.url)
      const wrapped = new Response(
        new ReadableStream({
          async pull(controller) {
            const reader = result.body?.getReader()
            if (!reader) {
              controller.close()
              return
            }

            let work = true
            while (work) {
              const { done, value } = await reader.read()
              controller.enqueue(value)

              const string = new TextDecoder().decode(value)
              const documentChanges = string.match(/"documentChange"/g)
              if (documentChanges) {
                counter += documentChanges.length
                if (timeout) clearTimeout(timeout)
                timeout = setTimeout(() => {
                  console.log(`Got ${counter} documents from firestore`)
                  counter = 0
                }, 1000)
              }
              if (done) work = false
            }
          },
        })
      )

      return wrapped
    })(args)
  }
}
