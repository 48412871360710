import type { StudentAssignmentCubit } from '@breakoutlearning/firebase-repository/cubits/StudentAssignmentCubit'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Dialog } from 'components/dialogs/Dialog'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { DateTime } from 'luxon'
import { autorun } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { BreakoutDateTimeInput } from 'components/design-system/BreakoutDateTimeInput'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { type RoomState } from '@breakoutlearning/firebase-repository/models/RoomState'
import { dateSchema } from 'util/schema-date'

export const RoomStateForm = observer(function RoomStateForm({
  cubit,
  onSave,
  isCreate = false,
}: {
  cubit: StudentAssignmentCubit
  onSave: () => void
  isCreate?: boolean
}) {
  const now = DateTime.now().startOf('minute') // that allows you to use "now" when starting a meeting
  const nowFormatted = now
  const maxFormatted = cubit.assignment.expiresAtDate

  const [saving, setSaving] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (isCreate) cubit.logEvent('show_create_group')
  }, [cubit, isCreate])

  const getSchema = useCallback(
    (
      t: ReturnType<typeof useTranslation>['t'],
      room: RoomState,
      rooms: RoomState[],
      expiresAt: DateTime | undefined
    ) =>
      z.object({
        groupName: z
          .preprocess(
            (s) => {
              if (typeof s === 'string') {
                return s.trim()
              }
              return s
            },
            z.string().min(1, t('student_assignment.group_name_required'))
          )
          .refine((value) => {
            // Check if the group name is unique
            return !rooms.some(
              (r) => {
                if (r.id === room.id) return false
                if (r.data.roomStateName === value) return true
              },
              {
                message: t('student_assignment.group_name_exists'),
              }
            )
          }),
        scheduledAt: dateSchema({
          required: t('student_assignment.scheduled_date_required'),
          invalid: t('student_assignment.scheduled_date_invalid'),
          min: {
            date: now,
            message: t('student_assignment.scheduled_date_in_past'),
          },
          max: expiresAt
            ? {
                date: expiresAt,
                message: t(
                  'student_assignment.scheduled_date_after_deadline'
                ).replace(
                  '${deadline}',
                  expiresAt.toLocaleString(DateTime.DATETIME_SHORT)
                ),
              }
            : undefined,
        }),
      }),
    [now]
  )

  const schema = useMemo(
    () =>
      getSchema(
        t,
        cubit.roomState,
        cubit.roomStates.models,
        cubit.assignment.expiresAtDate
      ),
    [
      getSchema,
      t,
      cubit.roomState,
      cubit.roomStates,
      cubit.assignment.expiresAtDate,
    ]
  )
  type FormValues = z.infer<ReturnType<typeof getSchema>>

  const {
    control,
    setValue,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      groupName: cubit.roomState.data.roomStateName || '',
      scheduledAt: cubit.roomState.scheduledAtDate || now,
    },
  })

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      handleSubmit(async (data) => {
        const { groupName, scheduledAt } = data
        setSaving(true)
        try {
          await cubit.updateRoom(groupName, scheduledAt)
          onSave?.()
        } catch (e) {
          console.error(e)
        } finally {
          setSaving(false)
        }
      })(e)
    },
    [cubit, handleSubmit, onSave]
  )

  // sync scheduledAt from libraryObject
  useEffect(() => {
    return autorun(() => {
      if (!cubit.roomState.scheduledAtDate) return
      setValue('scheduledAt', cubit.roomState.scheduledAtDate)
    })
  }, [cubit, setValue])

  return (
    <Dialog
      size="sm"
      className="rounded-2xl bg-gray-200"
      ignoreBackdropClick
      ignoreEscapeKey
    >
      <DialogCloseButton />
      <div className="w-full text-on-primary">
        <form onSubmit={onSubmit}>
          <div className="text-headline-large mb-6">
            {t('student_assignment.edit_group_details')}
          </div>
          <div className="space-y-4">
            <Controller
              control={control}
              name="groupName"
              render={({ field, fieldState }) => (
                <BreakoutTextInput
                  {...field}
                  autoFocus
                  kind="secondary"
                  error={fieldState.error}
                  testId="room-state-form-name"
                  label="Group Name"
                  name="groupName"
                  defaultValue={cubit.roomState.data.roomStateName || ''}
                />
              )}
            />
            <Controller
              control={control}
              name="scheduledAt"
              render={({ field, fieldState }) => (
                <BreakoutDateTimeInput
                  {...field}
                  error={fieldState.error}
                  value={field.value}
                  kind="secondary"
                  label={t('student_assignment.scheduled_date')}
                  min={nowFormatted}
                  max={maxFormatted}
                  id="meeting-time"
                  name="scheduledAt"
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="mt-7">
            <BreakoutButton
              data-testid="room-state-form-confirm"
              size="large"
              loading={isSubmitting}
              type="submit"
              className={classNames(
                'mr-4 w-full bg-black text-white',
                saving ? 'cursor-not-allowed opacity-50' : ''
              )}
            >
              {saving ? 'Saving...' : 'Confirm'}
            </BreakoutButton>
          </div>
        </form>
      </div>
    </Dialog>
  )
})
