import { type SlideDeckMaterial } from '@breakoutlearning/firebase-repository/models/SlideDeckMaterial'
import classNames from 'classnames'
import { BreakoutTooltip } from '../design-system/BreakoutTooltip'
import { observer } from 'mobx-react-lite'
import { SlideDeckMaterialType } from '@breakoutlearning/firebase-repository/types'
import { inMobileSafari } from 'config/browser'
import { LinkIcon } from '../icons/Link'
import { DownloadIcon } from '../icons/Download'
import { Podcasts } from '../icons/Podcasts'
import { EyeOpen } from '../icons/EyeOpen'
import { useDialogs } from 'hooks/dialogs'
import { LazyMaterialPdfDialog } from './LazyMaterialPdfDialog'
import { MaterialPlayerDialog } from './MaterialPlayerDialog'
import { useBreakoutUser } from 'hooks/profile'
import { Spinner } from '../Spinner'
import { useState } from 'react'
import { VideoPreviewDialog } from 'components/VideoPreviewDialog'
import { ImagePreviewDialog } from './ImagePreviewDialog'

type DivProps = React.HTMLAttributes<HTMLDivElement>

export function CourseMaterialListItem({
  material,
  slideDeckId,
  slideDeckName,
  onViewTap = () => {},
  onPdfDownloadTap = () => {},
  className,
  inlineDialogs = false,
  ...rest
}: DivProps & {
  slideDeckName: string
  material: SlideDeckMaterial
  slideDeckId: string
  onViewTap?: () => void
  onPdfDownloadTap?: () => void
  inlineDialogs?: boolean
}) {
  return (
    <div
      className={classNames(
        'text-title-small flex flex-row items-center justify-between',
        className
      )}
      {...rest}
    >
      <BreakoutTooltip content={material.data.materialName}>
        <span className="mr-3 line-clamp-2">{material.data.materialName}</span>
      </BreakoutTooltip>
      <CourseMaterialButton
        onViewTap={onViewTap}
        onPdfDownloadTap={onPdfDownloadTap}
        material={material}
        slideDeckId={slideDeckId}
        slideDeckName={slideDeckName}
        inlineDialogs={inlineDialogs}
      />
    </div>
  )
}

export const CourseMaterialButton = observer(function CourseMaterialButton({
  material,
  slideDeckId,
  slideDeckName,
  onViewTap = () => {},
  onPdfDownloadTap = () => {},
  omitPdfDownload = false,
  size = 20,
  inlineDialogs = false,
}: {
  material: SlideDeckMaterial
  slideDeckId: string
  slideDeckName: string
  onViewTap?: () => void
  onPdfDownloadTap?: () => void
  omitPdfDownload?: boolean
  size?: number
  inlineDialogs?: boolean
}) {
  const { showDialog } = useDialogs()
  const user = useBreakoutUser()

  const [pdfDownloading, setPDFDownloading] = useState(false)

  // exhaustive switch on materialType return button(s) for each type
  switch (material.data.materialType) {
    case SlideDeckMaterialType.url:
      return (
        <LinkIcon
          size={size}
          className="min-w-5 cursor-pointer"
          onClick={() => {
            onViewTap()
            window.open(material.data.materialLink, '_blank')
          }}
        />
      )
    case SlideDeckMaterialType.pdf: {
      const viewButton = !inMobileSafari ? (
        <EyeOpen
          size={size}
          className="min-w-5 cursor-pointer"
          onClick={() => {
            onViewTap()
            showDialog(() => (
              <LazyMaterialPdfDialog
                src={material.data.materialLink}
                slideDeckId={slideDeckId}
                inline={inlineDialogs}
              />
            ))
          }}
        />
      ) : null

      if (omitPdfDownload) return viewButton

      return (
        <div className="flex flex-row gap-6">
          {viewButton}
          {!pdfDownloading ? (
            <DownloadIcon
              size={size}
              className="min-w-5 cursor-pointer"
              onClick={async () => {
                try {
                  setPDFDownloading(true)
                  onPdfDownloadTap()
                  const token =
                    (await user.repository.currentUser?.getIdToken()) || ''
                  await downloadPdf(material.data.materialLink, token)
                } finally {
                  setPDFDownloading(false)
                }
              }}
            />
          ) : (
            <Spinner size={1.25} />
          )}
        </div>
      )
    }
    case SlideDeckMaterialType.mp3:
      return (
        <Podcasts
          size={size}
          className="min-w-5 cursor-pointer"
          onClick={() => {
            onViewTap()
            showDialog(() => (
              <MaterialPlayerDialog
                slideDeckId={slideDeckId}
                slideDeckName={slideDeckName}
                inline={inlineDialogs}
                materialData={material.data}
              />
            ))
          }}
        />
      )
    case SlideDeckMaterialType.mp4:
    case SlideDeckMaterialType.webm:
      return (
        <EyeOpen
          size={size}
          className="min-w-5 cursor-pointer"
          onClick={() => {
            onViewTap()
            showDialog(() => (
              <VideoPreviewDialog
                src={material.data.materialLink}
                inline={inlineDialogs}
              />
            ))
          }}
        />
      )
    case SlideDeckMaterialType.featuredLarge:
    case SlideDeckMaterialType.jpg:
      return (
        <EyeOpen
          size={size}
          className="min-w-5 cursor-pointer"
          onClick={() => {
            onViewTap()
            showDialog(() => (
              <ImagePreviewDialog
                src={material.data.materialLink}
                inline={inlineDialogs}
              />
            ))
          }}
        />
      )
  }
})

const downloadPdf = async (url: string, token: string) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  })
  if (!response.ok) {
    throw new Error('Failed to fetch PDF')
  }
  const blob = await response.blob()
  const blobUrl = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = blobUrl
  link.download = 'material.pdf' // You can set the default filename here
  link.click()
  window.URL.revokeObjectURL(blobUrl)
}
