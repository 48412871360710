import classNames from 'classnames'
import { useCallback, useState } from 'react'
import { DateTime } from 'luxon'
import { BreakoutButton } from './BreakoutButton'
import 'styles/datetime-picker.css'
import type { BreakoutDateTimeProps } from './BreakoutDateTimeInput'
import { useTranslation } from 'react-i18next'

type Props = Omit<BreakoutDateTimeProps, 'onChange'> & {
  // It's not possible to have a null here, so setting the right type def
  onChange: (value: DateTime) => void
}

export function BreakoutDateTimePicker({
  value,
  onChange,
  min,
  max,
  hideNowButton,
}: Props) {
  const defaultTimeMode = DateTime.now()
    .toLocaleString(DateTime.TIME_SIMPLE)
    .toLowerCase()
    .match(/(am|pm)/)
    ? '12h'
    : '24h'

  const [timeMode, setTimeMode] = useState<'12h' | '24h'>(defaultTimeMode)
  const [view, setView] = useState<DateTime>(value ? value : DateTime.now())
  // Chrome's implementation ignores hours for min/max settings. It simplifies
  // a lot of things, so we're going to follow that logic.
  const minDate = min ? min.startOf('day') : undefined
  const maxDate = max ? max.endOf('day') : undefined
  const [selected, setSelected] = useState<DateTime>(view)

  const startWeek = view.startOf('month').startOf('week')
  const endWeek = view.endOf('month').endOf('week')

  const weeks = Array.from(
    { length: endWeek.diff(startWeek, 'weeks').weeks + 1 },
    (_, i) => startWeek.plus({ weeks: i })
  )
  const firstWeek = weeks[0]

  const { t } = useTranslation()

  const update = useCallback(
    (newValue: DateTime, { skipSelected }: { skipSelected?: boolean } = {}) => {
      if (minDate && newValue < minDate) return
      if (maxDate && newValue > maxDate) return
      if (!skipSelected) setSelected(newValue)
    },
    [setSelected, minDate, maxDate]
  )

  const handleSaveChange = (date: DateTime<boolean>) => {
    // We don't want to track milliseconds.
    const cleanDate = date.set({
      second: 0,
      millisecond: 0,
    })

    onChange?.(cleanDate)
  }

  return (
    <div className="w-full select-none bg-white">
      <div className="flex h-[300px] flex-row justify-between gap-5 md:h-[250px]">
        <div className="calendar justify flex h-full w-full flex-1 flex-col">
          <div className="justify- flex w-full flex-row items-center justify-between">
            <div className="text-label-large px-2">
              {view.toFormat('LLL yyyy')}
            </div>
            <div>
              <button
                onClick={() => setView(view.minus({ months: 1 }))}
                className={classNames('px-2 py-1', 'border border-primary')}
              >
                {'<'}
              </button>
              <button
                onClick={() => setView(view.plus({ months: 1 }))}
                className={classNames('px-2 py-1', 'border border-primary')}
              >
                {'>'}
              </button>
            </div>
          </div>
          <div className="h-full w-full">
            <table className="text-body-small md:text-body-large h-full w-full">
              <thead>
                <tr>
                  {Array.from({ length: 7 }).map((_, i) => (
                    <th key={i}>
                      {firstWeek.plus({ days: i }).toFormat('ccc')[0]}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {weeks.map((week, weekIndex) => (
                  <tr key={weekIndex}>
                    {Array.from({ length: 7 }).map((_, i) => {
                      const day = week.plus({ days: i })
                      const current = selected.toISODate() === day.toISODate()
                      const key = day.toISODate()

                      if (minDate && day < minDate) {
                        return (
                          <td
                            data-testid={`datetime-${key}`}
                            key={key}
                            className="cursor-default p-2 py-1.5 text-center opacity-25"
                          >
                            {day.day}
                          </td>
                        )
                      }
                      if (maxDate && day >= maxDate) {
                        return (
                          <td
                            data-testid={`datetime-${key}`}
                            key={key}
                            className="cursor-default p-2 py-1.5 text-center opacity-25"
                          >
                            {day.day}
                          </td>
                        )
                      }

                      return (
                        <td
                          data-testid={`datetime-${key}`}
                          key={key}
                          onClick={() => {
                            const newValue = view.set({
                              year: day.year,
                              month: day.month,
                              day: day.day,
                            })
                            setView(newValue)
                            update(newValue)
                          }}
                          className={classNames(
                            'cursor-pointer rounded-xl p-2 py-1.5 text-center',
                            day.month !== view.month && 'text-gray-500',
                            current && 'bg-core-primary text-core-on-primary'
                          )}
                        >
                          {day.day}
                        </td>
                      )
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex h-full flex-col">
          <div className="flex-0 text-body-medium md:text-body-large mb-2 flex justify-center gap-2">
            <button
              onClick={(e) => {
                e.preventDefault()
                setTimeMode('12h')
              }}
              className={classNames('p-1', {
                'font-bold': timeMode === '12h',
              })}
            >
              {t('design_system.12h')}
            </button>{' '}
            <button
              onClick={(e) => {
                e.preventDefault()
                setTimeMode('24h')
              }}
              className={classNames('p-1', {
                'font-bold': timeMode === '24h',
              })}
            >
              {t('design_system.24h')}
            </button>
          </div>

          <div className="times text-body-medium md:text-body-large flex flex-1 flex-row gap-2 overflow-hidden">
            <div className="hours overflow-y-auto bg-core-secondary">
              {timeMode === '24h' &&
                Array.from({ length: 24 }).map((_, i) => (
                  <div
                    key={i}
                    data-testid={`time-${i}`}
                    className={classNames(
                      'cursor-pointer p-2 py-1.5 text-center',
                      {
                        'bg-core-primary text-core-on-primary':
                          i === selected.hour,
                      }
                    )}
                    onClick={() => {
                      setView(view.set({ hour: i }))
                      update(selected.set({ hour: i }))
                    }}
                  >
                    {i < 10 ? '0' + i : i}
                  </div>
                ))}
              {timeMode === '12h' &&
                Array.from({ length: 12 }).map((_, i) => {
                  const hour = i === 0 ? 12 : i
                  const am = view.hour < 12
                  let currentValueIn12 = am ? view.hour : view.hour - 12
                  if (currentValueIn12 === 0) currentValueIn12 = 12
                  return (
                    <div
                      key={i}
                      data-testid={`time-${i}`}
                      className={classNames(
                        'cursor-pointer p-2 py-1.5 text-center',
                        {
                          'bg-core-primary text-core-on-primary':
                            hour === currentValueIn12,
                        }
                      )}
                      onClick={() => {
                        const hourClicked = hour === 12 ? 0 : hour
                        const newHour = am ? hourClicked : hourClicked + 12
                        setView(view.set({ hour: newHour }))
                        update(selected.set({ hour: newHour }))
                      }}
                    >
                      {hour < 10 ? '0' + hour : hour}
                    </div>
                  )
                })}
            </div>
            <div className="minutes overflow-auto bg-core-secondary">
              {Array.from({ length: 60 }).map((_, i) => (
                <div
                  key={i}
                  data-testid={`minute-${i}`}
                  className={classNames(
                    'cursor-pointer p-2 py-1.5 text-center',
                    {
                      'bg-core-primary text-core-on-primary':
                        i === selected.minute,
                    }
                  )}
                  onClick={() => {
                    setView(view.set({ minute: i }))
                    update(selected.set({ minute: i }))
                  }}
                >
                  {i < 10 ? '0' + i : i}
                </div>
              ))}
            </div>
          </div>
          {timeMode === '12h' && (
            <div className="flex-0 text-body-medium md:text-body-large mt-2 flex justify-center gap-2">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  const am = view.hour < 12
                  const newHour = am ? view.hour : view.hour - 12
                  setView(view.set({ hour: newHour }))
                  update(selected.set({ hour: newHour }))
                }}
                className={classNames('p-1', {
                  'font-bold': view.hour < 12,
                })}
                data-testid="datetime-picker-am"
              >
                {t('design_system.am')}
              </button>{' '}
              <button
                onClick={(e) => {
                  e.preventDefault()
                  const am = view.hour < 12
                  const newHour = am ? view.hour + 12 : view.hour
                  setView(view.set({ hour: newHour }))
                  update(selected.set({ hour: newHour }))
                }}
                className={classNames('p-1', {
                  'font-bold': view.hour >= 12,
                })}
                data-testid="datetime-picker-pm"
              >
                {t('design_system.pm')}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 flex items-center justify-between">
        {hideNowButton && <div />}
        {!hideNowButton && (
          <BreakoutButton
            data-testid="datetime-picker-now"
            size="small"
            kind="secondary"
            onClick={() => {
              update(DateTime.now())
              setView(DateTime.now())
            }}
          >
            {t('design_system.now')}
          </BreakoutButton>
        )}
        <div className="text-body-small md:text-body-medium px-1">
          {timeMode === '12h'
            ? // With AM/PM
              selected.toFormat('LLL d, yyyy h:mm a ZZZZ')
            : // Without AM/PM
              selected.toFormat('LLL d, yyyy HH:mm ZZZZ')}
        </div>
        <BreakoutButton
          data-testid="datetime-picker-ok"
          size="small"
          onClick={() => handleSaveChange(selected)}
        >
          {t('design_system.ok')}
        </BreakoutButton>
      </div>
    </div>
  )
}
