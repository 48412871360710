import { LazyMarkdown } from 'components/LazyMarkdown'
import { Spinner } from 'components/Spinner'
import { BreakoutBarGraphVertical } from 'components/breakout/BreakoutBarGraphVertical'
import { EmptyState } from 'components/breakout/EmptyState'
import { ChevronRight } from 'components/icons/ChevronRight'
import { Close } from 'components/icons/Close'
import { QuestionIcon } from 'components/icons/Question'
import { useInstructorAssignmentCubit } from 'hooks/cubits/instructorAssignment'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const SummaryQuizAssessment = observer(function SummaryQuizAssessment() {
  const cubit = useInstructorAssignmentCubit()
  const { t } = useTranslation()
  // show spinner if cubit.questionsCollection.isLoading
  if (cubit.questionsCollection.isLoading) {
    return (
      <div className="flex w-full flex-col items-center justify-center md:h-full">
        <Spinner />
      </div>
    )
  }
  if (cubit.multipleChoiceQuestions.size === 0) {
    return (
      <EmptyState
        Icon={QuestionIcon}
        text={t('instructor_assignment.quizzes_missing')}
      />
    )
  }
  // Function to find the largest value in quizHistogram
  const findMaxValueInHistogram = (
    quizHistogram: Record<string, number>
  ): number => {
    return Object.values(quizHistogram).reduce((previousValue, element) => {
      return previousValue > element ? previousValue : element
    }, 0)
  }

  // Function to round the max value up to the nearest 10
  const roundUpMaxValue = (max: number): number => {
    return Math.ceil(max / 10) * 10
  }

  const max = findMaxValueInHistogram(cubit.multipleChoiceHistogram)

  const maxRounded = roundUpMaxValue(max)

  const delta = Math.round(maxRounded / 5)
  const scale = Array.from({ length: 6 }, (_, index) => index * delta)
  return (
    <>
      <div className="mb-4 flex items-center justify-between border-b border-border-grey pb-4">
        <div>
          <h1 className="text-title-large">
            {t('instructor_assignment.quiz_assessment')}
          </h1>
          <div className="text-body-medium text-grey-text">
            {t('instructor_assignment.n_answers_captured', {
              count: cubit.uniqueMultipleChoiceAnswerCount,
            })}
          </div>
        </div>
        <div
          className="text-body-medium flex cursor-pointer items-center justify-between text-grey-text"
          onClick={() => {
            cubit.changeResultsTab('quiz')
            cubit.changeTab('results')
          }}
        >
          {t('instructor_assignment.view_details')}
          <span className="text-grey-text">
            <ChevronRight size={14} />
          </span>
        </div>
      </div>
      <div className="h-[200px]">
        <BreakoutBarGraphVertical
          colorBackground="bg-light-grey"
          histogram={cubit.multipleChoiceHistogram}
          scale={scale}
          xLabel={t('instructor_assignment.score')}
          yLabel={t('instructor_assignment.number_of_students')}
        />
      </div>

      <div className="mt-2">
        <div className="mb-2 flex items-center">
          <span className="pr-1 text-grey-text">
            <QuestionIcon size={16} />
          </span>
          <div className="text-body-medium text-grey-text">
            {t('instructor_assignment.highlighted_question')}
          </div>
        </div>
        <div className="text-body-medium pl-5">
          {cubit.multipleChoiceQuestionWithMostWrongAnswers?.data.question}
        </div>

        <div className="mb-2 mt-4 flex items-start">
          <span className="pr-1 text-breakout-red">
            <Close size={16} />
          </span>
          <div className="text-body-medium text-grey-text">
            {t('instructor_assignment.most_common_wrong_answer')}
          </div>
        </div>
        <div className="text-body-medium pl-5">
          <LazyMarkdown>
            {cubit.multipleChoiceQuestionWithMostWrongAnswers?.data.answers[
              cubit.mostCommonWrongAnswer
            ] ?? ''}
          </LazyMarkdown>
        </div>
      </div>
    </>
  )
})
