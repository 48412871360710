import type { InstructorLibraryCubit } from '@breakoutlearning/firebase-repository/cubits/InstructorLibraryCubit'
import { BreakoutButton } from 'components/design-system/BreakoutButton'
import { BreakoutTextInput } from 'components/design-system/BreakoutTextInput'
import { Dialog } from 'components/dialogs/Dialog'
import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'

export const AddSectionDialog = observer(function AddSectionDialog({
  createSection,
  onCreate,
  instructorUserId,
}: {
  instructorUserId?: string
  onCreate?: () => void
  createSection: InstructorLibraryCubit['createSection']
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const formData = new FormData(e.currentTarget)
      const className = (formData.get('className') as string).trim()
      const sectionName = (formData.get('sectionName') as string).trim()

      if (!className || !sectionName) {
        setError('Please fill out all fields')
        return
      }

      setIsSaving(true)

      await createSection(className, sectionName, instructorUserId)

      setIsSaving(false)
      onCreate?.()
    },
    [createSection, onCreate, instructorUserId]
  )

  return (
    <Dialog size="xs">
      <div className="mb-4 text-xl font-bold text-on-primary">Add Class</div>
      <form className="flex flex-col gap-5" onSubmit={onSubmit}>
        <BreakoutTextInput
          label="Class name"
          name="className"
          autoFocus
          kind="secondary"
        />
        <BreakoutTextInput
          label="Section name"
          name="sectionName"
          kind="secondary"
        />
        {error && <div className="text-breakout-red">{error}</div>}
        <div>
          <BreakoutButton
            size="large"
            type="submit"
            fullWidth
            loading={isSaving}
          >
            Add Class
          </BreakoutButton>
        </div>
      </form>
    </Dialog>
  )
})
