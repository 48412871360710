import { useBreakoutUser } from 'hooks/profile'
import { observer } from 'mobx-react-lite'
import { Coin } from 'components/icons/Coin'
import { Trans, useTranslation } from 'react-i18next'

export const ProfileTokensSection = observer(function ProfileTokensSection() {
  const { t } = useTranslation()

  const user = useBreakoutUser()

  const tokensAvailable = user.availableTokens
  const tokensConsumed = user.consumedTokens

  return (
    <div className="h-full rounded-3xl bg-primary py-5 text-center lg:px-10">
      <div className="mb-5 mt-3 flex justify-center">
        <Coin size={50} color="#333" />
      </div>
      <h2 className="text-headline-medium mb-6 text-on-primary">
        {t('profile.tokens_headline')}
      </h2>
      <p className="mx-auto px-5 text-xs">
        <Trans
          i18nKey="profile.tokens_explanation"
          defaults="<bold>{{tokensAvailable}}</bold>"
          values={{ tokensAvailable }}
          components={{ bold: <strong /> }}
        />
      </p>
      <div className="mt-5 flex w-full flex-col items-center justify-center gap-5 px-5 lg:flex-row lg:px-0">
        <div className="w-full rounded-2xl bg-secondary p-4 pb-8">
          <h3 className="text-xxs font-semibold md:text-nowrap">
            {t('profile.tokens_available')}
          </h3>
          <div className="text-headline-large mt-4">{tokensAvailable}</div>
        </div>
        <div className="w-full rounded-2xl bg-secondary p-4 pb-8">
          <h3 className="text-xxs font-semibold md:text-nowrap">
            {t('profile.tokens_consumed')}
          </h3>
          <div className="text-headline-large mt-4 text-breakout-red">
            {tokensConsumed}
          </div>
        </div>
      </div>
    </div>
  )
})
