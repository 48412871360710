import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import type { RoomStateAnswer } from '@breakoutlearning/firebase-repository/models/RoomStateAnswer'
import type { SlideQuestion } from '@breakoutlearning/firebase-repository/models/SlideQuestion'
import { Dialog } from 'components/dialogs/Dialog'
import classNames from 'classnames'
import { DialogCloseButton } from 'components/dialogs/DialogCloseButton'
import { EmptyState } from 'components/breakout/EmptyState'
import { QuestionIcon } from 'components/icons/Question'
import { useMemo } from 'react'
import { LazyMarkdown } from 'components/LazyMarkdown'
import { LinearProgressIndicator } from '../quiz_components/LinearProgressIndicator'

export const AssignmentQuizMultipleChoiceDialog = observer(
  function AssignmentQuizMultipleChoiceDialog({
    answers,
    question,
  }: {
    answers: RoomStateAnswer[]
    question: SlideQuestion
  }) {
    const { t } = useTranslation()
    const tScoped = useMemo(
      () => (key: string) => t(`instructor_assignment.${key}`),
      [t]
    )
    const maxHeight = useMemo(
      () =>
        Math.min(Math.max(question.data.answers.length * 135 + 128, 400), 700),
      [question.data.answers.length]
    )

    return (
      <Dialog
        size="sm"
        className={classNames('min-w-[500px]', {
          '!bg-primary': answers.length === 0,
          '!bg-light-grey': answers.length > 0,
        })}
        innerClassName="!w-full"
      >
        <DialogCloseButton className="absolute right-0 top-0 p-5 pb-0" />
        <div
          className="min-h-[400px]"
          style={{
            maxHeight: `${maxHeight}px`,
          }}
        >
          {!answers.length ? (
            <EmptyState Icon={QuestionIcon} text={tScoped('quizzes_missing')} />
          ) : (
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-2.5">
                <h1 className="text-headline-large">
                  {tScoped('slide_question')}
                </h1>
                <h2 className="text-title-large">
                  {question.questionWithoutNumbersAtBeginning}
                </h2>
              </div>
              {question.data.answers.map((answer, index) => (
                <Answer
                  key={answer}
                  answer={answer}
                  isCorrect={question.data.correct === index}
                  total={answers.length}
                  responses={answers.filter((a) => a.data.answer === index)}
                />
              ))}
            </div>
          )}
        </div>
      </Dialog>
    )
  }
)

const Answer = observer(function Answer({
  answer,
  isCorrect,
  responses,
  total,
}: {
  answer: string
  isCorrect: boolean
  responses: RoomStateAnswer[]
  total: number
}) {
  const { t } = useTranslation()
  const color = isCorrect ? 'green' : 'red'
  return (
    <div className="markdown-quiz-results-dialog flex w-full flex-col gap-4 rounded-2xl bg-primary p-5">
      <LazyMarkdown>{answer}</LazyMarkdown>
      <div className="flex w-full flex-row items-center justify-between">
        <LinearProgressIndicator
          color={color}
          className="w-[280px] !bg-primary"
          percentage={(responses.length / total) * 100}
        />
        <span className="text-title-small">
          {t('instructor_assignment.n_students', { count: responses.length })}
        </span>
      </div>
    </div>
  )
})
