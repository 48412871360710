import { observer } from 'mobx-react-lite'
import { useDialogs } from 'hooks/dialogs'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect } from 'react'
import { reaction } from 'mobx'
import { useBreakoutUser } from 'hooks/profile'
import { useStudentAssignmentCubit } from 'hooks/cubits/studentAssignment'
import { PurchaseOrEnrollDialog } from './dialogs/PurchaseOrEnrollDialog'

export const StudentAssignmentEnroll = observer(
  function StudentAssignmentEnroll() {
    const cubit = useStudentAssignmentCubit()
    const { showDialog, dialogStore } = useDialogs()
    const { t } = useTranslation()
    const user = useBreakoutUser()
    const slideDeckPrice = cubit.slideDeck.data.slideDeckPrice
    const tokensAvailable = user.availableTokens
    const tokensRequired = slideDeckPrice - user.availableTokens

    const showPurchaseOrEnrollDialog = useCallback(() => {
      showDialog(() => <PurchaseOrEnrollDialog cubit={cubit} />)
    }, [cubit, showDialog])

    useEffect(() => {
      return reaction(
        () => ({
          canPopEnrollDialog:
            cubit.canPopEnrollDialog && dialogStore.dialogs.length === 0,
          tokensAvailable: user.availableTokens,
          tokensLoading: user.tokensLoading,
        }),
        ({ canPopEnrollDialog, tokensAvailable, tokensLoading }, prev) => {
          // if tokens are still loading, don't proceed as available will be 0
          if (tokensLoading || !canPopEnrollDialog) return
          const didTokensUpdate = tokensAvailable !== prev?.tokensAvailable
          const shouldPop =
            canPopEnrollDialog !== prev?.canPopEnrollDialog || didTokensUpdate
          if (!shouldPop) return
          showPurchaseOrEnrollDialog()
        },
        { fireImmediately: true }
      )
    }, [dialogStore, user, cubit, showDialog, showPurchaseOrEnrollDialog])

    return (
      <div className="flex h-full flex-col items-center justify-center rounded-3xl bg-primary px-20">
        <h1 className="text-title-large mb-[4px]">
          {t('student_assignment.enroll_in_case')}
        </h1>
        <p className="text-body-medium mb-[12px] text-center">
          {t('student_assignment.token_description')}
        </p>
        <button
          className="text-label-large mb-[12px] rounded-2xl bg-black px-6 py-4 text-white"
          onClick={showPurchaseOrEnrollDialog}
        >
          Enroll ({t('student_library.token_number', { count: slideDeckPrice })}
          )
        </button>
        <div className="flex items-center">
          <p className="text-body-medium">
            {t('student_assignment.tokens_available_title')}=
          </p>
          <p
            className={`text-title-small ${
              tokensRequired > 0 ? 'text-red-500' : '' // Change text color conditionally
            }`}
          >
            {t('student_library.token_number', { count: tokensAvailable })}
          </p>
        </div>
      </div>
    )
  }
)
