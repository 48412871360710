import { PreviewMeetingCubit } from '@breakoutlearning/firebase-repository/cubits/PreviewMeetingCubit'
import { Spinner } from 'components/Spinner'
import { useRepository } from 'hooks/auth'
import { MeetingContextProvider } from 'hooks/meeting'
import { useObjectUrlCacheManager } from 'hooks/objectUrl'
import { useBreakoutUser } from 'hooks/profile'
import { Suspense, lazy, useEffect, useMemo } from 'react'
const MeetingPage = lazy(() => import('./MeetingPage'))

export function MeetingPreviewLoader({ slideDeckId }: { slideDeckId: string }) {
  const currentUser = useBreakoutUser()
  const repository = useRepository()

  const meeting = useMemo(() => {
    const meeting = new PreviewMeetingCubit(
      currentUser,
      repository,
      slideDeckId
    )
    meeting.initialize()
    return meeting
  }, [currentUser, repository, slideDeckId])

  const objectUrlCache = useObjectUrlCacheManager()

  const meetingContext = useMemo(() => {
    if (!meeting) return null
    return { meeting: meeting }
  }, [meeting])

  useEffect(() => {
    return () => {
      objectUrlCache.clear('meeting')
    }
  }, [objectUrlCache])

  if (!meetingContext)
    return (
      <div className="absolute box-border h-full w-full">
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      </div>
    )

  const isReady = meetingContext

  return (
    <div className="box-border h-full w-full bg-secondary">
      {!isReady && (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )}

      {isReady && (
        <MeetingContextProvider value={meetingContext}>
          <Suspense fallback={<Spinner />}>
            <MeetingPage />
          </Suspense>
        </MeetingContextProvider>
      )}
    </div>
  )
}
